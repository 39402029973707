import { AgGridReact } from "ag-grid-react";
import "ag-grid-community/styles//ag-grid.css";
import "ag-grid-community/styles//ag-theme-alpine.css";
import "ag-grid-community/styles//ag-theme-material.css";
import "ag-grid-community/styles//ag-theme-balham.css";
import { useEffect, useRef, useState } from "react";
import { GetLocalStorageDataWithoutParse } from "../helpers";

export default function AGGrid(props) {
  //     const gridRef = props.lazyLoading ? useRef() : null;
  //     const [isLoading, setIsLoading] = useState(false);

  //     // const getLoadedPages = () => {
  //     //     const loadedPages = localStorage.getItem('loadedPages');
  //     //     return loadedPages ? new Set(JSON.parse(loadedPages)) : new Set();
  //     // };

  //     // const setLoadedPages = (pages) => {
  //     //     debugger
  //     //     localStorage.setItem('loadedPages', JSON.stringify(Array.from(pages)));
  //     // };

  //     const getLoadedPages = () => {
  //         // Retrieve loadedPages from localStorage, but ensure it defaults to an empty array if not found.
  //         const loadedPages = localStorage.getItem('loadedPages');
  //         return loadedPages ? new Set(JSON.parse(loadedPages)) : new Set();
  //     };

  //     const setLoadedPages = (pages) => {
  //         // Save the loadedPages set to localStorage, ensuring it's in a serializable form.
  //         localStorage.setItem('loadedPages', JSON.stringify(Array.from(pages)));
  //     };

  //     const changeClientName = GetLocalStorageDataWithoutParse("changeClientName");
  // let tot
  //     const onFirstDataRendered = (params) => {
  //         if (props.lazyLoading && props.onScroll) {
  //             params.api.addEventListener('bodyScroll', onBodyScroll);
  //            // const totalRows = params.api.getDisplayedRowCount();  // Get the total number of rows in the grid

  //             // AddLocalStorageData("Count",totalRows) // Add this

  //         }
  //     };

  //     let roCount=Number(localStorage.getItem("Count"));
  //     debugger
  //     useEffect(()=>{
  //        // roCount=localStorage.getItem("Count")
  //     },[localStorage.getItem("Count")])
  //     console.log("AG row count",roCount);
  //    // const roCount=276;
  //     const onBodyScroll = (event) => {
  //         if (props.lazyLoading && gridRef?.current && !isLoading) {
  //             const { scrollTop, scrollHeight, clientHeight } = gridRef.current.api.gridBodyCtrl.eBodyViewport;

  //             // Check if near the bottom of scroll and throttle the event
  //             if (scrollTop + clientHeight >= scrollHeight - 40) {
  //                 const currentPage = gridRef.current.api.paginationGetCurrentPage();
  //                 const loadedPages = getLoadedPages();
  //                 const requiredRowCount = (currentPage + 1) * 100;
  // debugger
  //                 // const startRow = currentPage * props.paginationPageSize;
  //                 // const endRow = startRow + props.paginationPageSize;
  //                 // const currentPageRowCount = props.rowData.slice(startRow, endRow).length;

  //                 // // Update roCount for the current page
  //                 // setRoCount(currentPageRowCount);
  //                 if (!loadedPages.has(currentPage)
  //                     && roCount >= requiredRowCount
  //                   //   && roCount > 0 && roCount >= 100
  //                     ) {
  //                     setIsLoading(true); // Set loading to true to prevent multiple calls
  //                     props.onScroll(currentPage); // Fetch data
  //                     loadedPages.add(currentPage);
  //                     setLoadedPages(loadedPages); // Save to local storage
  //                 }
  //             }
  //         }
  //     };

  //     // Reset isLoading after data load completes
  //     useEffect(() => {
  //         if (props.rowData.length > 0) {
  //             setIsLoading(false); // Reset loading after data is loaded
  //         }
  //     }, [props.rowData]);

  const gridRef = props.lazyLoading ? useRef() : null;
  const [isLoading, setIsLoading] = useState(false);

  const getLoadedPages = () => {
    // Retrieve loadedPages from localStorage, but ensure it defaults to an empty array if not found.
    const loadedPages = localStorage.getItem("loadedPages");
    return loadedPages ? new Set(JSON.parse(loadedPages)) : new Set();
  };

  const setLoadedPages = (pages) => {
    // Save the loadedPages set to localStorage, ensuring it's in a serializable form.
    localStorage.setItem("loadedPages", JSON.stringify(Array.from(pages)));
  };

  const changeClientName = GetLocalStorageDataWithoutParse("changeClientName");
  let roCount = Number(localStorage.getItem("Count"));

  // useEffect(() => {

  //     // Setup body scroll event listener once the grid is ready
  //     const gridApi = gridRef.current?.api;
  //     if (gridApi) {
  //         const onBodyScroll = (event) => {
  //             if (props.lazyLoading && gridRef?.current && !isLoading) {
  //                 const { scrollTop, scrollHeight, clientHeight } = gridRef.current.api.gridBodyCtrl.eBodyViewport;

  //                 // Check if near the bottom of scroll and throttle the event
  //                 if (scrollTop + clientHeight >= scrollHeight - 40) {
  //                     const currentPage = gridRef.current.api.paginationGetCurrentPage();
  //                     const loadedPages = getLoadedPages();
  //                     const requiredRowCount = (currentPage + 1) * 100;

  //                     // Update loading logic
  //                     if (!loadedPages.has(currentPage) && roCount >= requiredRowCount) {
  //                         setIsLoading(true); // Set loading to true to prevent multiple calls
  //                         props.onScroll(currentPage); // Fetch data
  //                         loadedPages.add(currentPage);
  //                         setLoadedPages(loadedPages); // Save to local storage
  //                     }
  //                 }
  //             }
  //         };

  //         gridApi.addEventListener('bodyScroll', onBodyScroll);

  //         // Cleanup on unmount
  //         return () => {
  //             gridApi.removeEventListener('bodyScroll', onBodyScroll);
  //         };
  //     }
  // }, [props.lazyLoading, isLoading, roCount, props.onScroll]);
  useEffect(() => {
    if (!props.lazyLoading) return; // Skip if lazyLoading is not enabled

    const gridApi = gridRef.current?.api;
    if (gridApi) {
      const onBodyScroll = (event) => {
        if (props.lazyLoading && gridRef?.current && !isLoading) {
          const { scrollTop, scrollHeight, clientHeight } =
            gridRef.current.api.gridBodyCtrl.eBodyViewport;

          // Check if near the bottom of scroll and throttle the event
          if (scrollTop + clientHeight >= scrollHeight - 40) {
            const currentPage = gridRef.current.api.paginationGetCurrentPage();
            const loadedPages = getLoadedPages();
            const requiredRowCount = (currentPage + 1) * 100;

            // Update loading logic
            if (!loadedPages.has(currentPage) && roCount >= requiredRowCount) {
              setIsLoading(true); // Set loading to true to prevent multiple calls
              props.onScroll(currentPage); // Fetch data
              loadedPages.add(currentPage);
              setLoadedPages(loadedPages); // Save to local storage
            }
          }
        }
      };

      gridApi.addEventListener("bodyScroll", onBodyScroll);

      // Cleanup on unmount
      return () => {
        gridApi.removeEventListener("bodyScroll", onBodyScroll);
      };
    }
  }, [props.lazyLoading, isLoading, roCount, props.onScroll]);
  // Reset isLoading after data load completes
  useEffect(() => {
    if (props.rowData.length > 0) {
      setIsLoading(false); // Reset loading after data is loaded
    }
  }, [props.rowData]);
  return (
    <div
      className="ag-theme-alpine"
      style={{
        height: props.height ?? "400px",
      }}
      onClick={props.onOverlayClick}
    >
      <AgGridReact
        ref={props.lazyLoading ? gridRef : null}
        onGridReady={props.onGridReady}
        suppressMenuHide={true}
        pagination={true}
        paginationPageSize={props.pagination}
        defaultColDef={{
          sortable: true,
          editable: false,
          unSortIcon: true,
          filter: true,
          resizable: true,
          autoHeight: props.autoHeight,
          wrapHeaderText: true,
          autoHeaderHeight: true,
          wrapText: true,
          textOverflow: "ellipsis",
          whiteSpace: "pre",
          overflow: "hidden",
        }}
        rowData={props.rowData}
        columnDefs={props.colDefs}
        suppressNoRowsOverlay={false}
        animateRows={true}
        tooltipShowDelay={0}
        tooltipHideDelay={2000}
        rowHeight={50}
        autoHeight={false}
        rowSelection={props.rowSelection ?? false}
        rowMultiSelectWithClick={props.rowMultiSelectWithClick ?? false}
        onCellContextMenu={props.onCellContextMenu}
        onSelectionChanged={props.onSelectionChanged}
        onCellClicked={props.onCellClicked}
        onCellKeyPress={props.onCellKeyPress}
        isRowSelectable={props.isRowSelectable}
        components={props.frameworkComponents ?? null}
        overlayNoRowsTemplate={props.overlayNoRowsTemplate}
        onSortChanged={props.onSortChanged}
        //  onFirstDataRendered={props.lazyLoading ? onFirstDataRendered : null}
        // onPaginationChanged={props.lazyLoading ? onPaginationChanged : null}
      ></AgGridReact>
    </div>
  );
}
