import React, { Component } from "react";
import Dropdown from "../../components/Dropdown";
import DatePicker from "../../components/DatePickerComponent";
import { helperText } from "../../helpers/helperText";
import { TrackingToolService } from "../../services/trackingTool.service";
import helpInfo from "../../assets/images/helpInfo.png";
import editIcon from "../../assets/images/Icon feather-edit.png";
import { SupplierService } from "../../services/supplier.service";
import viewIcon from "../../assets/images/Icon awesome-eye.png";
import deleteIcon from "../../assets/images/delete icon.png";
import historyIcon from "../../assets/images/admin-history.png";
import correctionIcon from "../../assets/images/correction-icon.png";
import addRowIcon from "../../assets/images/add.png";
import fileUploadIcon from "../../assets/images/tt-file-upload.png";
import downloadIcon from "../../assets/images/ship-download-document.png";
import downloadAllIcon from "../../assets/images/download-all.png";
import zoomIcon from "../../assets/images/maximize.png";
import AGGrid from "../../components/AGGrid";
import Loader from "../../components/Loader";
import Header from "../../components/Header";
import Footer from "../../components/Footer";
import Searchbar from "../../components/Searchbar";
import {
  convertDate,
  alphaNumComparator,
  customSortTableAComparator,
  customSortTableBComparator,
  customSortRemarkComparator,
  convertPOMinMaxDate,
  isStringEmpty,
} from "../../helpers/string-helper";
import {
  AddLocalStorageData,
  GetLocalStorageDataWithParse,
  GetLocalStorageDataWithoutParse,
  changePageTitle,
  withRouter,
} from "../../helpers";
import ZoomTrackingTool from "./ZoomTrackingTool";
import OtherStatusTrackingTool from "./OtherStatusTrackingTool";
import Toast from "../../components/Toast";
import MultiSelectDropdown from "../../components/MultiSelectDropdown";
import Logout from "../../helpers/Logout";
import ViewModal from "../../components/ViewModal";
import ViewLineItemHistory from "./ViewLineItemHistory";
import TTPOFileUpload from "./TTPOFileUpload";
import TTMDSDoCUpload from "./TTMDSDoCUpload";
import TagRenderer from "../../components/TagRenderer";
import AlertModal from "../../components/AlertModal";
import HelpModal from "../../components/HelpModal";
import { UserAction, UserRole } from "../../helpers/enums";
import ConfirmationModal from "../../components/ConfirmationModal";
import AGGridMultiSelectDropdown from "../../components/AGGridMultiSelectDropdown";
import eyeIcon from "../../assets/images/admin-eye.png";
import { UncontrolledTooltip } from "reactstrap";
import AutoCreatingSupplier from "../../components/AutoCreatingSupplier"

const tableAListPrePopulated = [
  { value: 1, label: "A-1 Asbestos" },
  { value: 2, label: "A-2 Polychlorinated biphenyls (PCBs)" },
  { value: 3, label: "A-3 Ozone depleting substances" },
  { value: 4, label: "A-4 Anti-fouling systems containing organotin compounds as a biocide" },
  { value: 5, label: "A-5 Perfluorooctane sulfonic acid(PFOS)" },
];

const tableBListPrePopulated = [
  { value: 1, label: "B-1 Cadmium and cadmium compounds" },
  { value: 2, label: "B-2 Hexavalent chromium and hexavalent chromium compounds" },
  { value: 3, label: "B-3 Lead and lead compounds" },
  { value: 4, label: "B-4 Mercury and mercury compounds" },
  { value: 5, label: "B-5 Polybrominated biphenyl (PBBs)" },
  { value: 6, label: "B-6 Polybrominated diphenyl ethers (PBDEs)" },
  { value: 7, label: "B-7 Polychlorinated naphthalenes (PCNs)" },
  { value: 8, label: "B-8 Radioactive substances" },
  { value: 9, label: "B-9 Certain shortchain chlorinated paraffins (SCCPs)" },
  { value: 10, label: "B-10 Brominated Flame Retardant (HBCDD)" },
];

const statusDataList = [
  { value: 1, label: "InComplete" },
  { value: 2, label: "Partially Complete" },
  { value: 3, label: "Completed" },
  { value: 4, label: "Other" },
];

const hazmatPresentStatus = [
  { value: "true", label: "Yes" },
  { value: "false", label: "No" },
];

// const Option = (props) => {
//   return (
//     <div>
//       <components.Option {...props}>
//         <input
//           type="checkbox"
//           checked={props.isSelected}
//           onChange={() => null}
//         />{" "}
//         <label>{props.label}</label>
//       </components.Option>
//     </div>
//   );
// };

let tableAGridItem = [];
let tableBGridItem = [];
let addManualRow = 0;
var currentDate = new Date();
var resetPageSize = "notReset"
var totalPOCount = 0;

var currentDate = new Date();

class TrackingTool extends Component {
  constructor(props) {
    super(props);

    this.state = {
      mappedTrackingTool: [],
      defaultTrackingTool: [],
      colDefsClient: this.gridClientColumns,
      selectedTrackingToolIdList: [],
      selectedTrackingToolDataList: [],
      selectedToSendReminderEmail: [],

      dropdownList: [],
      isLoading: false,

      toastMessage: null,
      toastType: "",
      toastVisible: false,
      showOnHoldModal: false,

      frameworkComponents: {
        tagRenderer: TagRenderer,
      },

      clientDataList: [],
      shipDataList: [],
      dropdownYearList: [],
      defaultShipDataList: [],
      supplierDataList: [],
      statusDataList: [],
      tableAList: [],
      tableBList: [],

      tableAGridItem: [],
      tableBGridItem: [],

      onHoldFromDate: "",
      onHoldToDate: "",
      userId: 0,
      supplierCompanyList: [],
      supplierStatusList: [],
      clientName: "",
      shipName: "",

      dropdown_clientFilter: "",
      dropdown_shipFilter: "",
      dropdown_supplierFilter: "",
      dropdown_poNoFilter: "",
      dropdown_hazmatFilter: "",
      dropdown_yearFilter: 0,
      dropdown_remarkFilter: "",
      dropdown_tableAFilter: "",
      dropdown_tableBFilter: "",
      tableAItem: [],
      tableBItem: [],
      filter_fromDate: "",
      filter_toDate: "",
      showZoom: false,
      showOtherValue: false,
      poNumberList: [],
      isBouncing: false,

      searchedText: "",
      selectedItem: [],
      otherValueStatus: "",
      otherValueValidationText: "",
      dataToSaveOtherValue: [],

      filePathToView: "",
      showViewModal: false,

      showViewHistoryModal: false,
      lstLineItemHistory: [],
      lineItemHistoryColumns: this.historyColumn,

      showUploadModal: false,
      showMDSDoCUploadModal: false,
      emailSentModal: false,
      messageText: "",
      accessList: [],

      isClient: false,
      isShipCrew: false,
      isClientOrClientUser: false,
      showConfirmationModal: false,
      selectedTTData: [],
      modalText: "",
      clientUserId: 0,
      nextFromDate: "",
      previousToDate: currentDate,
      isAdminOrInternalUser: false,
      userTypeId: 0,
      defaultYearList: [],
      gridApi: null,
      helpContentModal: false,
      userRolePermissionRead: false,
      displayText: " IHMM Maintenance",
      selectdTTDeleteId: 0,
      showConfirmationPopup: false,
      showSupplierAddingModal: false,
      isYesClicked: false,
      autoAddSupplierMessage: null,
      autoSupplierName: null,
      savedLineItemList: [],
      pageSize: 100,
      pageNumber: 1,
      hasMoreData: true,
      isLoad: false, count: 0
      //resetLoadedPages:new Set()
    };

    this.onGridReady = this.onGridReady.bind(this);
    this.gridRef = React.createRef();
  }

  /* <summary>
       date: 23/11/2022
       Name: DN
       description: Get data to bind on the manage supplier screen
       <summary> */
  componentDidMount = async () => {
    //HV - 20/02/2023 - changing browser title name
    changePageTitle("");
    AddLocalStorageData("SelectedTrackingTool", 0);
    var userData = GetLocalStorageDataWithParse("loggedInUser");

    if (userData != null) {
      window.addEventListener("paste", this.handlePaste);
      this.setState(
        {
          userId: userData.id,
          accessList: GetLocalStorageDataWithParse("userRolePermission"),
          isClient: userData.typeId == UserRole.CLIENT,
          isShipCrew: userData.typeId == UserRole.SHIPCREW,
          isClientOrClientUser:
            UserRole.CLIENT == userData.typeId ||
            userData.typeId == UserRole.CLIENTUSER,
          clientUserId:
            userData.typeId == UserRole.CLIENTUSER ? userData.id : 0,
          isAdminOrInternalUser:
            userData.typeId == UserRole.ADMIN ||
            userData.typeId == UserRole.INTERNALUSER ||
            userData.typeId == UserRole.ACCOUNTS,
          userTypeId: userData.typeId,
        },
        () => {
          this.setState(
            {
              userRolePermissionRead:
                this.state.accessList != null &&
                this.state.accessList.trackingToolAccess == UserAction.READ,
            },
            () => {
              this.getAllTrackingToolFilters();
            }
          );
        }
      );
    }
  };

  /* componentDidUpdate() {
    if (this.state.gridApi) {
      this.state.gridApi.paginationGoToPage(0);
    }
  } */

  /* <summary>
       date: 5/1/2023
       Name: NGG
       description: Get ship and client filter value from Manage ship page
       <summary> */

  BindClientShipDropdownData = async (
    dropdownList,
    ihmRegistryDropDownFilter
  ) => {
    const { dropdown_shipFilter, dropdown_yearFilter, dropdownYearList, defaultYearList } = this.state;
    this.setState({ isLoading: true });
    if (dropdownList != null) {
      let dropdown_yearFilterDefault;
      if (dropdown_yearFilter === 0 || dropdown_yearFilter === "" || dropdown_yearFilter === null) {
        dropdown_yearFilterDefault = defaultYearList.filter((x) => x.parentValue == dropdownList.clientId)[0].value;
      }
      else
        dropdown_yearFilterDefault = parseInt(dropdown_yearFilter, 10);

      resetPageSize = "Reset";
      totalPOCount = 0;
      this.setState(
        {
          dropdown_clientFilter:
            dropdownList.clientId != 0 && dropdownList.clientId != null && dropdownList.clientId != undefined
              ? this.state.dropdownList.clientList.filter(
                (e) => e.value === dropdownList.clientId
              )[0].value
              : 0,
          dropdown_shipFilter:
            dropdownList.shipId != 0 && dropdownList.shipId != null
              ? this.state.dropdownList.shipList.filter(
                (e) => e.value === dropdownList.shipId
              )[0].value
              : this.state.dropdownList.shipList.filter(
                (e) => e.parentValue === dropdownList.clientId
              )[0].value,
          clientName:
            dropdownList.clientId != 0 && dropdownList.clientId != null
              ? this.state.dropdownList.clientList.filter(
                (e) => e.value === dropdownList.clientId
              )[0].label
              : "",
          shipName: dropdownList.shipId != 0 && dropdownList.shipId != null
            ? this.state.dropdownList.shipList.filter(
              (e) => e.parentValue === dropdownList.clientId && e.value === dropdownList.shipId
            )[0].label
            : "",
          dropdown_yearFilter: dropdownList.clientId != 0 && dropdownList.clientId != null && dropdownList.clientId != undefined
            ? dropdownYearList.filter((x) => x.parentValue == dropdownList.clientId)[0].value
            : 0,
          hasMoreData: true,
          mappedTrackingTool: [],
        },
        async () => {
          this.filterYearDropdownBasisClient();
          await this.getAllTrackingTool();
        }
        // && e.value === dropdownList.shipId
      );
    } else {
      if (this.state.dropdownList.clientList.length > 0) {
        let dropdown_shipFilterDefault;
        let dropdown_yearFilterDefault;
        let dropdownInitialList = this.state.dropdownList;
        if (dropdown_shipFilter === 0 || dropdown_shipFilter === "" || dropdown_shipFilter === null) {
          dropdown_shipFilterDefault = dropdownInitialList.shipList.filter((e) => e.parentValue === dropdownInitialList.clientList[0].value)[0].value;
        }
        else
          dropdown_shipFilterDefault = parseInt(dropdown_shipFilter, 10);

        if (dropdown_yearFilter === 0 || dropdown_yearFilter === "" || dropdown_yearFilter === null) {
          dropdown_yearFilterDefault = dropdownYearList.filter((x) => x.parentValue == dropdownInitialList.clientList[0].value)[0].value;
        }
        else
          dropdown_yearFilterDefault = parseInt(dropdown_yearFilter, 10);


        resetPageSize = "Reset";
        totalPOCount = 0;
        this.setState(
          {
            dropdown_clientFilter: this.state.dropdownList.clientList[0].value,
            dropdown_shipFilter: dropdown_shipFilterDefault,
            dropdown_yearFilter: dropdown_yearFilterDefault,
            hasMoreData: true,
            mappedTrackingTool: [],
          },
          async () => {
            await this.getAllTrackingTool();
          }
        );
      } else {
        this.setState({ isLoading: false });
      }
    }
  };

  supplierclicked = async (supplierId) => {
    const response = await SupplierService.GetManageSupplierData(supplierId, 0);

    if (response.data != null && response.data != undefined) {
      let supplierData = response.data.filter(
        (s) => s.supplierId === supplierId
      );
      AddLocalStorageData("selectedSupplier", JSON.stringify(supplierData[0]));
      AddLocalStorageData("location", "/TrackingTool");
    }
    this.navigateTo("/AddEditSupplier");
  };

  ShowLoggedInClientData = () => {
    const { userId, dropdownList, defaultShipDataList, isClient } = this.state;
    let shipSelectedByClient = GetLocalStorageDataWithoutParse(
      "selectClienDashboardShip"
    );
    if (shipSelectedByClient == "0") {
      var selectedShipTTool = GetLocalStorageDataWithParse("selectedShipTTool");
      shipSelectedByClient = selectedShipTTool?.shipId ?? 0;
    }

    var loc = GetLocalStorageDataWithoutParse("location");
    if (loc == "/ManageShips") {
      var selectedShipTTool = GetLocalStorageDataWithParse("selectedShipTTool");
      shipSelectedByClient = selectedShipTTool?.shipId ?? 0;
    }

    var userData = GetLocalStorageDataWithParse("loggedInUser");
    let id = isClient ? userId : userData.clientId;

    let filteredShipDropdown = defaultShipDataList.filter(
      (e) => e.parentValue === parseInt(id, 10)
    );

    resetPageSize = "Reset";
    totalPOCount = 0;
    this.setState(
      {
        dropdown_clientFilter:
          parseInt(id, 10) != 0
            ? dropdownList.clientList.filter(
              (e) => e.value === parseInt(id, 10)
            )[0]?.value
            : 0,

        clientName:
          parseInt(id, 10) != 0
            ? dropdownList.clientList.filter(
              (e) => e.value === parseInt(id, 10, 10)
            )[0]?.label
            : "",

        dropdown_shipFilter:
          shipSelectedByClient != 0
            ? dropdownList.shipList.filter(
              (e) => e.value === parseInt(shipSelectedByClient, 10)
            )[0]?.value
            : 0,
        shipDataList: filteredShipDropdown,
        isLoading: false,
        hasMoreData: true,
        mappedTrackingTool: [],
      },
      async () => {
        await this.getAllTrackingTool();
      }
    );
  };

  ShowLoggedInShipData = () => {
    const { userId, dropdownList, defaultShipDataList } = this.state;

    let filteredShipDropdown = defaultShipDataList.filter(
      (e) => e.value === parseInt(userId, 10)
    );

    let clientNameData = dropdownList.clientList.filter(
      (e) => e.value === parseInt(filteredShipDropdown[0]?.parentValue, 10)
    );

    resetPageSize = "Reset";
    totalPOCount = 0;
    this.setState(
      {
        dropdown_clientFilter: clientNameData[0]?.value,
        clientName: clientNameData[0]?.label,
        dropdown_shipFilter: filteredShipDropdown[0]?.value,
        shipName: filteredShipDropdown[0]?.label,
        isLoading: false,
        hasMoreData: true,
        mappedTrackingTool: [],
      },
      async () => {
        await this.getAllTrackingTool();
      }
    );
  };

  historyColumn = [
    {
      headerName: "Serial Number",
      // field: "serialNumber",
      valueGetter: "node.rowIndex + 1",
      unSortIcon: false,
      sortable: false,
      filter: false,
      width: 90,
      cellStyle: {
        wordwrap: "break-word",
        whitespace: "nowrap",
      },
    },
    {
      headerName: "Ship Name",
      field: "shipName",
      tooltipField: "shipName",
      cellStyle: {
        textOverflow: "ellipsis",
        whiteSpace: "pre",
        overflow: "hidden",
      },
    },
    {
      headerName: "PO Number",
      field: "ponumber",
      tooltipField: "ponumber",
      cellRenderer: (params) =>
        params.data.poFilePath === undefined ||
          params.data.poFilePath === null ? (
          <label style={{ fontWeight: "normal" }}>{params.data.ponumber}</label>
        ) : (
          <a onClick={() => this.viewPOFile(params.data.poFilePath)}>
            {params.data.ponumber}
          </a>
        ),
    },
    {
      headerName: "PO Date",
      field: "podate",
      tooltipField: "podate",
      cellRenderer: (params) =>
        params.data.podate === undefined ||
          params.data.podate === null ||
          params.data.podate.includes("0001") ? (
          <label />
        ) : (
          <label style={{ fontWeight: "normal" }}>{params.data.podate}</label>
        ),
    },
    {
      headerName: "Supplier Branch Name",
      field: "supplierBranchName",
      tooltipField: "supplierBranchName",
      cellStyle: {
        textOverflow: "ellipsis",
        whiteSpace: "pre",
        overflow: "hidden",
      },
    },
    {
      headerName: "Supplier Email",
      field: "supplierEmail",
      tooltipField: "supplierEmail",
      cellStyle: {
        textOverflow: "ellipsis",
        whiteSpace: "pre",
        overflow: "hidden",
      },
    },
    {
      headerName: "Supplier Name",
      field: "supplierName",
      tooltipField: "supplierName",
      cellStyle: {
        textOverflow: "ellipsis",
        whiteSpace: "pre",
        overflow: "hidden",
      },
    },
    {
      headerName: "Supplier Address",
      field: "supplierAddress",
      tooltipField: "supplierAddress",
      cellStyle: {
        textOverflow: "ellipsis",
        whiteSpace: "pre",
        overflow: "hidden",
      },
    },
    {
      headerName: "Supplier Contact Number",
      field: "supplierContact",
      tooltipField: "supplierContact",
    },
    {
      headerName: "Issuer Name",
      field: "issuerName",
      tooltipField: "issuerName",
      cellStyle: {
        textOverflow: "ellipsis",
        whiteSpace: "pre",
        overflow: "hidden",
      },
    },
    {
      headerName: "Issuer Address",
      field: "issuerAddress",
      tooltipField: "issuerAddress",
      cellStyle: {
        textOverflow: "ellipsis",
        whiteSpace: "pre",
        overflow: "hidden",
      },
    },
    {
      headerName: "Supplier Company Name",
      field: "supplierCompanyName",
      tooltipField: "supplierCompanyName",
      cellStyle: {
        textOverflow: "ellipsis",
        whiteSpace: "pre",
        overflow: "hidden",
      },
    },
    {
      headerName: "Part Name",
      field: "partName",
      tooltipField: "partName",
      cellStyle: {
        textOverflow: "ellipsis",
        whiteSpace: "pre",
        overflow: "hidden",
      },
    },
    {
      headerName: "Sub Part Name",
      field: "subPartName",
      tooltipField: "subPartName",
      cellStyle: {
        textOverflow: "ellipsis",
        whiteSpace: "pre",
        overflow: "hidden",
      },
    },
    {
      headerName: "Equipment Used",
      field: "equipmentUsed",
      tooltipField: "equipmentUsed",
      cellStyle: {
        textOverflow: "ellipsis",
        whiteSpace: "pre",
        overflow: "hidden",
      },
    },
    {
      headerName: "Part Maker Name",
      field: "partMakerName",
      tooltipField: "partMakerName",
      cellStyle: {
        textOverflow: "ellipsis",
        whiteSpace: "pre",
        overflow: "hidden",
      },
    },
    {
      headerName: "Hazmat Present Status",
      field: "hazmatPresent",
      //tooltipField: "hazmatPresent",
      cellRenderer: (params) =>
        params.data.hazmatPresent === false ? (
          <label style={{ fontWeight: "normal" }}>No</label>
        ) : params.data.hazmatPresent === true ? (
          <label style={{ fontWeight: "normal" }}>Yes</label>
        ) : (
          <label />
        ),
    },
    {
      headerName: "Qty",
      field: "quantity",
      tooltipField: "quantity",
      comparator: alphaNumComparator,
    },
    {
      headerName: "Unit",
      field: "unit",
      tooltipField: "unit",
    },
    {
      headerName: "Description",
      field: "description",
      tooltipField: "description",
      cellStyle: {
        textOverflow: "ellipsis",
        whiteSpace: "pre",
        overflow: "hidden",
      },
    },
    {
      headerName: "Line Item Number",
      field: "lineItemNumber",
      tooltipField: "lineItemNumber",
      sortable: false,
      unSortIcon: false,
      //comparator: customSortLineItemNoComparator,
    },
    {
      headerName: "Master Category",
      field: "masterCategory",
      tooltipField: "masterCategory",
      cellStyle: {
        textOverflow: "ellipsis",
        whiteSpace: "pre",
        overflow: "hidden",
      },
    },
    {
      headerName: "Supplier Communication Commencement Date",
      field: "supplierCommencementDate",
      tooltipField: "supplierCommencementDate",
      cellRenderer: (params) =>
        params.data.supplierCommencementDate === null ||
          params.data.supplierCommencementDate.includes("0001") ? (
          <label />
        ) : (
          <label style={{ fontWeight: "normal" }}>
            {params.data.supplierCommencementDate}
          </label>
        ),
    },
    {
      headerName: "MD/SDoC Received",
      //tooltipField: "mdsDoCReceived",
      cellRenderer: (params) => (
        <label style={{ fontWeight: "normal" }}>
          {params.data.mdsDoCReceived === null ||
            params.data.mdsDoCReceived === false
            ? "NO"
            : params.data.mdsDoCReceived === true
              ? "YES"
              : "NO"}
        </label>
      ),
    },
    {
      headerName: "MD/SDoC taken from CTI database",
      field: "mdsDoCFromCti",
      //tooltipField: "mdsDoCFromCti",
      cellRenderer: (params) => (
        <label style={{ fontWeight: "normal" }}>
          {params.data.mdsDoCFromCti === null ||
            params.data.mdsDoCFromCti === false
            ? "NO"
            : params.data.mdsDoCFromCti === true
              ? "YES"
              : "NO"}
        </label>
      ),
    },
    {
      headerName: "Date of MD/SDoC Requested",
      field: "mdsDoCRequestedDate",
      tooltipField: "mdsDoCRequestedDate",
    },
    {
      headerName: "Date of Last Reminder",
      field: "lastReminderDate",
      tooltipField: "lastReminderDate",
    },
    {
      headerName: "Number of Reminder",
      field: "reminderCount",
      tooltipField: "reminderCount",
    },
    {
      headerName: "Date the MD/SDoC was received",
      field: "mdsDoCReceivedDate",
      tooltipField: "mdsDoCReceivedDate",
    },
    {
      headerName: "Hazmat Items Updated in IHM Part 1",
      field: "hazmatUpdatedIhmpart1",
      //tooltipField: "hazmatUpdatedIhmpart1",
      cellRenderer: (params) =>
        params.data.hazmatUpdatedIhmpart1 === 2 ? (
          <label style={{ fontWeight: "normal" }}>No</label>
        ) : params.data.hazmatUpdatedIhmpart1 === 1 ? (
          <label style={{ fontWeight: "normal" }}>Yes</label>
        ) : (
          <label />
        ),
    },
    {
      headerName: "Lab Testing of Samples",
      field: "labTestSample",
      tooltipField: "labTestSample",
    },
    {
      headerName: "Table A (Prohibited)",
      field: "tableAHazmatItemsText",
      tooltipField: "tableAHazmatItemsText",
    },
    {
      headerName: "Table B (Restricted)",
      field: "tableBHazmatItemsText",
      tooltipField: "tableBHazmatItemsText",
    },
    {
      headerName: "Last Communication Date",
      field: "lastCommunicationDate",
      tooltipField: "lastCommunicationDate",
      cellRenderer: (params) =>
        params.data.lastCommunicationDate === undefined ||
          params.data.lastCommunicationDate === null ||
          params.data.lastCommunicationDate.includes("0001") ? (
          <label />
        ) : (
          <label style={{ fontWeight: "normal" }}>
            {params.data.lastCommunicationDate}
          </label>
        ),
    },
    {
      headerName: "Remark / Status",
      field: "polineItemStatusToDisplay",
      tooltipField: "polineItemStatusToDisplay",
    },
    {
      headerName: "Supplier Status",
      field: "supplierStatus",
      tooltipField: "supplierStatus",
    },
    {
      headerName: "Action",
      field: "action",
      tooltipField: "action",
    },
    {
      headerName: "Edited By",
      field: "editedByName",
      tooltipField: "editedByName",
    },
    {
      pinned: "right",
      headerName: "Edited Date",
      field: "editedOnToDisplay",
      tooltipField: "editedOnToDisplay",
    },
  ];

  /* <summary>
   date: 23/11/2022
   Name: DN
   description: Bind AG grid columns
  <summary> */
  gridColumns = () => {
    return [
      {
        headerName: "",
        field: "",
        width: 80,
        cellRenderer: (params) => (
          <TagRenderer
            path={
              params.data.poFilePath === undefined ||
                params.data.poFilePath === null
                ? ""
                : params.data.poFilePath
            }
          />
        ),
        sortable: false,
        filter: false,
      },
      {
        headerName: "",
        headerCheckboxSelection: true,
        checkboxSelection: (params) =>
          params.data.trackingToolId == null || params.data.trackingToolId == 0
            ? false
            : true,
        width: 50,
        unSortIcon: false,
      },
      {
        headerName: "Serial Number",
        //  field: "serialNumber",
        valueGetter: "node.rowIndex + 1",
        width: 90,
        cellStyle: {
          wordwrap: "break-word",
          whitespace: "nowrap",
        },
        unSortIcon: false,
        sortable: false,
        filter: false,
      },
      {
        headerName: "Ship Name",
        field: "shipName",
        tooltipField: "shipName",
        editable: !this.state.userRolePermissionRead,
        cellStyle: {
          textOverflow: "ellipsis",
          whiteSpace: "pre",
          overflow: "hidden",
        },
      },
      {
        headerName: "PO Number",
        field: "ponumber",
        tooltipField: "ponumber",
        editable: !this.state.userRolePermissionRead,
        cellRenderer: (params) =>
          params.data.poFilePath === undefined ||
            params.data.poFilePath === null ? (
            <label style={{ fontWeight: "normal!important" }}>
              {params.data.ponumber}
            </label>
          ) : (
            <a onClick={() => this.viewPOFile(params.data.poFilePath)}>
              {params.data.ponumber}
            </a>
          ),
      },
      {
        headerName: "PO Date",
        field: "podate",
        tooltipField: "podate",
        filter: false,
        // editable: !this.state.userRolePermissionRead,
        cellRenderer: (params) => (
          <DatePicker
            readOnly={this.state.userRolePermissionRead}
            selectedDate={
              params.data.podate === null || params.data.podate.includes("0001")
                ? null
                : new Date(params.data.podate)
            }
            onDateChange={(e) => this.onPODateChanged(e, params.data)}
            isNotRoot={this.state.showZoom ? true : false}
            minDate={new Date(params.data.minPoDate)}
            maxDate={new Date(params.data.maxPoDate)}
          >
            {" "}
            {""}{" "}
          </DatePicker>
        ),
      },
      {
        headerName: "Supplier Branch Name",
        field: "supplierBranchName",
        tooltipField: "supplierBranchName",
        editable: !this.state.userRolePermissionRead,
        cellStyle: {
          textOverflow: "ellipsis",
          whiteSpace: "pre",
          overflow: "hidden",
        },
      },
      {
        headerName: "Supplier Email",
        field: "supplierEmail",
        tooltipField: "supplierEmail",
        editable: !this.state.userRolePermissionRead,
        cellStyle: {
          textOverflow: "ellipsis",
          whiteSpace: "pre",
          overflow: "hidden",
        },
      },
      {
        headerName: "Supplier Name",
        filter: false,
        width: 150,
        field: "supplierName",
        tooltipField: "supplierName",
        cellStyle: {
          textOverflow: "ellipsis",
          whiteSpace: "pre",
          overflow: "hidden",
        },
        cellRenderer: (params) =>
          !this.state.isClient &&
            this.state.isAdminOrInternalUser &&
            params.data.supplierId != null &&
            params.data.supplierId != 0 &&
            (this.state.accessList != null
              ? this.state.accessList.supplierAccess != UserAction.DENY
              : true) &&
            params.data.supplierStatus != null &&
            params.data.supplierStatus == "Active" ? (
            <a
              onClick={() => {
                this.supplierclicked(params.data.supplierId);
              }}
              style={{ cursor: "pointer" }}
            >
              {params.data.supplierName}
            </a>
          ) : (
            <label style={{ fontWeight: "normal" }}>
              {params.data.supplierName}
            </label>
          ),
      },
      {
        headerName: "Supplier Address",
        field: "supplierAddress",
        tooltipField: "supplierAddress",
        //editable: !this.state.userRolePermissionRead,
        cellStyle: {
          textOverflow: "ellipsis",
          whiteSpace: "pre",
          overflow: "hidden",
        },
      },
      {
        headerName: "Supplier Contact Number",
        field: "supplierContact",
        tooltipField: "supplierContact",
        //editable: !this.state.userRolePermissionRead,
      },
      {
        headerName: "Issuer Name",
        field: "issuerName",
        tooltipField: "issuerName",
        editable: !this.state.userRolePermissionRead,
        cellStyle: {
          textOverflow: "ellipsis",
          whiteSpace: "pre",
          overflow: "hidden",
        },
      },
      {
        headerName: "Issuer Address",
        field: "issuerAddress",
        tooltipField: "issuerAddress",
        editable: !this.state.userRolePermissionRead,
        cellStyle: {
          textOverflow: "ellipsis",
          whiteSpace: "pre",
          overflow: "hidden",
        },
      },
      {
        headerName: "Supplier Company Name",
        field: "supplierCompanyName",
        tooltipField: "supplierCompanyName",
        // editable: !this.state.userRolePermissionRead,
        cellStyle: {
          textOverflow: "ellipsis",
          whiteSpace: "pre",
          overflow: "hidden",
        },
      },
      {
        headerName: "Part Name",
        field: "partName",
        tooltipField: "partName",
        editable: !this.state.userRolePermissionRead,
        cellStyle: {
          textOverflow: "ellipsis",
          whiteSpace: "pre",
          overflow: "hidden",
        },
      },
      {
        headerName: "Sub Part Name",
        field: "subPartName",
        tooltipField: "subPartName",
        editable: !this.state.userRolePermissionRead,
        cellStyle: {
          textOverflow: "ellipsis",
          whiteSpace: "pre",
          overflow: "hidden",
        },
      },
      {
        headerName: "Equipment Used",
        field: "equipmentUsed",
        tooltipField: "equipmentUsed",
        editable: !this.state.userRolePermissionRead,
        cellStyle: {
          textOverflow: "ellipsis",
          whiteSpace: "pre",
          overflow: "hidden",
        },
      },
      {
        headerName: "Part Maker Name",
        field: "partMakerName",
        tooltipField: "partMakerName",
        editable: !this.state.userRolePermissionRead,
        cellStyle: {
          textOverflow: "ellipsis",
          whiteSpace: "pre",
          overflow: "hidden",
        },
      },
      {
        headerName: "Qty",
        field: "quantity",
        tooltipField: "quantity",
        editable: !this.state.userRolePermissionRead,
        comparator: alphaNumComparator,
      },
      {
        headerName: "Unit",
        field: "unit",
        tooltipField: "unit",
        editable: !this.state.userRolePermissionRead,
      },
      {
        headerName: "Description",
        field: "description",
        tooltipField: "description",
        editable: !this.state.userRolePermissionRead,
        cellStyle: {
          textOverflow: "ellipsis",
          whiteSpace: "pre",
          overflow: "hidden",
        },
      },
      {
        headerName: "Line Item Number",
        field: "lineItemNumber",
        tooltipField: "lineItemNumber",
        editable: !this.state.userRolePermissionRead,
        sortable: false,
        unSortIcon: false,
        //comparator: customSortLineItemNoComparator,
        cellStyle: {
          textOverflow: "ellipsis",
          whiteSpace: "pre",
          overflow: "hidden",
        },
      },
      {
        headerName: "Master Category",
        field: "masterCategory",
        tooltipField: "masterCategory",
        editable: !this.state.userRolePermissionRead,
        cellStyle: {
          textOverflow: "ellipsis",
          whiteSpace: "pre",
          overflow: "hidden",
        },
      },
      {
        headerName: "Supplier Communication Commencement Date",
        field: "supplierCommencementDate",
        filter: false,
        tooltipField: "supplierCommencementDate",
        cellRenderer: (params) =>
          params.data.supplierCommencementDate === null ||
            params.data.supplierCommencementDate.includes("0001") ? (
            <label />
          ) : (
            <label style={{ fontWeight: "normal" }}>
              {params.data.supplierCommencementDate}
            </label>
          ),
        // cellRenderer: (params) => (
        //   <DatePicker
        //     readOnly={
        //       this.state.accessList != null &&
        //       this.state.accessList.trackingToolAccess == UserAction.READ
        //         ? true
        //         : false
        //     }
        //     selectedDate={
        //       params.data.supplierCommencementDate === null ||
        //       params.data.supplierCommencementDate.includes("0001")
        //         ? null
        //         : new Date(params.data.supplierCommencementDate)
        //     }
        //     onDateChange={(e) => this.onSupplierCommDateChanged(e, params.data)}
        //     isNotRoot={this.state.showZoom ? true : false}
        //   >
        //     {" "}
        //     {""}{" "}
        //   </DatePicker>
        // ),
      },
      {
        headerName: "Hazmat Present Status",
        field: "hazmatPresent",
        filter: false,
        //tooltipField: "hazmatPresent",
        editable: !this.state.userRolePermissionRead,
        cellRenderer: (params) =>
          params.data.hazmatPresent === false ? (
            <label style={{ fontWeight: "normal" }}>No</label>
          ) : params.data.hazmatPresent === true ? (
            <label style={{ fontWeight: "normal" }}>Yes</label>
          ) : (
            <label />
          ),
      },
      {
        headerName: "MD/SDoC Received",
        field: "mdsDoCReceived",
        filter: false,
        sortable: true,
        // tooltipField: "mdsDoCReceived",
        cellRenderer: (params) => (
          <label style={{ fontWeight: "normal" }}>
            {params.data.mdsDoCReceived === null ||
              params.data.mdsDoCReceived === false
              ? "NO"
              : params.data.mdsDoCReceived === true
                ? "YES"
                : "NO"}
          </label>
        ),
        //editable: !this.state.userRolePermissionRead,
      },
      {
        headerName: "MD/SDoC taken from CTI database",
        filter: false,
        field: "mdsDoCFromCti",
        //tooltipField: "mdsDoCFromCti",
        //editable: !this.state.userRolePermissionRead,
        cellRenderer: (params) => (
          <label style={{ fontWeight: "normal" }}>
            {params.data.mdsDoCFromCti === null ||
              params.data.mdsDoCFromCti === false
              ? "NO"
              : params.data.mdsDoCFromCti === true
                ? "YES"
                : "NO"}
          </label>
        ),
      },
      {
        headerName: "Date of MD/SDoC Requested",
        field: "mdsDoCRequestedDate",
        tooltipField: "mdsDoCRequestedDate",
        filter: false,
        cellRenderer: (params) =>
          params.data.mdsDoCRequestedDate === undefined ||
            params.data.mdsDoCRequestedDate === null ||
            params.data.mdsDoCRequestedDate.includes("0001") ? (
            <label />
          ) : (
            <label style={{ fontWeight: "normal" }}>
              {params.data.mdsDoCRequestedDate}
            </label>
          ),
      },
      {
        headerName: "Date of Last Reminder",
        field: "lastReminderDate",
        tooltipField: "lastReminderDate",
        filter: false,
        cellRenderer: (params) =>
          params.data.lastReminderDate === undefined ||
            params.data.lastReminderDate === null ||
            params.data.lastReminderDate.includes("0001") ? (
            <label />
          ) : (
            <label style={{ fontWeight: "normal" }}>
              {params.data.lastReminderDate}
            </label>
          ),
      },
      {
        headerName: "Number of Reminder",
        field: "reminderCount",
        tooltipField: "reminderCount",
        //editable: !this.state.userRolePermissionRead,
      },
      {
        headerName: "Date the MD/SDoC was received",
        field: "mdsDoCReceivedDate",
        filter: false,
        tooltipField: "mdsDoCReceivedDate",
        cellRenderer: (params) =>
          params.data.mdsDoCReceivedDate === undefined ||
            params.data.mdsDoCReceivedDate === null ||
            params.data.mdsDoCReceivedDate.includes("0001") ? (
            <label />
          ) : (
            <label style={{ fontWeight: "normal" }}>
              {params.data.mdsDoCReceivedDate}
            </label>
          ),
      },
      {
        headerName: "Hazmat Items Updated in IHM Part 1",
        field: "hazmatUpdatedIhmpart1",
        //tooltipField: "hazmatUpdatedIhmpart1",
        filter: false,
        editable: !this.state.userRolePermissionRead,
        cellRenderer: (params) =>
          params.data.hazmatUpdatedIhmpart1 === 2 ? (
            <label style={{ fontWeight: "normal" }}>No</label>
          ) : params.data.hazmatUpdatedIhmpart1 === 1 ? (
            <label style={{ fontWeight: "normal" }}>Yes</label>
          ) : (
            <label />
          ),
      },
      {
        headerName: "Lab Testing of Samples",
        field: "labTestSample",
        tooltipField: "labTestSample",
        editable: !this.state.userRolePermissionRead,
      },
      {
        headerName: "Table A (Prohibited)",
        field: "listTableAHazmat",
        filter: false,
        tooltipField: "tableAHazmatItemsText",
        comparator: customSortTableAComparator,
        cellRenderer: (params) => (
          <AGGridMultiSelectDropdown
            name="listTableAHazmat"
            selectedOptions={
              params.data.listTableAHazmat == undefined
                ? []
                : params.data.listTableAHazmat
            }
            options={tableAListPrePopulated}
            disabled={
              this.state.accessList != null &&
                this.state.accessList.trackingToolAccess == UserAction.READ
                ? true
                : false
            }
            onChange={(e) => this.onTableAGridDropdownChange(e, params.data)}
          />
        ),
        width: 250,
      },
      {
        headerName: "Table B (Restricted)",
        field: "listTableBHazmat",
        filter: false,
        tooltipField: "tableBHazmatItemsText",
        comparator: customSortTableBComparator,
        cellRenderer: (params) => (
          <AGGridMultiSelectDropdown
            name="listTableBHazmat"
            selectedOptions={
              params.data.listTableBHazmat == undefined
                ? []
                : params.data.listTableBHazmat
            }
            options={tableBListPrePopulated}
            disabled={
              this.state.accessList != null
                ? this.state.accessList.trackingToolAccess == UserAction.READ
                  ? true
                  : false
                : false
            }
            onChange={(e) => this.onTableBGridDropdownChange(e, params.data)}
          />
        ),
        width: 250,
      },
      {
        headerName: "Last Communication Date",
        field: "lastCommunicationDate",
        tooltipField: "lastCommunicationDate",
        //editable: !this.state.userRolePermissionRead,
        filter: false,
        cellRenderer: (params) =>
          params.data.lastCommunicationDate === undefined ||
            params.data.lastCommunicationDate === null ||
            params.data.lastCommunicationDate.includes("0001") ? (
            <label />
          ) : (
            <label style={{ fontWeight: "normal" }}>
              {params.data.lastCommunicationDate}
            </label>
          ),
      },
      {
        headerName: "Remark / Status",
        field: "polineItemStatus",
        tooltipField: "polineItemStatusToDisplay",
        filter: false,
        comparator: customSortRemarkComparator,
        cellRenderer: (params) =>
          params.data.trackingToolId != null &&
            params.data.trackingToolId > 0 ? (
            <MultiSelectDropdown
              name="remark"
              selectedOptions={
                params.data.polineItemStatus
                  ? parseInt(params.data.polineItemStatus, 10)
                  : 0
              } //parseInt(params.data.polineItemStatus)
              options={statusDataList}
              disabled={
                this.state.accessList != null
                  ? this.state.accessList.trackingToolAccess == UserAction.READ
                    ? true
                    : false
                  : false
              }
              onChange={(e) => this.onStatusSelected(e, params.data)}
              isMulti={false}
            />
          ) : null,
      },
      {
        headerName: "Supplier Status",
        field: "supplierStatus",
        tooltipField: "supplierStatus",
      },
      {
        headerName: "Table A Text",
        field: "tableAHazmatItemsText",
        hide: true,
      },
      {
        headerName: "Table B Text",
        field: "tableBHazmatItemsText",
        hide: true,
      },
      {
        headerName: "Remark Text",
        field: "polineItemStatusToDisplay",
        hide: true,
      },
      {
        pinned: "right",
        headerName: "Request MD/SDoC",
        filter: false,
        sortable: false,
        unSortIcon: false,
        width: 150,
        cellRenderer: (params) =>
          params.data.mdsDoCRequestedDate === null ? (
            <a
              onClick={() => {
                (this.state.accessList != null &&
                  this.state.accessList.trackingToolAccess ==
                  UserAction.READ) ||
                  params.data.supplierStatus == "On Hold" ||
                  params.data.supplierId == "" ||
                  params.data.supplierId == null ||
                  params.data.supplierId == 0 ||
                  params.data.ponumber == "" ||
                  params.data.quantity == "" ||
                  params.data.unit == "" ||
                  params.data.description == ""
                  ? ""
                  : this.showHideConfirmationModal(
                    true,
                    params.data,
                    "request the MD/SDoc to the supplier " +
                    params?.data?.supplierName +
                    "?"
                  );
              }}
              style={
                (this.state.accessList != null &&
                  this.state.accessList.trackingToolAccess ==
                  UserAction.READ) ||
                  params.data.hideIcons == true ||
                  params.data.supplierStatus == "On Hold" ||
                  params.data.supplierId == "" ||
                  params.data.supplierId == null ||
                  params.data.supplierId == 0 ||
                  params.data.description == "" ||
                  params.data.ponumber == "" ||
                  params.data.quantity == "" ||
                  params.data.unit == "" ||
                  this.state.userRolePermissionRead
                  ? {
                    pointerEvents: "none",
                    cursor: "default",
                    opacity: "0.6",
                    color: "#888888",
                  }
                  : { cursor: "pointer" }
              }
            >
              Send Email
            </a>
          ) : (
            <a
              onClick={() => {
                (this.state.accessList != null &&
                  this.state.accessList.trackingToolAccess ==
                  UserAction.READ) ||
                  params.data.supplierStatus == "On Hold" ||
                  params.data.supplierEmail == "" ||
                  params.data.supplierEmail == null ||
                  params.data.ponumber == "" ||
                  params.data.quantity == "" ||
                  params.data.unit == "" ||
                  params.data.description == ""
                  ? ""
                  : this.showHideConfirmationModal(
                    true,
                    params.data,
                    "request the MD/SDoc to the supplier " +
                    params?.data?.supplierName +
                    "?"
                  );
              }}
              style={
                (this.state.accessList != null &&
                  this.state.accessList.trackingToolAccess ==
                  UserAction.READ) ||
                  params.data.hideIcons == true ||
                  params.data.supplierStatus == "On Hold" ||
                  params.data.supplierEmail == "" ||
                  params.data.supplierEmail == null ||
                  params.data.ponumber == "" ||
                  params.data.quantity == "" ||
                  params.data.unit == "" ||
                  params.data.description == ""
                  ? {
                    pointerEvents: "none",
                    cursor: "default",
                    opacity: "0.9",
                    color: "#888888",
                  }
                  : { cursor: "pointer", marginLeft: "-5px" }
              }
            >
              Send Reminder
            </a>
          ),
      },
      {
        pinned: "right",
        filter: false,
        sortable: false,
        unSortIcon: false,
        width: 40,
        cellRenderer: (params) =>
          params.data.trackingToolId != null &&
            params.data.trackingToolId > 0 ? (
            <img
              className="cursor-class"
              src={this.state.userRolePermissionRead ? eyeIcon : editIcon}
              title={this.state.userRolePermissionRead ? "View" : "Edit"}
              width="20px"
              onClick={() => {
                this.onEditTrackingToolClicked(params.data.trackingToolId);
              }}
              style={
                params.data.hideIcons == true
                  ? {
                    pointerEvents: "none",
                    cursor: "default",
                    opacity: "0.9",
                    color: "#888888",
                    marginLeft: "-5px",
                  }
                  : { cursor: "pointer" }
              }
            />
          ) : null,
        cellStyle: { textAlign: "center", paddingInline: "0" },
      },
      {
        pinned: "right",
        filter: false,
        sortable: false,
        unSortIcon: false,
        width: 40,
        cellRenderer: (params) => (
          <img
            className="cursor-class"
            src={historyIcon}
            width="20px"
            title="History"
            data-bs-toggle="modal"
            data-bs-target="#view-history-Modal"
            onClick={() =>
              this.onViewHistoryLineItemClicked(params.data.trackingToolId)
            }
            style={
              params.data.hideIcons == true
                ? {
                  pointerEvents: "none",
                  marginLeft: "-5px",
                  cursor: "default",
                  opacity: "0.9",
                  color: "#888888",
                }
                : { cursor: "pointer" }
            }
          />
        ),
        cellStyle: { textAlign: "center", paddingInline: "0" },
      },
      {
        pinned: "right",
        headerName: "",
        filter: false,
        unSortIcon: false,
        width: 40,
        sortable: false,
        cellRenderer: (params) => {
          return (
            (params.data.mdsDoCRequestedDate != null || params.data.polineItemStatusToDisplay == "Completed" || this.state.userRolePermissionRead) ? null :
              (
                <img
                  className="cursor-class deleteimg"
                  src={deleteIcon}
                  title="Delete"
                  onClick={(e) => { this.showDeleteDocModal(params.data.trackingToolId); }}
                  //style={{width: '28px'}}
                  width='15px'
                />
              )
          )
        },
        cellStyle: { textAlign: "center", paddingInline: "0" }
      },

    ];
  };

  gridClientColumns = [
    {
      headerName: "",
      field: "",
      width: 80,
      cellRenderer: (params) => (
        <TagRenderer
          path={
            params.data.poFilePath === undefined ||
              params.data.poFilePath === null
              ? ""
              : params.data.poFilePath
          }
        />
      ),
      sortable: false,
      filter: false,
    },
    {
      headerName: "",
      headerCheckboxSelection: true,
      checkboxSelection: (params) =>
        params.data.trackingToolId == null || params.data.trackingToolId == 0
          ? false
          : true,
      width: 50,
      unSortIcon: false,
    },
    {
      headerName: "Serial Number",
      // field: "serialNumber",
      valueGetter: "node.rowIndex + 1",
      width: 90,
      cellStyle: {
        wordwrap: "break-word",
        whitespace: "nowrap",
      },
      unSortIcon: false,
      sortable: false,
      filter: false,
    },
    {
      headerName: "Ship Name",
      field: "shipName",
      tooltipField: "shipName",
      cellStyle: {
        textOverflow: "ellipsis",
        whiteSpace: "pre",
        overflow: "hidden",
      },
    },
    {
      headerName: "PO Number",
      field: "ponumber",
      tooltipField: "ponumber",
      cellRenderer: (params) =>
        params.data.poFilePath === undefined ||
          params.data.poFilePath === null ? (
          <label style={{ fontWeight: "normal" }}>{params.data.ponumber}</label>
        ) : (
          <a onClick={() => this.viewPOFile(params.data.poFilePath)}>
            {params.data.ponumber}
          </a>
        ),
    },
    {
      headerName: "PO Date",
      field: "podate",
      tooltipField: "podate",
      cellRenderer: (params) =>
        params.data.podate === undefined ||
          params.data.podate === null ||
          params.data.podate.includes("0001") ? (
          <label />
        ) : (
          <label style={{ fontWeight: "normal" }}>{params.data.podate}</label>
        ),
    },
    {
      headerName: "Supplier Branch Name",
      field: "supplierBranchName",
      tooltipField: "supplierBranchName",
      cellStyle: {
        textOverflow: "ellipsis",
        whiteSpace: "pre",
        overflow: "hidden",
      },
    },
    {
      headerName: "Supplier Email",
      field: "supplierEmail",
      tooltipField: "supplierEmail",
      cellStyle: {
        textOverflow: "ellipsis",
        whiteSpace: "pre",
        overflow: "hidden",
      },
    },
    {
      headerName: "Supplier Name",
      filter: false,
      tooltipField: "supplierName",
      width: 150,
      cellRenderer: (params) =>
        !this.state.isClient &&
          this.state.isAdminOrInternalUser &&
          params.data.supplierId != null &&
          params.data.supplierId != 0 &&
          (this.state.accessList != null
            ? this.state.accessList.supplierAccess != UserAction.DENY
            : true) &&
          params.data.supplierStatus != null &&
          params.data.supplierStatus == "Active" ? (
          <a
            onClick={() => {
              this.supplierclicked(params.data.supplierId);
            }}
            style={{ cursor: "pointer" }}
          >
            {params.data.supplierName}
          </a>
        ) : (
          <label style={{ fontWeight: "normal" }}>
            {params.data.supplierName}
          </label>
        ),
    },
    {
      headerName: "Supplier Address",
      field: "supplierAddress",
      tooltipField: "supplierAddress",
      cellStyle: {
        textOverflow: "ellipsis",
        whiteSpace: "pre",
        overflow: "hidden",
      },
    },
    {
      headerName: "Supplier Contact Number",
      field: "supplierContact",
      tooltipField: "supplierContact",
    },
    {
      headerName: "Issuer Name",
      field: "issuerName",
      tooltipField: "issuerName",
      cellStyle: {
        textOverflow: "ellipsis",
        whiteSpace: "pre",
        overflow: "hidden",
      },
    },
    {
      headerName: "Issuer Address",
      field: "issuerAddress",
      tooltipField: "issuerAddress",
      cellStyle: {
        textOverflow: "ellipsis",
        whiteSpace: "pre",
        overflow: "hidden",
      },
    },
    {
      headerName: "Supplier Company Name",
      field: "supplierCompanyName",
      tooltipField: "supplierCompanyName",
      cellStyle: {
        textOverflow: "ellipsis",
        whiteSpace: "pre",
        overflow: "hidden",
      },
    },
    {
      headerName: "Part Name",
      field: "partName",
      tooltipField: "partName",
      cellStyle: {
        textOverflow: "ellipsis",
        whiteSpace: "pre",
        overflow: "hidden",
      },
    },
    {
      headerName: "Sub Part Name",
      field: "subPartName",
      tooltipField: "subPartName",
      cellStyle: {
        textOverflow: "ellipsis",
        whiteSpace: "pre",
        overflow: "hidden",
      },
    },
    {
      headerName: "Equipment Used",
      field: "equipmentUsed",
      tooltipField: "equipmentUsed",
      cellStyle: {
        textOverflow: "ellipsis",
        whiteSpace: "pre",
        overflow: "hidden",
      },
    },
    {
      headerName: "Part Maker Name",
      field: "partMakerName",
      tooltipField: "partMakerName",
      cellStyle: {
        textOverflow: "ellipsis",
        whiteSpace: "pre",
        overflow: "hidden",
      },
    },
    {
      headerName: "Master Category",
      field: "masterCategory",
      tooltipField: "masterCategory",
      cellStyle: {
        textOverflow: "ellipsis",
        whiteSpace: "pre",
        overflow: "hidden",
      },
    },
    {
      headerName: "Supplier Communication Commencement Date",
      field: "supplierCommencementDate",
      tooltipField: "supplierCommencementDate",
      cellRenderer: (params) =>
        params.data.supplierCommencementDate === null || params.data.supplierCommencementDate === undefined ||
          params.data.supplierCommencementDate.includes("0001") ? (
          <label />
        ) : (
          <label style={{ fontWeight: "normal" }}>
            {params.data.supplierCommencementDate}
          </label>
        ),
    },
    {
      headerName: "Hazmat Present Status",
      field: "hazmatPresent",
      //tooltipField: "hazmatPresent",
      cellRenderer: (params) =>
        params.data.hazmatPresent === false ? (
          <label style={{ fontWeight: "normal" }}>No</label>
        ) : params.data.hazmatPresent === true ? (
          <label style={{ fontWeight: "normal" }}>Yes</label>
        ) : (
          <label />
        ),
    },
    {
      headerName: "Qty",
      field: "quantity",
      tooltipField: "quantity",
      comparator: alphaNumComparator,
    },
    {
      headerName: "Unit",
      field: "unit",
      tooltipField: "unit",
    },
    {
      headerName: "Description",
      field: "description",
      tooltipField: "description",
      cellStyle: {
        textOverflow: "ellipsis",
        whiteSpace: "pre",
        overflow: "hidden",
      },
    },
    {
      headerName: "Line Item Number",
      field: "lineItemNumber",
      tooltipField: "lineItemNumber",
      sortable: false,
      unSortIcon: false,
      //comparator: customSortLineItemNoComparator,
    },
    {
      headerName: "MD/SDoC Received",
      //tooltipField: "mdsDoCReceived",
      cellRenderer: (params) => (
        <label style={{ fontWeight: "normal" }}>
          {params.data.mdsDoCReceived === null ||
            params.data.mdsDoCReceived === false
            ? "NO"
            : params.data.mdsDoCReceived === true
              ? "YES"
              : "NO"}
        </label>
      ),
    },
    {
      headerName: "MD/SDoC taken from CTI database",
      //tooltipField: "mdsDoCFromCti",
      cellRenderer: (params) => (
        <label style={{ fontWeight: "normal" }}>
          {params.data.mdsDoCFromCti === null ||
            params.data.mdsDoCFromCti === false
            ? "NO"
            : params.data.mdsDoCFromCti === true
              ? "YES"
              : "NO"}
        </label>
      ),
    },
    {
      headerName: "Date of MD/SDoC Requested",
      field: "mdsDoCRequestedDate",
      tooltipField: "mdsDoCRequestedDate",
    },
    {
      headerName: "Date of Last Reminder",
      field: "lastReminderDate",
      tooltipField: "lastReminderDate",
    },
    {
      headerName: "Number of Reminder",
      field: "reminderCount",
      tooltipField: "reminderCount",
    },
    {
      headerName: "Date the MD/SDoC was received",
      field: "mdsDoCReceivedDate",
      tooltipField: "mdsDoCReceivedDate",
    },
    {
      headerName: "Hazmat Items Updated in IHM Part 1",
      field: "hazmatUpdatedIhmpart1",
      //tooltipField: "hazmatUpdatedIhmpart1",
      cellRenderer: (params) =>
        params.data.hazmatUpdatedIhmpart1 === 2 ? (
          <label style={{ fontWeight: "normal" }}>No</label>
        ) : params.data.hazmatUpdatedIhmpart1 === 1 ? (
          <label style={{ fontWeight: "normal" }}>Yes</label>
        ) : (
          <label />
        ),
    },
    {
      headerName: "Lab Testing of Samples",
      field: "labTestSample",
      tooltipField: "labTestSample",
    },
    {
      headerName: "Table A (Prohibited)",
      field: "tableAHazmatItemsText",
      tooltipField: "tableAHazmatItemsText",
    },
    {
      headerName: "Table B (Restricted)",
      field: "tableBHazmatItemsText",
      tooltipField: "tableBHazmatItemsText",
    },
    {
      headerName: "Last Communication Date",
      field: "lastCommunicationDate",
      tooltipField: "lastCommunicationDate",
      cellRenderer: (params) =>
        params.data.lastCommunicationDate === undefined ||
          params.data.lastCommunicationDate === null ||
          params.data.lastCommunicationDate.includes("0001") ? (
          <label />
        ) : (
          <label style={{ fontWeight: "normal" }}>
            {params.data.lastCommunicationDate}
          </label>
        ),
    },
    {
      headerName: "Remark / Status",
      field: "polineItemStatusToDisplay",
      tooltipField: "polineItemStatusToDisplay",
    },
    {
      headerName: "Supplier Status",
      field: "supplierStatus",
      tooltipField: "supplierStatus",
    },
    {
      pinned: "right",
      filter: false,
      sortable: false,
      unSortIcon: false,
      width: 40,
      cellRenderer: (params) => (
        <img
          className="cursor-class"
          src={viewIcon}
          title="View"
          width="20px"
          // onClick={() => {
          //   this.state.accessList != null
          //     ? this.state.accessList.trackingToolAccess == UserAction.READ
          //       ? ""
          //       : this.onEditTrackingToolClicked(params.data.trackingToolId)
          //     : this.onEditTrackingToolClicked(params.data.trackingToolId);
          // }}
          onClick={() => {
            this.onEditTrackingToolClicked(params.data.trackingToolId);
          }}
        />
      ),
      cellStyle: { textAlign: "center", paddingInline: "0" },
    },
    {
      pinned: "right",
      filter: false,
      sortable: false,
      unSortIcon: false,
      width: 40,
      cellRenderer: (params) => (
        <img
          className="cursor-class"
          src={historyIcon}
          title="History"
          width="20px"
          data-bs-toggle="modal"
          data-bs-target="#view-history-Modal"
          onClick={() =>
            this.onViewHistoryLineItemClicked(params.data.trackingToolId)
          }
        />
      ),
      cellStyle: { textAlign: "center", paddingInline: "0" },
    },
  ];

  showDeleteDocModal = (id) => {
    this.setState({ showConfirmationPopup: true, selectdTTDeleteId: id });
  };

  deleteTTLineItemData = async () => {
    try {
      const { selectdTTDeleteId, userTypeId, userId } = this.state;
      this.setState({
        isLoading: true,
      });
      var response = await TrackingToolService.DeleteTTLineItemData(selectdTTDeleteId, userTypeId, userId);
      if (response == undefined) {
        this.setState({
          toastVisible: true,
          toastMessage: helperText.global.somethingWentWrong,
          toastType: helperText.global.toastError,
          hasMoreData: true,
          mappedTrackingTool: [],
        });
      } else if (response != null) {
        this.setState(
          {
            toastVisible: true,
            toastMessage: helperText.global.deletedDocumentdLineItem,
            toastType: helperText.global.toastSuccess,
            isLoading: false,
            hasMoreData: true,
            mappedTrackingTool: [],
          },
          async () => {
            await this.getAllTrackingTool();
          }
        );
      } else {
        this.setState({
          toastVisible: true,
          toastMessage: helperText.global.somethingWentWrong,
          toastType: helperText.global.toastError,
        });
      }

      this.setState({
        isLoading: false,
        showConfirmationPopup: false,
      });
    } catch (error) { }
  };


  onPODateChanged = async (e, ttData) => {
    ttData.podate = convertDate(new Date(e));
    let listToSave = [];
    listToSave.push(ttData);

    if (addManualRow == 0) await this.SaveCopyPastedRowData(listToSave);
    else this.gridApi.updateRowData(ttData);
  };

  onStatusSelected = (e, ttData) => {
    ttData.polineItemStatus = e.value;

    let listToSave = [];
    listToSave.push(ttData);

    if (e.label != "Other") {
      this.SaveCopyPastedRowData(listToSave);
    }

    if (e.label == "Other") {
      this.setState({
        showOtherValue: true,
        selectedItem: ttData,
        dataToSaveOtherValue: listToSave,
      });
    }
  };

  onSortChanged(e) {
    e.api.refreshCells();
  }

  closeToast = () => {
    this.setState({ toastVisible: false });
  };

  onCellKeyPress = async (e) => {
    if (e.event.code === "Enter") {
      //addManualRow = 0;
      const { dropdown_clientFilter, dropdown_yearFilter } = this.state;

      e.data.createdBy = this.state.userId;
      e.data.clientId = dropdown_clientFilter;

      let listToSave = [];
      listToSave.push(e.data);

      await this.SaveCopyPastedRowData(listToSave);
    }
  };

  handleChange = (selectedOptiondd) => {
    this.setState({
      selectedOption: selectedOptiondd,
    });
    //selectedOption = selectedOptiondd;
  };

  /* <summary>
 date: 21/03/2023
 Name: HV
 description: handled scenario if only cell is pastes
 <summary> */

  handlePaste = async (e) => {
    var existingTTList = this.state.mappedTrackingTool;
    const { dropdown_clientFilter, dropdown_yearFilter, dropdownYearList } =
      this.state;
    if (dropdown_yearFilter != 0 || dropdown_yearFilter != undefined) {
      const text = await navigator.clipboard.readText();
      var result = "";
      // Simplified parsing of the data with hard-coded columns
      const rows = text.split("\r\n").filter((n) => n);
      result = rows.map((row) => {
        if (row != "") {
          const cells = row.replace("\n", "").split("\t");
          if (cells.length > 1) {
            if (cells[1] == undefined) {
              this.setState(
                {
                  toastVisible: true,
                  toastType: helperText.global.toastError,
                  toastMessage: helperText.TrackingTool.poNumberMandatory,
                },
                () => {
                  return null;
                }
              );
            } else {
              let podate = cells[2] == undefined ? "" : cells[2];

              let saveData = true;

              if (podate != "") {
                saveData = this.CheckIfPODateDateISValid(podate);
              }

              if (saveData == false) {
                this.setState({
                  toastVisible: true,
                  toastType: helperText.global.toastError,
                  toastMessage: helperText.TrackingTool.invalidPODateError,
                });
                return;
              } else {
                return {
                  trackingToolId: 0,
                  shipName: cells[0] == undefined ? "" : cells[0],
                  ponumber: cells[1] == undefined ? "" : cells[1],
                  podate: cells[2] == undefined ? "" : cells[2],
                  supplierName: cells[3] == undefined ? "" : cells[3],
                  supplierBranchName: cells[4] == undefined ? "" : cells[4],
                  supplierEmail: cells[5] == undefined ? "" : cells[5],
                  supplierAddress: cells[6] == undefined ? "" : cells[6],
                  supplierContact: cells[7] == undefined ? "" : cells[7],
                  supplierCompanyName: cells[8] == undefined ? "" : cells[8],
                  partName: cells[9] == undefined ? "" : cells[9],
                  subPartName: cells[10] == undefined ? "" : cells[10],
                  equipmentUsed: cells[11] == undefined ? "" : cells[11],
                  partMakerName: cells[12] == undefined ? "" : cells[12],
                  quantity: cells[13] == undefined ? "" : cells[13],
                  unit: cells[14] == undefined ? "" : cells[14],
                  description: cells[15] == undefined ? "" : cells[15],
                  lineItemNumber: cells[16] == undefined ? "" : cells[16],

                  masterCategory: cells[17] == undefined ? "" : cells[17],
                  supplierCommencementDate:
                    cells[18] == undefined ? "" : cells[18],
                  hazmatPresent: cells[19] == undefined ? "" : cells[19],
                  mdsDoCReceived: cells[20] == undefined ? "" : cells[20],
                  mdsDoCFromCti: cells[21] == undefined ? "" : cells[21],
                  mdsDoCRequestedDate: cells[22] == undefined ? "" : cells[22],
                  lastReminderDate: cells[23] == undefined ? "" : cells[23],
                  reminderCount: cells[24] == undefined ? "" : cells[24],
                  mdsDoCReceivedDate: cells[25] == undefined ? "" : cells[25],
                  hazmatUpdatedIhmpart1:
                    cells[26] == undefined ? "" : cells[26],
                  labTestSample: cells[27] == undefined ? "" : cells[27],
                  tableAHazmatItems: cells[28] == undefined ? "" : cells[28],
                  tableBHazmatItems: cells[29] == undefined ? "" : cells[29],
                  lastCommunicationDate:
                    cells[30] == undefined ? "" : cells[30],
                  polineItemStatus: cells[31] == undefined ? 0 : cells[31],

                  createdBy: this.state.userId,
                  trackingToolYear:
                    dropdown_yearFilter != 0 || dropdown_yearFilter != undefined
                      ? dropdown_yearFilter
                      : "",
                  isManualRecord: true,
                  clientId: dropdown_clientFilter,
                };
              }
            }
          } else {
            return;
          }
        }
      });
      if (result[0] != undefined) {
        const newList = result;
        const finalList = existingTTList.concat(result);
        this.setState({ mappedTrackingTool: finalList });
        await this.SaveCopyPastedRowData(newList);
      }
    } else {
      this.setState({
        toastVisible: true,
        toastType: helperText.global.toastError,
        toastMessage: helperText.TrackingTool.selectYearError,
      });
    }
  };

  onCellClickedMenu = (event) => {
    const rowId = event.rowIndex;
    const colId = event.column.colId;
    this.handleColumnPaste(colId, rowId);
  };

  /* <summary>
  date: 03/07/2023
  Name: HV
  description: checking if entered po date falls within selected year
  <summary> */

  CheckIfPODateDateISValid = (podate) => {
    let validDate = true;

    podate = convertDate(podate);
    var currentDate = convertDate(new Date());

    if (podate <= currentDate) {
      const { dropdown_yearFilter, dropdownYearList } = this.state;
      var yearList = dropdownYearList.filter(
        (x) => x.value == dropdown_yearFilter
      );

      var currentDate = convertDate(new Date());
      let contractStartDate = new Date(yearList[0]?.contractStartDate);

      let minPoDate = convertPOMinMaxDate(
        yearList[0]?.contractStartDate,
        dropdown_yearFilter,
        0
      );

      var endYear = contractStartDate.getFullYear() + 1;
      let maxPoDate = convertPOMinMaxDate(
        yearList[0]?.contractStartDate,
        endYear,
        1
      );

      maxPoDate = maxPoDate <= currentDate ? maxPoDate : currentDate;

      if (!(podate >= minPoDate && podate <= maxPoDate)) {
        validDate = false;
      }
    } else {
      validDate = false;
    }

    return validDate;
  };

  handleColumnPaste = async (colId, rowId) => {
    var currentList = this.state.defaultTrackingTool;

    const { dropdown_clientFilter, dropdown_yearFilter } = this.state;
    if (dropdown_yearFilter != 0 || dropdown_yearFilter != undefined) {
      const text = await navigator.clipboard.readText();
      // Simplified parsing of the TSV data with hard-coded columns
      const data = text.split("\r\n").filter((t) => t != "" && t != null);

      data.forEach((item) => {
        const cells = item.replace("\n", "").split("\t");
        var record = currentList[rowId];

        let podate = colId == "podate" ? cells[0] : "";

        let saveData = true;

        if (podate != "") {
          saveData = this.CheckIfPODateDateISValid(podate);
        }

        if (saveData == false) {
          this.setState({
            toastVisible: true,
            toastType: helperText.global.toastError,
            toastMessage: helperText.TrackingTool.invalidPODateError,
          });
          return;
        } else {
          if (record.trackingToolId == 0) {
            var objNew = [
              {
                rowId: rowId,
                trackingToolId: record.trackingToolId,
                shipName: colId == "shipName" ? cells[0] : "",
                ponumber: colId == "ponumber" ? cells[0] : "",
                podate: colId == "podate" ? cells[0] : "",
                supplierName: colId == "supplierName" ? cells[0] : "",
                supplierEmail: colId == "supplierEmail" ? cells[0] : "",
                supplierAddress: colId == "supplierAddress" ? cells[0] : "",
                supplierContact: colId == "supplierContact" ? cells[0] : "",
                supplierCompanyName:
                  colId == "supplierCompanyName" ? cells[0] : "",
                supplierBranchName:
                  colId == "supplierBranchName" ? cells[0] : "",
                partName: colId == "partName" ? cells[0] : "",
                subPartName: colId == "subPartName" ? cells[0] : "",
                equipmentUsed: colId == "equipmentUsed" ? cells[0] : "",
                partMakerName: colId == "partMakerName" ? cells[0] : "",
                masterCategory: colId == "masterCategory" ? cells[0] : "",
                supplierCommencementDate:
                  colId == "supplierCommencementDate" ? cells[0] : "",
                hazmatPresent: colId == "hazmatPresent" ? cells[0] : "",
                quantity: colId == "quantity" ? cells[0] : "",
                unit: colId == "unit" ? cells[0] : "",
                description: colId == "description" ? cells[0] : "",
                lineItemNumber: colId == "lineItemNumber" ? cells[0] : "",
                mdsDoCReceived: colId == "mdsDoCReceived" ? cells[0] : "",
                mdsDoCFromCti: colId == "mdsDoCFromCti" ? cells[0] : "",
                mdsDoCRequestedDate:
                  colId == "mdsDoCRequestedDate" ? cells[0] : "",
                lastReminderDate: colId == "lastReminderDate" ? cells[0] : "",
                reminderCount: colId == "reminderCount" ? cells[0] : "",
                mdsDoCReceivedDate:
                  colId == "mdsDoCReceivedDate" ? cells[0] : "",
                hazmatUpdatedIhmpart1:
                  colId == "hazmatUpdatedIhmpart1" ? cells[0] : "",
                labTestSample: colId == "labTestSample" ? cells[0] : "",
                tableAHazmatItems: colId == "tableAHazmatItems" ? cells[0] : "",
                tableBHazmatItems: colId == "tableBHazmatItems" ? cells[0] : "",
                lastCommunicationDate:
                  colId == "lastCommunicationDate" ? cells[0] : "",
                polineItemStatus: colId == "polineItemStatus" ? cells[0] : 0,
                trackingToolYear:
                  dropdown_yearFilter != 0 || dropdown_yearFilter != undefined
                    ? dropdown_yearFilter
                    : "",
                clientId: dropdown_clientFilter,
              },
            ];
            const newList = objNew;
            currentList = currentList
              .concat(objNew)
              .sort((a, b) => a.rowId - b.rowId);
            this.gridApi.applyTransaction({ add: objNew });

            this.SaveCopyPastedRowData(newList);
          } else {
            var updateRecord = record;
            let colToUpdate = [colId][0];
            updateRecord[colToUpdate] = cells[0];
            currentList = currentList
              .concat(updateRecord)
              .sort((a, b) => a.rowId - b.rowId);
            this.gridApi.applyTransaction({ update: updateRecord });
            const newList = updateRecord;
            this.SaveCopyPastedRowData(newList);
          }
        }
        rowId++;
      });
      this.setState({ mappedTrackingTool: currentList }, () => { });
    } else {
      this.setState({
        toastVisible: true,
        toastType: helperText.global.toastError,
        toastMessage: helperText.TrackingTool.selectYearError,
      });
    }
  };

  SaveCopyPastedRowData = async (newList) => {
    var shipNameInDropdown =
      this.state.dropdown_shipFilter == 0 ||
        this.state.dropdown_shipFilter === ""
        ? null
        : this.state.dropdownList.shipList.filter(
          (e) => e.value === parseInt(this.state.dropdown_shipFilter, 10)
        )[0].label;

    if (
      shipNameInDropdown == null ||
      shipNameInDropdown == newList[0].shipName
    ) {
      this.setState({
        isLoading: true,
      });

      newList.map((obj) => ({
        ...obj,
        clientId: this.state.dropdown_clientFilter,
      }));

      if (!isStringEmpty(newList[0].supplierBranchName) && !isStringEmpty(newList[0].supplierEmail) && isStringEmpty(newList[0].supplierName)) {

        var responseCheckIfSuppExist = await TrackingToolService.CheckIfSupplierExist(newList[0].supplierBranchName, newList[0].supplierEmail);

        if (responseCheckIfSuppExist.data == undefined || responseCheckIfSuppExist.data == "" || !responseCheckIfSuppExist.data) {
          this.setState({
            showSupplierAddingModal: true,
            isLoading: false,
            savedLineItemList: newList,
          });
        } else {
          await this.SaveInTTData(newList);
        }
      }
      else {
        await this.SaveInTTData(newList);
      };
    }
    else {
      this.setState({
        toastVisible: true,
        toastType: helperText.global.toastError,
        toastMessage: helperText.TrackingTool.shipNotMatching,
        isLoading: false,
      });
    }
  }

  SaveInTTData = async (newList) => {
    var responseFilters = await TrackingToolService.SaveCopyPastedRowData(
      newList
    );
    if (responseFilters.data.errorMsg != "Success") {
      this.setState({
        toastVisible: true,
        toastType: helperText.global.toastError,
        toastMessage: responseFilters.data.errorMsg
          ? responseFilters.data.errorMsg
          : helperText.global.somethingWentWrong,
        isLoading: false,
      });
    } else if (responseFilters.status == 200) {
      addManualRow = 0;

      resetPageSize = "Reset";
      totalPOCount = 0;
      this.setState({
        isLoading: false,
        toastVisible: true,
        toastType: helperText.global.toastSuccess,
        toastMessage: helperText.TrackingTool.copyPasteRowData,
        hasMoreData: true,
        mappedTrackingTool: [],
      },
        async () => {
          await this.getAllTrackingTool();
        }
      );

      setTimeout(() => {
        this.filterGridView();
      }, 3000);
    } else {
      addManualRow = 0;
      this.setState({
        toastVisible: true,
        toastType: helperText.global.toastError,
        toastMessage: helperText.global.somethingWentWrong,
        isLoading: false,
      });
    }
  }


  /* <summary>
           date: 23/11/2022
           Name: DN
           description: Get all Tracking tool filters to bind in the dropdown
           <summary> */
  getAllTrackingToolFilters = async () => {
    try {
      this.setState({
        isLoading: true,
        userRolePermissionRead:
          this.state.accessList != null &&
          this.state.accessList.trackingToolAccess == UserAction.READ,
      });
      const {
        userTypeId,
        userId,
        clientUserId,
        isAdminOrInternalUser,
        isClientOrClientUser,
        isShipCrew,
      } = this.state;

      const response = await TrackingToolService.GetAllTrackingToolFilters(
        userTypeId,
        userId,
        clientUserId
      );
      if (response.data != null && response.data != undefined) {
        if (response.status == 401) {
          Logout(this.props.navigate);
        } else if (response.status == 200) {
          this.setState(
            {
              dropdownList: response.data,
              tableAList: response.data.tableA,
              tableBList: response.data.tableB,
              clientDataList: response.data.clientList,
              shipDataList: response.data.shipList,
              dropdownYearList: response.data.year,
              defaultYearList: response.data.year,
              defaultShipDataList: response.data.shipList,
              supplierDataList: response.data.supplierList,
              statusDataList: response.data.ttStatusList,
            },
            async () => {
              let clientShipDropdownData =
                GetLocalStorageDataWithoutParse("selectedShipTTool") === ""
                  ? true
                  : false;
              if (!clientShipDropdownData && isAdminOrInternalUser) {
                var dropdownList =
                  GetLocalStorageDataWithParse("selectedShipTTool");

                this.BindClientShipDropdownData(dropdownList);
              } else {
                if (isClientOrClientUser) {
                  this.ShowLoggedInClientData();
                } else if (isShipCrew) {
                  this.ShowLoggedInShipData();
                } else this.BindClientShipDropdownData(null);
              }
            }
          );
        }
      } else {
        this.setState({
          tableAList: [],
          tableBList: [],
          clientDataList: [],
          shipDataList: [],
          dropdownYearList: [],
          defaultShipDataList: [],
          supplierDataList: [],
          statusDataList: [],
          toastVisible: true,
          toastType: helperText.global.toastError,
          toastMessage: helperText.global.somethingWentWrong,
          isLoading: false,
        });
      }
    } catch (error) { }
  };

  /* <summary>
       date: 23/11/2022
       Name: DN
       description: Get all suppliers to bind in the Ag grid, fill in the dropdown used for filtering
       <summary> */
  //HV - 17/02/2023 - Fix for ship dropdown not getting filtered based on selected client and even po number
  getAllTrackingTool = async () => {
    try {
      if (this.state.isLoad || !this.state.hasMoreData) return;

      this.setState({ isLoading: true });

      const {
        dropdown_clientFilter,
        defaultShipDataList,
        dropdown_shipFilter,
        userId,
        clientUserId,
        userTypeId,
        pageSize,
        pageNumber,
        mappedTrackingTool,
        dropdownYearList,
        dropdown_yearFilter
      } = this.state;
      const page = pageNumber;
      //const page = resetPageSize && resetPageSize === 'Reset' ? 1 : pageNumber;
      const response = await TrackingToolService.GetAllTrackingToolDataByClient(
        parseInt(dropdown_clientFilter, 10),
        parseInt(dropdown_shipFilter, 10),
        parseInt(dropdown_yearFilter, 10),
        userId,
        clientUserId,
        userTypeId,
        pageSize,
        page
      );

      if (response.status === 401) {
        Logout(this.props.navigate);
      } else if (response.status === 200 && response.data) {
        const newData = response.data;
        totalPOCount = response?.data[0]?.totalPOCount ?? 0;
        this.setState({ count: totalPOCount }),
          localStorage.setItem("Count", totalPOCount);
        // Apply filtering to the new data if dropdown filters are active
        let filteredResponseData = newData;
        if (parseInt(dropdown_clientFilter, 10) > 0) {
          filteredResponseData = parseInt(dropdown_shipFilter, 10) > 0
            ? newData.filter(
              (e) =>
                e.clientId === parseInt(dropdown_clientFilter, 10) &&
                e.shipId === parseInt(dropdown_shipFilter, 10)
            )
            : newData.filter(
              (e) => e.clientId === parseInt(dropdown_clientFilter, 10)
            );
        }

        // Check if more data is available by comparing with pageSize
        const hasMoreData = newData.length === pageSize;

        this.setState((prevState) => ({
          mappedTrackingTool: [...prevState.mappedTrackingTool, ...filteredResponseData],
          pageNumber: prevState.pageNumber + 1,
          hasMoreData: hasMoreData,
          isLoading: false,
        }));

        // Update any dependent dropdowns or lists only once
        if (parseInt(dropdown_clientFilter, 10) > 0) {
          const filteredOnlyClientData = newData.filter(
            (e) => e.clientId === parseInt(dropdown_clientFilter, 10)
          );

          const filteredShipDropdown = defaultShipDataList.filter(
            (e) => e.parentValue === parseInt(dropdown_clientFilter, 10)
          );

          // Get unique PO numbers from filtered data
          const uniquePONumberList = Array.from(
            new Set(
              filteredResponseData.map((l) => l.ponumber).filter((x) => x !== undefined)
            )
          );

          // Set only additional state needed for dropdowns and lists
          this.setState({
            defaultTrackingTool: filteredOnlyClientData,
            shipDataList: filteredShipDropdown,
            poNumberList: uniquePONumberList,
          });
        }
      } else {
        // Handle error or empty data response
        this.setState({
          mappedTrackingTool: [],
          defaultTrackingTool: [],
          toastVisible: true,
          shipDataList: [],
          toastType: helperText.global.toastError,
          toastMessage: helperText.global.somethingWentWrong,
          isLoading: false,
        });
      }
    } catch (error) {
      this.setState({ isLoading: false });
      console.error("Error fetching tracking tool data:", error);
    }
  };

  onScroll = () => {
    if (!this.state.isLoading && this.state.hasMoreData) {
      this.getAllTrackingTool();
    }
  };
  resetPagesInChild = (resetFunction) => {
    if (resetFunction) {
      resetFunction(); // Call the reset function from the child
    }
  };
  onZoomClicked = () => {
    addManualRow = 0;

    this.setState({
      selectedTrackingToolIdList: [],
      selectedTrackingToolDataList: [],
      selectedToSendReminderEmail: [],
      showZoom: !this.state.showZoom,
    });
  };

  onGridReady = (params) => {
    addManualRow = 0;
    this.setState({
      gridApi: params.api,
    });

    this.gridApi = params.api;
    this.gridColumnApi = params.columnApi;
  };
  componentDidUpdate(prevProps, prevState) {
    if (prevState.pageNumber !== this.state.pageNumber) {
      this.setState({
        resetLoadedPages: Date.now()
      })
    }
  }
  onAddRowClicked = () => {
    const { dropdown_yearFilter, dropdownYearList } = this.state;

    if (dropdown_yearFilter != 0 && dropdown_yearFilter != null) {
      const lastSerialNumber = this.state.mappedTrackingTool
        .sort((a, b) => a.serialNumber - b.serialNumber)
        .reverse()
        .shift(0);

      var yearList = dropdownYearList.filter(
        (x) => x.value == dropdown_yearFilter
      );

      var currentDate = convertDate(new Date());
      let contractStartDate = new Date(yearList[0]?.contractStartDate);

      let minPoDate = convertPOMinMaxDate(
        yearList[0]?.contractStartDate,
        dropdown_yearFilter,
        0
      );

      var endYear = contractStartDate.getFullYear() + 1;
      let maxPoDate = convertPOMinMaxDate(
        yearList[0]?.contractStartDate,
        endYear,
        1
      );

      maxPoDate = new Date(maxPoDate) <= new Date() ? maxPoDate : currentDate;
      if (addManualRow == 0) {
        addManualRow++;
        var shipNameInDropdown =
          this.state.dropdown_shipFilter == 0 ||
            this.state.dropdown_shipFilter === ""
            ? null
            : this.state.dropdownList.shipList.filter(
              (e) => e.value === parseInt(this.state.dropdown_shipFilter, 10)
            )[0].label;
        this.gridApi.updateRowData({
          add: [
            {
              serialNumber:
                lastSerialNumber === undefined
                  ? 1
                  : lastSerialNumber.serialNumber + 1,
              shipName: shipNameInDropdown,
              ponumber: null,
              podate: null,
              supplierName: null,
              supplierEmail: null,
              supplierAddress: null,
              supplierContact: null,
              supplierCompanyName: null,
              supplierBranchName: null,
              partName: null,
              subPartName: null,
              equipmentUsed: null,
              partMakerName: null,
              masterCategory: null,
              supplierCommencementDate: null,
              hazmatPresent: null,
              quantity: null,
              unit: null,
              description: null,
              lineItemNumber: null,
              mdsDoCReceived: null,
              mdsDoCFromCti: null,
              mdsDoCRequestedDate: null,
              lastReminderDate: null,
              reminderCount: null,
              mdsDoCReceivedDate: null,
              hazmatUpdatedIhmpart1: null,
              labTestSample: null,
              tableAHazmatItems: null,
              tableBHazmatItems: null,
              lastCommunicationDate: null,
              polineItemStatus: 0,
              hideIcons: true,
              isManualRecord: true,
              listTableAHazmat: [],
              listTableBHazmat: [],
              trackingToolId: 0,
              trackingToolYear:
                dropdown_yearFilter != 0 || dropdown_yearFilter != undefined
                  ? dropdown_yearFilter
                  : "",
              minPoDate: new Date(minPoDate),
              maxPoDate: new Date(maxPoDate),
            },
          ],
          addIndex: 0,
        });

        this.setState({
          toastVisible: true,
          toastType: helperText.global.toastSuccess,
          toastMessage: helperText.global.trackingToolAddRowInstruction,
        });
      }
    } else {
      this.setState({
        toastVisible: true,
        toastType: helperText.global.toastError,
        toastMessage: helperText.TrackingTool.selectYearError,
      });
    }
  };

  /* <summary>
        date: 23/11/2022
        Name: DN
        description: Filter data from grid using search bar
        <summary> */
  onSearch = (e) => {
    var inputValue = e.target.value;
    this.setState(
      {
        searchedText: inputValue,
      },
      async () => {
        await this.getSearchedTrackingTool(inputValue);
        setTimeout(() => {
          this.filterGridView();
        }, 3000);
      }
    );
  };

  handleInputChange = (event) => {
    if (event.target.value == "")
      this.onSearch(event);
    else
      this.setState({ searchedText: event.target.value }); // Update state on input change
  };

  handleKeyDown = (event) => {
    if (event.key === 'Enter') {
      event.preventDefault(); // Prevent default form submission if inside a form
      this.onSearch(event); // Trigger search on Enter key press
    }
  };


  getSearchedTrackingTool = async (inputValue) => {
    try {
      this.setState({
        isLoading: true,
      });
      const {
        dropdown_clientFilter,
        defaultShipDataList,
        dropdown_shipFilter,
        userId,
        clientUserId,
        userTypeId,
      } = this.state;

      const response = await TrackingToolService.GetSearchedTrackingTool(
        parseInt(dropdown_clientFilter, 10),
        inputValue,
        userId,
        clientUserId,
        userTypeId
      );
      if (response.data != null && response.data != undefined) {
        if (response.status == 401) {
          Logout(this.props.navigate);
        } else if (response.status == 200) {
          if (parseInt(dropdown_clientFilter, 10) > 0) {
            let filteredResponseData =
              parseInt(dropdown_shipFilter, 10) > 0
                ? response.data.filter(
                  (e) =>
                    e.clientId === parseInt(dropdown_clientFilter, 10) &&
                    e.shipId == parseInt(dropdown_shipFilter, 10)
                )
                : response.data.filter(
                  (e) => e.clientId === parseInt(dropdown_clientFilter, 10)
                );
            let filteredOnlyClientData = response.data.filter(
              (e) => e.clientId === parseInt(dropdown_clientFilter, 10)
            );
            let filteredShipDropdown = defaultShipDataList.filter(
              (e) => e.parentValue === parseInt(dropdown_clientFilter, 10)
            );
            this.filterGridView();

            this.filterYearDropdownBasisClient();
            let uniquePONumberList = Array.from([
              ...new Set(
                filteredResponseData
                  .map((l) => l.ponumber)
                  .filter((x) => x != undefined)
              ),
            ]);

            this.setState({
              mappedTrackingTool: filteredResponseData,
              defaultTrackingTool: filteredOnlyClientData,
              shipDataList: filteredShipDropdown,
              poNumberList: uniquePONumberList,
              isLoading: false,
            });
          } else {
            this.setState({
              mappedTrackingTool: [],
              defaultTrackingTool: response.data,
              poNumberList: [],
              isLoading: false,
              shipDataList: [],
            });
          }
        }
      } else {
        this.setState({
          mappedTrackingTool: [],
          defaultTrackingTool: [],
          toastVisible: true,
          shipDataList: [],
          toastType: helperText.global.toastError,
          toastMessage: helperText.global.somethingWentWrong,
          isLoading: false,
        });
      }

    } catch (error) {
    }
  };

  /* <summary>
          date: 24/11/2022
          Name: DN
          description: On date selected/changed from the date picker
          <summary> */
  //HV - 24/03/2023 - handled from date should be greater than to date
  onDateChanged = (id, e) => {
    if (id == "fromDate") {
      var nextFromDate = new Date(e);
      nextFromDate.setDate(e.getDate());
      nextFromDate.toLocaleDateString();
      this.setState({ filter_fromDate: e, nextFromDate: nextFromDate }, () => {
        this.filterGridView();
      });
    } else if (id == "toDate") {
      var previousToDate = new Date(e);
      previousToDate.setDate(e.getDate() - 1);
      previousToDate.toLocaleDateString();
      this.setState(
        { filter_toDate: e, previousToDate: previousToDate },
        () => {
          this.filterGridView();
        }
      );
    }
  };

  onSupplierCommDateChanged = async (e, ttData) => {
    ttData.supplierCommencementDate = convertDate(new Date(e));
    let listToSave = [];
    listToSave.push(ttData);

    await this.SaveCopyPastedRowData(listToSave);
  };

  //HV - 17/04/2023 - fix added for year dropdown not getting binded
  filterYearDropdownBasisClient = () => {
    var { dropdown_clientFilter, dropdown_yearFilter, defaultYearList } =
      this.state;
    var dropdownYearList = defaultYearList;

    var dropdown_client = parseInt(dropdown_clientFilter, 10);
    if (dropdownYearList.length > 0) {
      dropdownYearList = dropdownYearList.filter(
        (e) => e.parentValue == dropdown_client
      );
      this.setState({
        dropdownYearList: dropdownYearList,
        dropdown_yearFilter,
        isLoading: false,
      });
    }
  };

  /* <summary>
        date: 24/11/2022
        Name: DN
        description: On filter dropdown selected
        <summary> */
  onFilterSelected = (e) => {
    var { name, value } = e.target;
    addManualRow = 0;
    this.state.gridApi.paginationGoToPage(0);
    // AddLocalStorageData("changeClientName","changeClientName")
    //if(name==="dropdown_clientFilter"){
    AddLocalStorageData("changeClientName", `changeClientName-${value}`);
    localStorage.removeItem('loadedPages');
    // }
    this.setState(
      {
        [name]: value,
      },
      () => {
        const { dropdown_yearFilter, dropdown_clientFilter, defaultTrackingTool, dropdown_shipFilter, defaultShipDataList, dropdownYearList, displayText, defaultYearList } = this.state;

        var showPeriodText = displayText;
        if (name == "dropdown_clientFilter") {
          if (parseInt(dropdown_clientFilter, 10) > 0) {
            let dropdownData = {
              clientId: parseInt(dropdown_clientFilter, 10),
              shipId: 0,
            };

            AddLocalStorageData(
              "selectedShipTTool",
              JSON.stringify(dropdownData)
            );

            this.filterYearDropdownBasisClient();

            let filteredResponseData = defaultTrackingTool.filter(
              (e) =>
                e.clientId === parseInt(dropdown_clientFilter, 10)
            );

            let uniquePONumberList = Array.from([
              ...new Set(
                filteredResponseData
                  .map((l) => l.ponumber)
                  .filter((x) => x != undefined)
              ),
            ]);

            resetPageSize = "Reset";
            totalPOCount = 0;

            let dropdown_shipFilterDefault = "";
            let dropdown_yearFilterDefault = "";
            let dropdownInitialList = this.state.dropdownList;

            dropdown_shipFilterDefault = dropdownInitialList.shipList.filter((e) => e.parentValue === parseInt(dropdown_clientFilter, 10))[0] !== undefined ? dropdownInitialList.shipList.filter((e) => e.parentValue === parseInt(dropdown_clientFilter, 10))[0].value : "";

            dropdown_yearFilterDefault = defaultYearList.filter((x) => x.parentValue == parseInt(dropdown_clientFilter, 10))[0] !== undefined ? defaultYearList.filter((x) => x.parentValue == parseInt(dropdown_clientFilter, 10))[0].value : 0;

            resetPageSize = "Reset";
            totalPOCount = 0;
            this.setState(
              {
                isLoading: true,
                pageNumber: 1,
                mappedTrackingTool: [],
                shipDataList: defaultShipDataList.filter(
                  (x) =>
                    x.parentValue ==
                    parseInt(dropdown_clientFilter, 10)
                ),
                poNumberList: uniquePONumberList,
                dropdown_shipFilter: dropdown_shipFilterDefault,
                dropdown_yearFilter: dropdown_yearFilterDefault,
                displayText: "",
                searchedText: "",
                hasMoreData: true,
                pageNumber: 1,
              },
              () => {
                this.getAllTrackingTool();
              }

            );
          }
        }
        else if (name == "dropdown_shipFilter") {
          resetPageSize = "Reset";
          totalPOCount = 0;
          this.setState({
            displayText: "",
            searchedText: "",
            hasMoreData: true,
            pageNumber: 1,
            mappedTrackingTool: [],
            resetLoadedPages: Date.now()
          },
            () => {
              this.getAllTrackingTool();
            }
          );
        }
        else if (name == "dropdown_yearFilter") {
          resetPageSize = "Reset";
          totalPOCount = 0;
          if (dropdown_yearFilter != "" && dropdown_yearFilter != "0") {
            let selectDropdownYear = dropdownYearList.filter(
              (x) => x.value == parseInt(dropdown_yearFilter, 10)
            );

            showPeriodText =
              selectDropdownYear != null
                ? selectDropdownYear?.[0]?.year +
                " / IHMM Maintenance Period " +
                selectDropdownYear?.[0].period
                : displayText;
          } else if (dropdown_yearFilter == "" || dropdown_yearFilter == "0") {
            showPeriodText = " IHMM Maintenance";
          }
          this.setState({
            displayText: "",
            hasMoreData: true,
            searchedText: "",
            mappedTrackingTool: [],
            resetLoadedPages: Date.now()
          },
            () => {
              this.getAllTrackingTool();
            }
          );
        }
        else {
          this.setState(
            {
              isLoading: true,
              displayText: "",
              searchedText: "",
              hasMoreData: true,
              resetLoadedPages: Date.now()
            },
            () => {
              this.filterGridView();
            }
          );
        }
        // this.setState({ resetLoadedPages: Date.now() }); // Trigger a change to reset
      }
    );
  };

  clearAllFilter = () => {
    const { userId, dropdownList, isClient, isShipCrew, dropdown_shipFilter, dropdown_yearFilter, dropdownYearList, defaultYearList } = this.state;
    document.getElementById("searchQueryInput").value = "";

    let dropdown_shipFilterDefault;
    let dropdown_yearFilterDefault;
    let dropdownInitialList = dropdownList;
    dropdown_shipFilterDefault = dropdownInitialList.shipList.filter((e) => e.parentValue === dropdownInitialList.clientList[0].value)[0].value;
    dropdown_yearFilterDefault = defaultYearList.filter((x) => x.parentValue == dropdownInitialList.clientList[0].value)[0].value;

    resetPageSize = "Reset";
    totalPOCount = 0;
    this.setState(
      {
        dropdown_clientFilter:
          dropdownList.clientList.length > 0
            ? isClient
              ? userId
              : isShipCrew && dropdownList.shipList.length > 0
                ? dropdownList.shipList.filter((s) => s.value == userId)[0]
                  .parentValue
                : dropdownList.clientList[0].value
            : "",
        dropdown_shipFilter:
          dropdownList.shipList.length > 0 ? (isShipCrew ? userId : dropdown_shipFilterDefault) : "",
        dropdown_supplierFilter: "",
        dropdown_poNoFilter: "",
        dropdown_hazmatFilter: "",
        dropdown_yearFilter: dropdownYearList.length > 0 ? dropdown_yearFilterDefault : 0,
        dropdown_remarkFilter: "",
        dropdown_tableAFilter: "",
        dropdown_tableBFilter: "",
        filter_fromDate: "",
        filter_toDate: "",
        tableAItem: [],
        tableBItem: [],
        nextFromDate: "",
        previousToDate: currentDate,
        displayText: " IHMM Maintenance",
        searchedText: "",
        hasMoreData: true,
        mappedTrackingTool: [],
      },
      async () => {
        await this.getAllTrackingTool();
        setTimeout(() => {
          this.filterGridView();
        }, 3000);
      }
    );
  };

  /* <summary>
        date: 24/11/2022
        Name: DN
        description: Filter grid data using the date and dropdowns available in the ui
        <summary> */
  filterGridView = () => {
    const {
      dropdown_shipFilter,
      dropdown_supplierFilter,
      dropdown_poNoFilter,
      dropdown_hazmatFilter,
      dropdown_yearFilter,
      dropdown_remarkFilter,
      dropdown_tableAFilter,
      dropdown_tableBFilter,
      filter_fromDate,
      filter_toDate,
      dropdown_clientFilter,
      displayText,
      dropdownYearList,
      searchedText,
    } = this.state;
    this.state.gridApi.paginationGoToPage(0);
    var filterList = [];
    var trackingToolList = this.state.defaultTrackingTool;
    var showPeriodText = displayText;

    if (
      dropdown_shipFilter == "" &&
      dropdown_supplierFilter == "" &&
      dropdown_poNoFilter == "" &&
      dropdown_hazmatFilter == "" &&
      dropdown_yearFilter == "" &&
      dropdown_remarkFilter == "" &&
      dropdown_tableAFilter == "" &&
      dropdown_tableBFilter == "" &&
      filter_fromDate == "" &&
      filter_toDate == ""
    ) {
      filterList = trackingToolList.filter(
        (e) => e.clientId == parseInt(dropdown_clientFilter, 10)
      );
    } else {
      var filterApplied = false;
      if (
        dropdown_shipFilter == "0" ||
        dropdown_supplierFilter == "0" ||
        dropdown_poNoFilter == "0" ||
        dropdown_hazmatFilter == "0" ||
        dropdown_yearFilter == "0" ||
        dropdown_remarkFilter == "0" ||
        dropdown_tableAFilter == "0" ||
        dropdown_tableBFilter == "0" ||
        filter_fromDate == "0" ||
        filter_toDate == "0"
      ) {
        filterList = trackingToolList.filter(
          (e) => e.clientId == parseInt(dropdown_clientFilter, 10)
        );
        filterApplied = true;
      }

      if (parseInt(dropdown_shipFilter, 10) > 0) {
        // filterList = filterApplied
        //   ? filterList.filter(
        //     (e) => e.shipId === parseInt(dropdown_shipFilter, 10)
        //   )
        //   : trackingToolList.filter(
        //     (e) => e.shipId === parseInt(dropdown_shipFilter, 10)
        //   );
        //filterApplied = true;
      }

      if (parseInt(dropdown_supplierFilter, 10) > 0) {
        filterList = filterApplied
          ? filterList.filter(
            (e) => e.supplierId === parseInt(dropdown_supplierFilter, 10)
          )
          : trackingToolList.filter(
            (e) => e.supplierId === parseInt(dropdown_supplierFilter, 10)
          );
        filterApplied = true;
      }

      if (dropdown_poNoFilter != "" && dropdown_poNoFilter != "0") {
        filterList = filterApplied
          ? filterList.filter((e) => e.ponumber == dropdown_poNoFilter)
          : trackingToolList.filter((e) => e.ponumber == dropdown_poNoFilter);
        filterApplied = true;
      }

      if (dropdown_hazmatFilter != "" && dropdown_hazmatFilter != "0") {
        filterList = filterApplied
          ? filterList.filter(
            (e) =>
              e.hazmatPresent != null &&
              e.hazmatPresent == JSON.parse(dropdown_hazmatFilter)
          )
          : trackingToolList.filter(
            (e) =>
              e.hazmatPresent != null &&
              e.hazmatPresent == JSON.parse(dropdown_hazmatFilter)
          );
        filterApplied = true;
      }

      if (dropdown_yearFilter != "" && dropdown_yearFilter != "0") {
        // filterList = filterApplied
        //   ? filterList.filter(
        //     (e) => e.trackingToolYear === parseInt(dropdown_yearFilter, 10)
        //   )
        //   : trackingToolList.filter(
        //     (e) => e.trackingToolYear === parseInt(dropdown_yearFilter, 10)
        //   );

        let selectDropdownYear = dropdownYearList.filter(
          (x) => x.value == parseInt(dropdown_yearFilter, 10)
        );

        showPeriodText =
          selectDropdownYear != null
            ? selectDropdownYear?.[0]?.year +
            " / IHMM Maintenance Period " +
            selectDropdownYear?.[0].period
            : displayText;
        //filterApplied = true;
      } else if (dropdown_yearFilter == "" || dropdown_yearFilter == "0") {
        showPeriodText = " IHMM Maintenance";
      }

      if (dropdown_remarkFilter != "" && dropdown_remarkFilter != "0") {
        filterList = filterApplied
          ? filterList.filter(
            (e) =>
              e.polineItemStatus != null &&
              e.polineItemStatus.includes(dropdown_remarkFilter)
          )
          : trackingToolList.filter(
            (e) =>
              e.polineItemStatus != null &&
              e.polineItemStatus.includes(dropdown_remarkFilter)
          );
        filterApplied = true;
      }

      if (dropdown_tableAFilter != "") {
        dropdown_tableAFilter.forEach((element) => {
          if (filterApplied) {
            filterList = filterList.filter((e) =>
              e.tableAHazmatItems.includes(element.label)
            );
          } else {
            filterList = trackingToolList.filter((e) =>
              e.tableAHazmatItems.includes(element.label)
            );
          }
        });
        filterApplied = true;
      }

      if (dropdown_tableBFilter != "") {
        dropdown_tableBFilter.forEach((element) => {
          if (filterApplied) {
            filterList = filterList.filter((e) =>
              e.tableBHazmatItems.includes(element.label)
            );
          } else {
            filterList = trackingToolList.filter((e) =>
              e.tableBHazmatItems.includes(element.label)
            );
          }
        });
        filterApplied = true;
      }

      if (filter_fromDate != "" && filter_toDate != "") {
        let fromDate = convertDate(filter_fromDate);
        let toDate = convertDate(filter_toDate);
        filterList = filterApplied
          ? filterList.filter((e) => {
            let date = convertDate(new Date(e.podate));
            return (
              new Date(date).getTime() >= new Date(fromDate).getTime() &&
              new Date(date).getTime() <= new Date(toDate).getTime()
            );
          })
          : trackingToolList.filter((e) => {
            let date = convertDate(new Date(e.podate));
            return (
              new Date(date).getTime() >= new Date(fromDate).getTime() &&
              new Date(date).getTime() <= new Date(toDate).getTime()
            );
          });
        filterApplied = true;
      }
    }

    this.setState({
      mappedTrackingTool: filterList,
      displayText: showPeriodText,
      isLoading: false,
    });
  };

  onTableADropdownChange = (option) => {
    var tableAItemSelected = [];
    var selectedTableA = this.state.tableAList;
    var filter_rowData = [];
    if (option.length > 0) {
      option
        .filter((t) => t.value != "0")
        .forEach((item) => {
          tableAItemSelected.push(item);
        });
      if (option.some((t) => t.value == "0")) {
        filter_rowData = selectedTableA;
      } else {
        option
          .filter((t) => t.value != "0")
          .forEach((item) => {
            var rowDataList = selectedTableA.filter(
              (t) => t.value == item.value
            );
            rowDataList.forEach((item) => {
              filter_rowData.push(item);
            });
          });
      }
    }
    this.setState(
      { tableAItem: tableAItemSelected, dropdown_tableAFilter: filter_rowData },
      () => {
        this.filterGridView();
      }
    );
  };

  onTableBDropdownChange = (option) => {
    var tableBItemSelected = [];
    var selectedTableB = this.state.tableBList;
    var filter_rowData = [];
    if (option.length > 0) {
      option
        .filter((t) => t.value != "0")
        .forEach((item) => {
          tableBItemSelected.push(item);
        });
      if (option.some((t) => t.value == "0")) {
        filter_rowData = selectedTableB;
      } else {
        option
          .filter((t) => t.value != "0")
          .forEach((item) => {
            var rowDataList = selectedTableB.filter(
              (t) => t.value == item.value
            );
            rowDataList.forEach((item) => {
              filter_rowData.push(item);
            });
          });
      }
    }
    this.setState(
      { tableBItem: tableBItemSelected, dropdown_tableBFilter: filter_rowData },
      () => {
        this.filterGridView();
      }
    );
  };

  onTableAGridDropdownChange = (option, rowData) => {
    var tableAItemSelected = [];
    var selectedTableA = this.state.dropdown_tableAFilter;
    if (option.length > 0) {
      option
        .filter((t) => t.value != "")
        .forEach((item) => {
          tableAItemSelected.push(item);
          selectedTableA =
            selectedTableA === ""
              ? item.label
              : selectedTableA + ", " + item.label;
        });
    }
    tableAGridItem = tableAItemSelected;

    rowData.listTableAHazmat = [];
    option.forEach((element) => {
      if (!rowData.listTableAHazmat.includes(element.label))
        rowData.listTableAHazmat.push(element.label);
    });
  };

  onTableBGridDropdownChange = async (option, rowData) => {
    var tableBItemSelected = [];
    var selectedTableB = this.state.dropdown_tableBFilter;
    if (option.length > 0) {
      option
        .filter((t) => t.value != "")
        .forEach((item) => {
          tableBItemSelected.push(item);
          selectedTableB =
            selectedTableB === ""
              ? item.label
              : selectedTableB + ", " + item.label;
        });
    }
    tableBGridItem = tableBItemSelected;
    rowData.listTableBHazmat = [];
    option.forEach((element) => {
      if (!rowData.listTableBHazmat.includes(element.label))
        rowData.listTableBHazmat.push(element.label);
    });
  };

  downloadPOFile = async () => {
    try {
      if (this.state.selectedTrackingToolDataList.length > 0) {
        this.setState({
          isLoading: true,
        });

        let ttStatusData = [];
        this.state.selectedTrackingToolDataList.forEach((element) => {
          ttStatusData.push(element);
        });
        const response = await TrackingToolService.DownloadPOFile(ttStatusData);

        if (response.status == 200) {
          if (response.data.length == 0) {
            this.setState({
              toastVisible: true,
              toastType: helperText.global.toastError,
              toastMessage: helperText.TrackingTool.noFileInSelectedLineItem,
            });
          } else {
            response.data.forEach((element) => {
              var fname = "";
              var lastItem = element.split("/").pop();
              fname = lastItem;
              const link = document.createElement("a");
              link.setAttribute("href", element);
              link.setAttribute("download", element);
              var ext = element.substring(element.lastIndexOf(".") + 1);
              if (ext == "pdf" || ext == "PDF") {
                link.setAttribute("target", "_blank");
              } else {
                link.setAttribute("target", "");
              }
              document.body.appendChild(link);
              link.download = fname;
              link.click();
            });

            this.setState({
              toastVisible: true,
              toastType: helperText.global.toastSuccess,
              toastMessage: helperText.global.downloadSuccess,
            });
          }
        } else {
          this.setState({
            toastVisible: true,
            toastType: helperText.global.toastError,
            toastMessage: helperText.global.somethingWentWrong,
          });
        }

        this.setState({
          isLoading: false,
        });
      } else {
        this.setState({
          toastVisible: true,
          toastMessage:
            helperText.TrackingTool.selectLineItemToDownloadDocument,
          toastType: helperText.global.toastError,
        });
      }
    } catch (error) { }
  };

  downloadTrackingToolTemplate = async () => {
    try {
      this.setState({
        isLoading: true,
      });

      const response = await TrackingToolService.DownloadTrackingToolTemplate();
      if (response.status == 200) {
        response.data.forEach((element) => {
          var fname = "";
          var lastItem = element.split("/").pop();
          fname = lastItem;
          const link = document.createElement("a");
          link.href = element;
          link.setAttribute("download", element);
          document.body.appendChild(link);
          link.download = fname;
          link.click();
        });

        this.setState({
          toastVisible: true,
          toastType: helperText.global.toastSuccess,
          toastMessage: helperText.global.downloadSuccess,
        });
      } else {
        this.setState({
          toastVisible: true,
          toastType: helperText.global.toastError,
          toastMessage: helperText.global.somethingWentWrong,
        });
      }

      this.setState({
        isLoading: false,
      });
    } catch (error) { }
  };

  onSelectionChanged = () => {
    var selectedRows = this.gridApi.getSelectedRows();

    if (selectedRows != undefined && selectedRows.length > 0) {
      selectedRows = selectedRows.filter(
        (x) => x.trackingToolId != null && x.trackingToolId != 0
      );
    } else {
      this.setState({
        selectedTrackingToolIdList: [],
        selectedTrackingToolDataList: [],
        selectedToSendReminderEmail: [],
      });
      return;
    }

    if (selectedRows != undefined && selectedRows.length > 0) {
      let listSelectedTTId = [];
      let listSelectedTTData = [];

      selectedRows.forEach((element) => {
        listSelectedTTId.push(element.trackingToolId);
        listSelectedTTData.push(element);
      });

      let selectedDataTT = Array.from(
        new Set(listSelectedTTData.map((l) => l.ponumber))
      ).map((id) => {
        return {
          ponumber: listSelectedTTData.find((p) => p.ponumber === id).ponumber,
          clientId: listSelectedTTData.find((p) => p.ponumber === id).clientId,
          shipId: listSelectedTTData.find((p) => p.ponumber === id).shipId,
          supplierId: listSelectedTTData.find((p) => p.ponumber === id)
            .supplierId,
          supplierName: listSelectedTTData.find((p) => p.ponumber === id)
            .supplierName,
          supplierDivision: listSelectedTTData.find((p) => p.ponumber === id)
            .supplierDivision,
          partMakerName: listSelectedTTData.find((p) => p.ponumber === id)
            .partMakerName,
          description: listSelectedTTData.find((p) => p.ponumber === id)
            .description,
          trackingToolId: listSelectedTTData.find((p) => p.ponumber === id)
            .trackingToolId,
          poDate: listSelectedTTData.find((p) => p.ponumber === id)?.podate,
        };
      });

      let selectedDataTTSendReminder = Array.from(
        new Set(listSelectedTTData.map((l) => l.supplierId))
      ).map((id) => {
        return {
          ponumber: listSelectedTTData.find((p) => p.supplierId === id)
            .ponumber,
          clientId: listSelectedTTData.find((p) => p.supplierId === id)
            .clientId,
          shipId: listSelectedTTData.find((p) => p.supplierId === id).shipId,
          supplierId: listSelectedTTData.find((p) => p.supplierId === id)
            .supplierId,
          supplierName: listSelectedTTData.find((p) => p.supplierId === id)
            .supplierName,
          supplierDivision: listSelectedTTData.find((p) => p.supplierId === id)
            .supplierDivision,
          partMakerName: listSelectedTTData.find((p) => p.supplierId === id)
            .partMakerName,
          description: listSelectedTTData.find((p) => p.supplierId === id)
            .description,
          trackingToolId: listSelectedTTData.find((p) => p.supplierId === id)
            .trackingToolId,
          poDate: listSelectedTTData.find((p) => p.ponumber === id)?.podate,
        };
      });

      this.setState({
        selectedTrackingToolIdList: listSelectedTTId,
        selectedTrackingToolDataList: selectedDataTT,
        selectedToSendReminderEmail: listSelectedTTData,
      });
    } else {
      this.setState({
        selectedTrackingToolIdList: [],
        selectedTrackingToolDataList: [],
        selectedToSendReminderEmail: [],
      });
    }
  };

  /* <summary>
   date: 21/03/2023
   Name: HV
   description: showing confirmation modal 
   <summary> */

  showHideConfirmationModal = (showModal, ttData, modalText) => {
    this.setState({
      showConfirmationModal: showModal,
      selectedTTData: ttData,
      modalText: modalText,
    });
  };

  sendMDSDoCRequest = async () => {
    try {
      const { dropdown_shipFilter, dropdown_clientFilter, userTypeId, userId } =
        this.state;

      this.setState({
        isLoading: true,
        showConfirmationModal: false,
      });

      var supplierListCommaSeperated = "";
      var poNoListCommaSeperated = "";
      let ttDataList = [];
      let ttSelectedList = [];
      let uniqueSupplierList = [];
      let uniquePOList = [];

      let ttData = this.state.selectedTTData;

      if (ttData != null) {
        ttDataList.push(ttData.trackingToolId);
        ttSelectedList.push(ttData);

        uniqueSupplierList = Array.from([
          ...new Set(ttSelectedList.map((l) => l.supplierName)),
        ]);
        uniqueSupplierList.forEach((element) => {
          supplierListCommaSeperated = supplierListCommaSeperated.concat(
            element + ", "
          );
        });

        uniquePOList = Array.from([
          ...new Set(ttSelectedList.map((l) => l.ponumber)),
        ]);
        uniquePOList.forEach((element) => {
          poNoListCommaSeperated = poNoListCommaSeperated.concat(
            element + ", "
          );
        });
      } else ttDataList = this.state.selectedTrackingToolIdList;

      let trackingToolData = {
        TrackingToolIdList: ttDataList,
        userId: userId,
        userTypeId: userTypeId,
      };

      const response = await TrackingToolService.SendMDSDoCRequest(
        trackingToolData
      );
      if (response.status == 200) {

        resetPageSize = "Reset";
        totalPOCount = 0;
        this.setState({
          hasMoreData: true,
          mappedTrackingTool: [],
        }, async () => {
          await this.getAllTrackingTool();
        });


        // let uniquePONumberList = Array.from([
        //   ...new Set(response.data.map((l) => l.ponumber)),
        // ]);

        var frameAlertModalMessage =
          helperText.TrackingTool.multipleReminderEmail.replace(
            "[supplierNames]",
            supplierListCommaSeperated.trim().slice(0, -1)
          );
        frameAlertModalMessage = frameAlertModalMessage.replace(
          "[poNumbers]",
          poNoListCommaSeperated.trim().slice(0, -1)
        );

        // let filteredResponseData =
        //   parseInt(dropdown_shipFilter, 10) > 0
        //     ? response.data.filter(
        //       (e) =>
        //         e.clientId === parseInt(dropdown_clientFilter, 10) &&
        //         e.shipId == parseInt(dropdown_shipFilter, 10)
        //     )
        //     : response.data.filter(
        //       (e) => e.clientId === parseInt(dropdown_clientFilter, 10)
        //     );

        this.setState({
          emailSentModal: true,
          messageText: frameAlertModalMessage,
          //mappedTrackingTool: filteredResponseData,
          //defaultTrackingTool: filteredResponseData, //response.data,
          //poNumberList: uniquePONumberList,
        });
      } else {
        this.setState({
          emailSentModal: false,
          toastVisible: true,
          toastType: helperText.global.toastError,
          toastMessage: helperText.global.somethingWentWrong,
        });
      }

      this.setState({
        isLoading: false,
        modalText: "",
        selectedTTData: [],
      });
    } catch (error) { }
  };

  navigateTo = (path) => {
    this.props.navigate(path);
  };

  onEditTrackingToolClicked = (ttId) => {
    AddLocalStorageData("SelectedTrackingTool", ttId);
    this.navigateTo("/AddEditTrackingTool");
  };

  overlayImportPo = () => {
    const rowExist = this.state.mappedTrackingTool.length;

    if (
      this.state.accessList == null ||
      this.state.accessList.trackingToolAccess == UserAction.READWRITE
    ) {
      if (rowExist === 0 && addManualRow === 0) {
        this.navigateTo("/ImportPO");
      }
    }
  };

  importPoButtonClick = () => {
    this.navigateTo("/ImportPO");
  };

  onChangeOtherValue = (e) => {
    this.setState({
      otherValueStatus: e.target.value,
      otherValueValidationText: "",
    });
  };

  onSaveOtherValue = async () => {
    try {
      const {
        selectedItem,
        otherValueStatus,
        otherValueValidationText,
        userId,
      } = this.state;

      this.setState({
        isLoading: true,
      });

      if (otherValueStatus != "") {
        var ttStatusData = {
          TrackingToolId: selectedItem.trackingToolId,
          ClientId: selectedItem.clientId,
          ShipId: selectedItem.shipId,
          OtherValueStatus: otherValueStatus,
          CreatedBy: userId,
        };

        var response = await TrackingToolService.UpdateOtherValueStatus(
          ttStatusData
        );

        if (response != null && response != undefined) {
          this.setState({ otherValueStatus: "", showOtherValue: false });

          this.SaveCopyPastedRowData(this.state.dataToSaveOtherValue);
        } else {
          this.setState({
            toastVisible: true,
            toastType: helperText.global.toastError,
            toastMessage: helperText.global.somethingWentWrong,
            showOtherValue: false,
          });
        }
      } else {
        this.setState({
          otherValueValidationText: helperText.global.showValidationMessage,
        });
      }
      this.setState({
        isLoading: false,
      });
    } catch (error) { }
  };

  onModalCloseClick = () => {
    this.setState({
      otherValueStatus: "",
      showOtherValue: false,
      showViewModal: false,
      showViewHistoryModal: false,
      showUploadModal: false,
      showMDSDoCUploadModal: false,
      emailSentModal: false,
      messageText: "",
      showConfirmationModal: false,
      helpContentModal: false,
      selectedTTData: [],
      modalText: "",
      showConfirmationPopup: false,
      showSupplierAddingModal: false,
    });
  };

  onYesClickOfAddSupplierModal = () => {
    this.setState({ isYesClicked: true })
  }

  onViewModalCloseClick = () => {
    this.setState({
      showViewModal: false,
    });
  };

  viewPOFile = (filePath) => {
    this.setState({
      filePathToView: filePath,
      showViewModal: true,
    });
  };

  onViewHistoryLineItemClicked = async (ttId) => {
    try {
      this.setState({
        isLoading: true,
      });
      const response = await TrackingToolService.GetLineItemHistory(ttId);
      if (response.data != null && response.data != undefined) {
        if (response.status == 401) {
          Logout(this.props.navigate);
        } else if (response.status == 200) {
          if (response.data.length > 0) {
            this.setState({
              lstLineItemHistory: response.data,
              showViewHistoryModal: true,
            });
          } else {
            this.setState({
              lstLineItemHistory: [],
              showViewHistoryModal: false,
              toastVisible: true,
              toastType: helperText.global.toastError,
              toastMessage: helperText.TrackingTool.noLineItemHistory,
            });
          }
        }
      } else {
        this.setState({
          lstLineItemHistory: [],
          showViewHistoryModal: false,
          toastVisible: true,
          toastType: helperText.global.toastError,
          toastMessage: helperText.global.somethingWentWrong,
        });
      }

      this.setState({
        isLoading: false,
      });
    } catch (error) { }
  };

  onUploadPOFile = async (data) => {
    const {
      userId,
      dropdown_clientFilter,
      dropdown_yearFilter,
      userTypeId,
      dropdownYearList,
    } = this.state;
    this.setState({
      showUploadModal: false,
      isLoading: true,
    });

    var dropdownSelected_Year = "";
    if (dropdown_yearFilter != 0 && dropdown_yearFilter != undefined) {
      dropdownSelected_Year = dropdown_yearFilter;
    }
    else {
      dropdownSelected_Year = dropdownYearList.filter(
        (e) => e.parentValue == dropdown_clientFilter
      )[0].value;
    }

    let userData = new FormData();
    if (data.dropdown_fileProperty == "readable") {
      userData.append("LoggedInUserID", userId);
      userData.append("ClientID", dropdown_clientFilter);
      userData.append("Year", dropdownSelected_Year);
      userData.append("FileType", data.dropdown_filetype);
      userData.append("FileProperty", data.dropdown_fileProperty);
      userData.append("POFile", data.POFile);
      userData.append("UserTypeId", userTypeId);

      var response = await TrackingToolService.ImportPO(userData);
      if (response != null && response != undefined && response.status == 200) {
        if (
          response.data.incorrectDataMessage == "" ||
          response.data.incorrectDataMessage == null
        ) {
          this.setState(
            {
              rowData: response.data.pOMasterModels,
              defaultRowData: response.data.pOMasterModels,
              importedPOFileId: response.data.importedPOFileId,
              importedPOFilename: response.data.importedPOFilename,
              POItemsImportedCount: response.data.pOMasterModels.length,
              toastVisible: true,
              toastMessage: helperText.TrackingTool.POFileImportedSuccess,
              toastType: helperText.global.toastSuccess,
              isLoading: false,
            },
            () => {
              //this.selectHazmatItems();
            }
          );
        } else {
          this.setState({
            toastVisible: true,
            toastMessage: response.data.incorrectDataMessage,
            toastType: helperText.global.toastError,
            isLoading: false,
          });
        }
      } else if (response.status == 401) {
        Logout(this.props.navigate);
      } else if (response.status == 404) {
        this.setState({
          toastVisible: true,
          toastMessage: helperText.TrackingTool.incorrectExcel, //importedFileShipMissing
          toastType: helperText.global.toastError,
          isLoading: false,
        });
      } else {
        this.setState({
          toastVisible: true,
          toastMessage: helperText.global.somethingWentWrong,
          toastType: helperText.global.toastError,
          isLoading: false,
        });
      }
    } else {
      this.setState({
        rowData: [],
        defaultRowData: [],
        POItemsImportedCount: 0,
      });

      var dropdownSelected_Year = "";
      if (dropdown_yearFilter != 0 && dropdown_yearFilter != undefined) {
        dropdownSelected_Year = dropdown_yearFilter;
      }
      else {
        dropdownSelected_Year = dropdownYearList.filter(
          (e) => e.parentValue == dropdown_clientFilter
        )[0].value;
      }

      var poImportedFileIDMain = 0;
      for (var i = 0; i < data.unreadablePODataList.length; i++) {
        userData = new FormData();
        userData.append("LoggedInUserID", userId);
        userData.append("ClientID", dropdown_clientFilter);
        userData.append("Year", dropdownSelected_Year);
        userData.append("FileType", data.dropdown_filetype);
        userData.append("FileProperty", data.dropdown_fileProperty);
        userData.append("POFile", data.unreadablePODataList[i].poFile);
        userData.append("POImportedFileIDMain", poImportedFileIDMain);
        userData.append("TrackingToolId", data.TrackingToolId);
        userData.append("SupplierId", data.SupplierId);
        userData.append("UserTypeId", userTypeId);

        var addData = {
          ShipId: data.unreadablePODataList[i].dropdown_modalShip,
          SupplierId: data.unreadablePODataList[i].dropdown_modalSupplier,
          TotalPoItem: data.unreadablePODataList[i].totalPOItems,
          TotalSuspectedItem:
            data.unreadablePODataList[i].totalSuspectedHazmatItems,
          Pono: data.unreadablePODataList[i].poNumber,
          Podate: data.unreadablePODataList[i].poDate,
          CustomFileName: data.unreadablePODataList[i].customFileName,
          POFileName: data.unreadablePODataList[i].poFileName,
          POFilePath: data.unreadablePODataList[i].poFilePath,
        };

        userData.append("unreadablePODataList", JSON.stringify(addData));
        userData.append("PODate", convertDate(new Date(data.unreadablePODataList[i].poDate)));

        var response = await TrackingToolService.ImportPO(userData);
        if (
          response == null ||
          response == undefined ||
          response.status != 200
        ) {
          this.setState({
            toastVisible: true,
            toastMessage: helperText.global.somethingWentWrong,
            toastType: helperText.global.toastError,
            isLoading: false,
          });
          return;
        } else {
          poImportedFileIDMain = response.data;
        }
      }

      resetPageSize = "Reset";
      totalPOCount = 0;
      this.setState(
        {
          toastVisible: true,
          toastMessage: helperText.TrackingTool.POFileSavedSuccess,
          toastType: helperText.global.toastSuccess,
          isLoading: false,
          hasMoreData: true,
          mappedTrackingTool: [],
        },
        () => {
          this.getAllTrackingTool();
        }
      );
    }
  };

  openUploadModal = (modalToOpen) => {
    if (this.state.selectedTrackingToolDataList.length > 0) {
      if (modalToOpen === "showUploadModal") {
        this.setState({
          showUploadModal: true,
        });
      } else if (modalToOpen === "showMDSDoCUploadModal") {
        if (this.state.selectedTrackingToolDataList.length > 1) {
          this.setState({
            toastVisible: true,
            toastType: helperText.global.toastError,
            toastMessage: helperText.TrackingTool.selectOneLineItem,
          });
        } else if (
          this.state.selectedTrackingToolDataList[0].supplierId == null ||
          this.state.selectedTrackingToolDataList[0].supplierId == undefined
        ) {
          this.setState({
            toastVisible: true,
            toastType: helperText.global.toastError,
            toastMessage: helperText.TrackingTool.NoSupplierPresent,
          });
        } else {
          this.setState({ showMDSDoCUploadModal: true });
        }
      }
    } else {
      this.setState({
        toastVisible: true,
        toastMessage: helperText.TrackingTool.selectLineItemToUploadDocument,
        toastType: helperText.global.toastError,
      });
    }
  };

  onUploadMDSDoCFile = async (data) => {
    const { userId, selectedTrackingToolIdList, selectedTrackingToolDataList } =
      this.state;
    this.setState({ showMDSDoCUploadModal: false, isLoading: true });
    var selectedTTId = [];
    selectedTrackingToolIdList.forEach((element) => {
      selectedTTId.push({
        TrackingToolId: element,
      });
    });
    let mdsdocData = new FormData();

    mdsdocData.append("MdNo", data.mdNumber);
    mdsdocData.append("SdocNo", data.sdocNumber);
    mdsdocData.append("MdDateOfIssue", convertDate(data.dateOfMDIssue));
    mdsdocData.append("SdocDateOfIssue", convertDate(data.dateOfSDoCIssue));
    mdsdocData.append("SupplierName", data.dropdown_supplierName);
    mdsdocData.append("Division", data.division);
    mdsdocData.append("MakerName", data.makerName);
    mdsdocData.append("ProductName", data.productName);
    mdsdocData.append("Declaration", data.objDeclaration);
    mdsdocData.append("Remarks", data.remarks);
    mdsdocData.append("Status", data.dropdown_status);
    mdsdocData.append("UserID", userId);
    mdsdocData.append("TFile", data.TFile);

    mdsdocData.append("TrackingToolId", data.TrackingToolId);
    mdsdocData.append("SupplierId", data.SupplierId);
    mdsdocData.append(
      "ClientId",
      selectedTrackingToolDataList.length > 0
        ? selectedTrackingToolDataList[0]?.clientId
        : 0
    );
    mdsdocData.append(
      "ShipId",
      selectedTrackingToolDataList.length > 0
        ? selectedTrackingToolDataList[0]?.shipId
        : 0
    );
    var responseSaved = await TrackingToolService.AddMDSDoCData(mdsdocData);

    if (
      responseSaved != null &&
      responseSaved != undefined &&
      responseSaved.status == 200
    ) {
      this.setState({
        isLoading: false,
        toastVisible: true,
        toastType: helperText.global.toastSuccess,
        toastMessage: helperText.TrackingTool.documnetUploadedSuccess,
      });
    } else if (responseSaved.status == 401) {
      Logout(this.props.navigate);
    } else {
      this.setState({
        toastVisible: true,
        toastMessage: helperText.global.somethingWentWrong,
        toastType: helperText.global.toastError,
        isLoading: false,
      });
    }
  };

  onSendMultipleReminder = () => {
    if (this.state.selectedToSendReminderEmail.length > 0) {
      let supplierList = this.state.selectedToSendReminderEmail.filter(
        (x) => x.supplierId == 0 || x.supplierId == null
      );

      if (supplierList.length > 0) {
        this.setState({
          toastVisible: true,
          toastMessage: helperText.TrackingTool.NoSupplierPresent,
          toastType: helperText.global.toastError,
        });
      } else {
        AddLocalStorageData(
          "selectedLineItems",
          JSON.stringify(this.state.selectedToSendReminderEmail)
        );
        this.props.navigate("/MultipleCommunicationReminder");
      }
    } else {
      this.setState({
        toastVisible: true,
        toastMessage: helperText.TrackingTool.selectLineItemToSendReminder,
        toastType: helperText.global.toastError,
      });
    }
  };

  onShowHelpContent = () => {
    this.setState({
      helpContentModal: true,
    });
  };

  onViewFileDownload = (filePath) => {
    try {
      var lastItem = filePath.split("/").pop();
      var fname = lastItem;
      const link = document.createElement("a");
      link.href = filePath;
      link.setAttribute("download", filePath);
      document.body.appendChild(link);
      link.download = fname;
      link.click();
      this.setState({
        toastVisible: true,
        toastType: helperText.global.toastSuccess,
        toastMessage: helperText.global.downloadSuccess,
      });
    } catch (e) { }
  };

  onViewEmailClick = () => {
    AddLocalStorageData("location", "/TrackingTool");
    this.navigateTo("/CommunicationHistory");
  };

  onBackClick = () => {
    AddLocalStorageData("selectedShipTTool", null);
    var loc = GetLocalStorageDataWithoutParse("location");
    AddLocalStorageData("location", "");
    if (loc == "/ActionRequired") {
      this.props.navigate("/ActionRequired");
    } else if (loc == "/ManageShips") {
      this.props.navigate("/ManageShips");
    } else if (loc == "/ManageIHMDatabaseRegistry") {
      this.props.navigate("/ManageIHMDatabaseRegistry");
    } else if (loc == "/ActivityLog") {
      this.props.navigate("/ActivityLog");
    } else if (this.state.isClientOrClientUser)
      this.props.navigate("/ClientDashboard");
    else if (this.state.isShipCrew) this.props.navigate("/ShipCrewDashboard");
    else this.props.navigate("/Dashboard");
  };

  /* <summary>
      date: 30/06/2023
      Name: HV
      description: downloading tracking tool data in excel
      <summary> */
  onDownloadTrackingToolClicked = async () => {
    const { mappedTrackingTool,
      dropdown_clientFilter,
      dropdown_shipFilter,
      dropdown_yearFilter,
      userId,
      clientUserId,
      userTypeId,
      dropdown_supplierFilter,
      filter_fromDate,
      filter_toDate,
      dropdown_poNoFilter,
      dropdown_remarkFilter,
      dropdown_hazmatFilter,
      tableAItem,
      tableBItem,
    } =
      this.state;

    // toastVisible: true,
    // toastType: helperText.global.toastSuccess,
    // toastMessage: helperText.global.downloadBackgroundStarted

    if (mappedTrackingTool.length > 0) {
      this.setState({
        isLoading: false,
        isBouncing: true,
      });

      let fileName = "";

      if (dropdown_clientFilter != null && dropdown_clientFilter != 0) {
        fileName = mappedTrackingTool[0]?.clientName;
      }

      if (dropdown_shipFilter != null && dropdown_shipFilter != 0) {
        fileName = fileName + "_" + mappedTrackingTool[0]?.shipName;
      }

      fileName = fileName + "_TrackingTool.xlsx";

      let trackingToolData = {
        clientId: dropdown_clientFilter,
        shipId: dropdown_shipFilter === "" ? 0 : dropdown_shipFilter,
        year: dropdown_yearFilter === "" ? 0 : dropdown_yearFilter,
        fromPODate: filter_fromDate === "" ? null : filter_fromDate,
        toPODate: filter_toDate === "" ? null : filter_toDate,
        supplierId: dropdown_supplierFilter === "" ? 0 : dropdown_supplierFilter,
        poNumber: dropdown_poNoFilter === "" ? null : dropdown_poNoFilter,
        poStatus: dropdown_remarkFilter === "" ? 0 : dropdown_remarkFilter,
        hazmatPresent: dropdown_hazmatFilter === "" ? null : dropdown_hazmatFilter,
        tableA: tableAItem.length > 0 ? tableAItem.map(item => item.value) : tableAItem,
        tableB: tableBItem.length > 0 ? tableBItem.map(item => item.value) : tableBItem,
        userID: userId,
        clientUserId: clientUserId,
        userTypeId: userTypeId,
        filename: fileName
      };
      var responseList = await TrackingToolService.ApplyFiltersToDownloadTrackingTool(
        trackingToolData
      );

      if (responseList.status == 200 && responseList.data != null) {
        let trackingToolDataToDownload = new FormData();
        trackingToolDataToDownload.append(
          "TrackingToolList",
          JSON.stringify(responseList.data)
        );
        trackingToolDataToDownload.append("FileName", fileName);

        var response = await TrackingToolService.DownloadTrackingToolData(
          trackingToolDataToDownload
        );

        if (response.status == 200 && response.data != null) {
          const url = window.URL.createObjectURL(new Blob([response.data]));

          const link = document.createElement("a");
          link.href = url;
          link.setAttribute("download", fileName);
          document.body.appendChild(link);
          link.click();

          this.setState({
            toastVisible: true,
            toastType: helperText.global.toastSuccess,
            toastMessage: helperText.global.downloadSuccess,
            isLoading: false,
            isBouncing: false,
          });
        } else if (response.status == 401) {
          Logout(this.props.navigate);
        } else {
          this.setState({
            toastVisible: true,
            toastMessage: helperText.global.somethingWentWrong,
            toastType: helperText.global.toastError,
            isLoading: false,
            isBouncing: false,
          });
        }
      }
    } else {
      this.setState({
        toastVisible: true,
        toastMessage: helperText.TrackingTool.trackingToolEmpty,
        toastType: helperText.global.toastError,
        isLoading: false,
        isBouncing: false,
      });
    }
  };

  onDownloadAllTrackingToolClicked = async () => {
    this.setState({
      isLoading: true,
    });

    let trackingToolData = new FormData();

    let fileName = "";

    fileName = "AllTrackingTool.xlsx";

    trackingToolData.append("FileName", fileName);

    var response = await TrackingToolService.DownloadAllTrackingToolData(
      trackingToolData
    );

    if (response.status == 200 && response.data != null) {
      const url = window.URL.createObjectURL(new Blob([response.data]));

      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", fileName);
      document.body.appendChild(link);
      link.click();

      this.setState({
        toastVisible: true,
        toastType: helperText.global.toastSuccess,
        toastMessage: helperText.global.downloadSuccess,
        isLoading: false,
      });
    } else if (response.status == 401) {
      Logout(this.props.navigate);
    } else {
      this.setState({
        toastVisible: true,
        toastMessage: helperText.global.somethingWentWrong,
        toastType: helperText.global.toastError,
        isLoading: false,
      });
    }
  };


  onChange = (e) => {
    const { name, value } = e.target;
    this.setState({ [name]: value }, () => {
      this.setState({
        autoSupplierName: value,
        autoAddSupplierMessage: null,
      });
    });
  }

  onAutoSupplierSaveClick = () => {
    const { autoSupplierName, savedLineItemList } = this.state;

    if (isStringEmpty(autoSupplierName)) {
      this.setState({
        autoAddSupplierMessage: helperText.TrackingTool.autoAddSupplierMessage,
      });
      return;
    }
    else {
      let savedList = savedLineItemList;
      savedList[0].supplierName = autoSupplierName;
      this.SaveCopyPastedRowData(savedList);

      this.setState({
        showSupplierAddingModal: false,
      });
    }
  }

  render() {
    const {
      mappedTrackingTool,
      supplierCompanyList,
      supplierStatusList,
      filter_fromDate,
      filter_toDate,
      comments,
      colDefs,
      colDefsClient,
      isLoading,
      onHoldFromDate,
      onHoldToDate,
      showOnHoldModal,
      toastVisible,
      toastType,
      toastMessage,
      selectedSupplierId,
      showZoom,
      showOtherValue,
      defaultTrackingTool,

      clientDataList,
      shipDataList,
      supplierDataList,
      dropdownYearList,
      statusDataList,
      tableAList,
      tableBList,
      tableAItem,
      tableBItem,
      poNumberList,

      otherValueStatus,
      otherValueValidationText,
      showViewModal,
      filePathToView,

      showViewHistoryModal,
      lstLineItemHistory,
      lineItemHistoryColumns,

      showUploadModal,
      showMDSDoCUploadModal,
      dropdownList,
      dropdown_clientFilter,
      dropdown_shipFilter,
      dropdown_supplierFilter,
      dropdown_poNoFilter,
      dropdown_hazmatFilter,
      dropdown_yearFilter,
      dropdown_remarkFilter,
      emailSentModal,
      messageText,
      clientName,
      shipName,

      selectedTrackingToolDataList,

      frameworkComponents,
      isClientOrClientUser,
      isShipCrew,
      showConfirmationModal,
      modalText,
      previousToDate,
      nextFromDate,
      isAdminOrInternalUser,

      helpContentModal,
      displayText,

      showConfirmationPopup,

      showSupplierAddingModal,
      isYesClicked,
      autoAddSupplierMessage,
      autoSupplierName,
      userTypeId,
      accessList,
      searchedText,
      hasMoreData,
      resetLoadedPages,
      count,
      isBouncing
    } = this.state;

    return (
      <>
        {isLoading && <Loader></Loader>}
        <Header componentFor={"TrackingTool"} />
        {showZoom && (
          <ZoomTrackingTool
            showZoom
            accessList={accessList}
            mappedTrackingTool={mappedTrackingTool}
            colDefs={
              isClientOrClientUser || isShipCrew
                ? colDefsClient
                : this.gridColumns()
            }
            onAddRow={this.onAddRowClicked}
            onSendReminder={this.onSendMultipleReminder}
            onOpenSubModal={this.openUploadModal}
            onCellKeyPress={this.onCellKeyPress}
            onSelectionChanged={this.onSelectionChanged}
            onCellClickedMenu={this.onCellClickedMenu}
            onGridReady={this.onGridReady}
            onCloseZoomClicked={this.onZoomClicked}
            isClientOrClientUser={isClientOrClientUser}
            isShipCrew={isShipCrew}
            userRolePermissionRead={this.state.userRolePermissionRead}
            onDownloadTrackingToolClicked={this.onDownloadTrackingToolClicked}
            onDownloadAllTrackingToolClicked={this.onDownloadAllTrackingToolClicked}
            onShowHelpContent={this.onShowHelpContent}
          ></ZoomTrackingTool>
        )}
        {showOtherValue && (
          <OtherStatusTrackingTool
            showOtherValue
            otherValueValidation={otherValueValidationText}
            comments={otherValueStatus}
            onCommentsChange={this.onChangeOtherValue}
            onSaveClick={this.onSaveOtherValue}
            onCloseClick={this.onModalCloseClick}
          ></OtherStatusTrackingTool>
        )}
        {showViewModal && (
          <ViewModal
            showViewModal
            modalheader="View PO File"
            filePath={filePathToView}
            onCloseClick={this.onViewModalCloseClick}
            onDownloadClick={() => this.onViewFileDownload(filePathToView)}
            toastVisible={toastVisible}
            type={toastType}
            message={toastMessage}
            closeToast={this.closeToast}
          ></ViewModal>
        )}
        {showViewHistoryModal && (
          <ViewLineItemHistory
            showViewHistoryModal
            mappedHistory={lstLineItemHistory}
            colDefs={lineItemHistoryColumns}
            onCloseClick={this.onModalCloseClick}
          ></ViewLineItemHistory>
        )}
        {showUploadModal && (
          <TTPOFileUpload
            onCloseClick={this.onModalCloseClick}
            onUploadClick={this.onUploadPOFile}
            dropdownList={dropdownList}
            selectedPOUpload={selectedTrackingToolDataList}
          />
        )}
        {emailSentModal && (
          <AlertModal
            emailSentModal
            onOkClick={this.onModalCloseClick}
            msgToDisplay={messageText}
            viewLink={this.onViewEmailClick}
          />
        )}
        {helpContentModal && (
          <HelpModal
            helpContentModal
            isPOInstructions={false}
            onOkClick={this.onModalCloseClick}
          />
        )}
        {showMDSDoCUploadModal && (
          <TTMDSDoCUpload
            onCloseClick={this.onModalCloseClick}
            onUploadClick={this.onUploadMDSDoCFile}
            dropdownList={dropdownList}
            selectedPOUpload={selectedTrackingToolDataList}
          />
        )}
        {toastVisible && (
          <Toast
            toastVisible
            type={toastType}
            message={toastMessage}
            closeToast={this.closeToast}
          />
        )}

        {showConfirmationModal && (
          <ConfirmationModal
            onModalCloseClick={this.onModalCloseClick}
            deleteIHMDocData={this.sendMDSDoCRequest}
            modalText={modalText}
          />
        )}

        {showConfirmationPopup && (
          <ConfirmationModal
            onModalCloseClick={this.onModalCloseClick}
            deleteIHMDocData={this.deleteTTLineItemData}
          />
        )}

        {showSupplierAddingModal && (
          <AutoCreatingSupplier
            onModalCloseClick={this.onModalCloseClick}
            yesClicked={this.onYesClickOfAddSupplierModal}
            isYesClicked={isYesClicked}
            errorMessages={autoAddSupplierMessage}
            onChange={this.onChange}
            onSaveClick={this.onAutoSupplierSaveClick}
            supplierName={autoSupplierName}
          />
        )}


        <main className="page-height">
          <section className="tracking-tool-page">
            <div className="container">
              <div className="row">
                <div className="col-12 col-md-5 col-lg-8 ">
                  <div className="admin-profile-detail-back">
                    <img
                      src="images/back.png"
                      alt="Back"
                      onClick={this.onBackClick}
                      className="cursor-class"
                    />
                    <h5>Tracking Tool</h5>
                  </div>
                </div>
                <div className="col-12 col-md-7 col-lg-4">
                  <div className="searchBar">
                    <input id="searchQueryInput"
                      type="text"
                      name="searchQueryInput"
                      placeholder={"Client Name, Ship Name, PO Number, etc..."}
                      onChange={this.handleInputChange}
                      onKeyDown={this.handleKeyDown}
                      value={searchedText}
                      className="search-placeholder-text" />
                    <button id="searchQuerySubmit" type="submit" name="searchQuerySubmit">
                      <img src="images/magnifying-glass.png" width="27px" />
                    </button>
                  </div>
                </div>
              </div>

              <div className="accordion-filter-dropdown">
                <div id="myAccordion">
                  <div className="accordion-item">
                    <h2 className="accordion-header">
                      <button
                        type="button"
                        className="accordion-button collapsed filter-toggle-btn"
                        data-bs-toggle="collapse"
                        data-bs-target="#collapseOne"
                      >
                        Filter By
                      </button>
                    </h2>
                    <div
                      id="collapseOne"
                      className="accordion-collapse collapse"
                      data-bs-parent="#myAccordion"
                    >
                      <div className="card-body filter-dropdwn-body">
                        <div className="col-lg-12 filter-flex-container wrap">
                          <div
                            className="filter-box filter-flex-item form-label"
                            id={"filter"}
                          >
                            {!isClientOrClientUser &&
                              !isShipCrew &&
                              !this.state.userRolePermissionRead ? (
                              <div>
                                <Dropdown
                                  dropdownLabel="Client Name:"
                                  name="clientFilter"
                                  onChange={this.onFilterSelected}
                                  selected={dropdown_clientFilter}
                                  style={{
                                    textOverflow: "ellipsis",
                                    whiteSpace: "pre",
                                    overflow: "hidden",
                                  }}
                                  hideselect={true}
                                >
                                  {clientDataList == null
                                    ? null
                                    : clientDataList.map((item) => (
                                      <option
                                        wrap="true"
                                        key={item.value}
                                        value={item.value}
                                      >
                                        {item.label}
                                      </option>
                                    ))}
                                  ;
                                </Dropdown>
                                {dropdown_clientFilter != "" ? (
                                  <UncontrolledTooltip
                                    autohide={false}
                                    target={"filter"}
                                    aria-haspopup="true"
                                    role="example"
                                    effect="solid"
                                    placement="bottom"
                                    style={{ width: "350px" }}
                                  >
                                    {clientDataList != null &&
                                      clientDataList.filter(
                                        (x) => x.value == dropdown_clientFilter
                                      )[0]?.label}
                                  </UncontrolledTooltip>
                                ) : null}
                              </div>
                            ) : (
                              <div className="form-group dropdown-form form-drpdown-responsive admin-client-form">
                                <label className="label-class">
                                  Client Name:
                                </label>
                                <input
                                  type="name"
                                  value={clientName}
                                  className="form-control"
                                  disabled
                                  readOnly
                                  style={{
                                    textOverflow: "ellipsis",
                                    whiteSpace: "pre",
                                    overflow: "hidden",
                                  }}
                                  title={clientName}
                                />
                              </div>
                            )}
                          </div>

                          <div
                            className="filter-box filter-flex-item form-label"
                            id={"shipName"}
                          >
                            {!isShipCrew ? (
                              <div>
                                <Dropdown
                                  dropdownLabel="Ship Name:"
                                  name="shipFilter"
                                  onChange={this.onFilterSelected}
                                  selected={dropdown_shipFilter}
                                  style={{
                                    textOverflow: "ellipsis",
                                    whiteSpace: "pre",
                                    overflow: "hidden",
                                  }}
                                  hasALL={true}
                                >
                                  {shipDataList == null
                                    ? null
                                    : shipDataList.map((item) => (
                                      <option
                                        wrap="true"
                                        key={item.value}
                                        value={item.value}
                                      >
                                        {item.label}
                                      </option>
                                    ))}
                                  ;
                                </Dropdown>
                                {dropdown_clientFilter != "" ? (
                                  <UncontrolledTooltip
                                    autohide={false}
                                    target={"shipName"}
                                    aria-haspopup="true"
                                    role="example"
                                    effect="solid"
                                    placement="bottom"
                                    style={{ width: "350px" }}
                                  >
                                    {clientDataList != null &&
                                      clientDataList.filter(
                                        (x) => x.value == dropdown_clientFilter
                                      )[0]?.label}
                                  </UncontrolledTooltip>
                                ) : null}
                              </div>
                            ) : (
                              <div className="form-group dropdown-form form-drpdown-responsive">
                                <label className="label-class">
                                  Ship Name:
                                </label>
                                <input
                                  type="name"
                                  value={shipName}
                                  className="form-control"
                                  disabled
                                  readOnly
                                  style={{
                                    textOverflow: "ellipsis",
                                    whiteSpace: "pre",
                                    overflow: "hidden",
                                  }}
                                  title={shipName}
                                />
                              </div>
                            )}
                          </div>

                          <div
                            className="filter-box filter-flex-item"
                            id={"year"}
                          >
                            <Dropdown
                              dropdownLabel="Year:"
                              name="yearFilter"
                              onChange={this.onFilterSelected}
                              selected={dropdown_yearFilter}
                              style={{
                                textOverflow: "ellipsis",
                                whiteSpace: "pre",
                                overflow: "hidden",
                              }}
                            >
                              {dropdownYearList == null
                                ? null
                                : dropdownYearList.map((item, index) => (
                                  <option
                                    wrap="true"
                                    value={item.value}
                                    key={index}
                                  >
                                    {item.label}
                                  </option>
                                ))}
                              ;
                            </Dropdown>
                            {dropdown_yearFilter != "" ? (
                              <UncontrolledTooltip
                                autohide={false}
                                target={"year"}
                                aria-haspopup="true"
                                role="example"
                                effect="solid"
                                placement="bottom"
                                style={{ width: "350px" }}
                              >
                                {dropdownYearList != null &&
                                  dropdownYearList.filter(
                                    (x) => x.value == dropdown_yearFilter
                                  )[0]?.label}
                              </UncontrolledTooltip>
                            ) : null}
                          </div>
                          <div className="tracking-tool-fieldset">
                            <fieldset className="p-2 admin-ship-fieldset">
                              <legend className="float-none w-auto p-2 admin-ship-legend">
                                Filter By
                              </legend>
                              <div className="row">
                                <div className="col-lg-12 filter-flex-container wrap">
                                  <label
                                    htmlFor="fromdate"
                                    className="form-label"
                                  >
                                    PO Date:
                                  </label>
                                  <div className="filter-box filter-flex-item">
                                    <label
                                      htmlFor="fromdate"
                                      className="form-label"
                                    >
                                      From:
                                    </label>
                                    <DatePicker
                                      selectedDate={filter_fromDate}
                                      onDateChange={(e) =>
                                        this.onDateChanged("fromDate", e)
                                      }
                                      maxDate={previousToDate}
                                    >
                                      {" "}
                                    </DatePicker>
                                  </div>

                                  <div className="filter-box filter-flex-item">
                                    <label
                                      htmlFor="todate"
                                      className="form-label"
                                    >
                                      To:
                                    </label>
                                    <DatePicker
                                      selectedDate={filter_toDate}
                                      onDateChange={(e) =>
                                        this.onDateChanged("toDate", e)
                                      }
                                      minDate={nextFromDate}
                                      maxDate={currentDate}
                                    >
                                      {" "}
                                    </DatePicker>
                                  </div>

                                  <div
                                    className="filter-box filter-flex-item form-label"
                                    id={"supName"}
                                  >
                                    <Dropdown
                                      dropdownLabel="Supplier Name:"
                                      name="supplierFilter"
                                      onChange={this.onFilterSelected}
                                      selected={dropdown_supplierFilter}
                                      style={{
                                        textOverflow: "ellipsis",
                                        whiteSpace: "pre",
                                        overflow: "hidden",
                                      }}
                                    >
                                      {supplierDataList == null
                                        ? null
                                        : supplierDataList.map((item) => (
                                          <option
                                            wrap="true"
                                            key={item.value}
                                            value={item.value}
                                          >
                                            {item.label}
                                          </option>
                                        ))}
                                      ;
                                    </Dropdown>
                                    {dropdown_supplierFilter != "" ? (
                                      <UncontrolledTooltip
                                        autohide={false}
                                        target={"supName"}
                                        aria-haspopup="true"
                                        role="example"
                                        effect="solid"
                                        placement="bottom"
                                        style={{ width: "350px" }}
                                      >
                                        {supplierDataList != null &&
                                          supplierDataList.filter(
                                            (x) =>
                                              x.value == dropdown_supplierFilter
                                          )[0]?.label}
                                      </UncontrolledTooltip>
                                    ) : null}
                                  </div>

                                  <div
                                    className="filter-box filter-flex-item form-label"
                                    id={"po"}
                                  >
                                    <Dropdown
                                      dropdownLabel="PO Number:"
                                      name="poNoFilter"
                                      onChange={this.onFilterSelected}
                                      selected={dropdown_poNoFilter}
                                      style={{
                                        textOverflow: "ellipsis",
                                        whiteSpace: "pre",
                                        overflow: "hidden",
                                      }}
                                    >
                                      {poNumberList == null
                                        ? null
                                        : poNumberList.map((item) => (
                                          <option
                                            wrap="true"
                                            key={item}
                                            value={item}
                                          >
                                            {item}
                                          </option>
                                        ))}
                                      ;
                                    </Dropdown>
                                    {dropdown_poNoFilter != undefined &&
                                      dropdown_poNoFilter != null &&
                                      dropdown_poNoFilter != "" &&
                                      dropdown_poNoFilter != 0 ? (
                                      <UncontrolledTooltip
                                        autohide={false}
                                        target={"po"}
                                        aria-haspopup="true"
                                        role="example"
                                        effect="solid"
                                        placement="bottom"
                                        style={{ width: "350px" }}
                                      >
                                        {poNumberList != null &&
                                          dropdown_poNoFilter}
                                      </UncontrolledTooltip>
                                    ) : null}
                                  </div>

                                  <div
                                    className="filter-box filter-flex-item form-label"
                                    id={"supRemark"}
                                  >
                                    <Dropdown
                                      dropdownLabel="Remark / Status:"
                                      name="remarkFilter"
                                      onChange={this.onFilterSelected}
                                      selected={dropdown_remarkFilter}
                                      style={{
                                        textOverflow: "ellipsis",
                                        whiteSpace: "pre",
                                        overflow: "hidden",
                                      }}
                                    >
                                      {statusDataList == null
                                        ? null
                                        : statusDataList
                                          .sort((a, b) =>
                                            a.label.localeCompare(b.label)
                                          )
                                          .map((item) => (
                                            <option
                                              wrap="true"
                                              key={item.value}
                                              value={item.value}
                                            >
                                              {item.label}
                                            </option>
                                          ))}
                                      ;
                                    </Dropdown>
                                    {dropdown_remarkFilter != "" ? (
                                      <UncontrolledTooltip
                                        autohide={false}
                                        target={"supRemark"}
                                        aria-haspopup="true"
                                        role="example"
                                        effect="solid"
                                        placement="bottom"
                                        style={{ width: "350px" }}
                                      >
                                        {statusDataList != null &&
                                          statusDataList.filter(
                                            (x) =>
                                              x.value == dropdown_remarkFilter
                                          )[0]?.label}
                                      </UncontrolledTooltip>
                                    ) : null}
                                  </div>

                                  <div
                                    className="filter-box filter-flex-item form-label"
                                    id={"hazmatStatus"}
                                  >
                                    <Dropdown
                                      dropdownLabel="Hazmat Present:"
                                      name="hazmatFilter"
                                      onChange={this.onFilterSelected}
                                      selected={dropdown_hazmatFilter}
                                      style={{
                                        textOverflow: "ellipsis",
                                        whiteSpace: "pre",
                                        overflow: "hidden",
                                      }}
                                    >
                                      {hazmatPresentStatus == null
                                        ? null
                                        : hazmatPresentStatus.map(
                                          (item, key) => (
                                            <option
                                              wrap="true"
                                              key={key}
                                              value={item.value}
                                            >
                                              {item.label}
                                            </option>
                                          )
                                        )}
                                      ;
                                    </Dropdown>
                                    {dropdown_hazmatFilter != "" ? (
                                      <UncontrolledTooltip
                                        autohide={false}
                                        target={"hazmatStatus"}
                                        aria-haspopup="true"
                                        role="example"
                                        effect="solid"
                                        placement="bottom"
                                        style={{ width: "350px" }}
                                      >
                                        {hazmatPresentStatus != null &&
                                          hazmatPresentStatus.filter(
                                            (x) =>
                                              x.value == dropdown_hazmatFilter
                                          )[0]?.label}
                                      </UncontrolledTooltip>
                                    ) : null}
                                  </div>

                                  <div className="filter-box filter-flex-item">
                                    <label
                                      htmlFor="exampleDataList"
                                      className="form-label"
                                    >
                                      Hazmat Table A:
                                    </label>
                                    <MultiSelectDropdown
                                      name="tableAFilter"
                                      selectedOptions={
                                        tableAItem == undefined
                                          ? []
                                          : tableAItem
                                      }
                                      options={
                                        tableAList == undefined
                                          ? []
                                          : tableAList
                                      }
                                      onChange={this.onTableADropdownChange}
                                      isMulti={true}
                                    />
                                  </div>
                                  <div className="filter-box filter-flex-item">
                                    <label
                                      htmlFor="exampleDataList"
                                      className="form-label"
                                    >
                                      Hazmat Table B:
                                    </label>
                                    <MultiSelectDropdown
                                      name="tableBFilter"
                                      selectedOptions={
                                        tableBItem == undefined
                                          ? []
                                          : tableBItem
                                      }
                                      options={
                                        tableBList == undefined
                                          ? []
                                          : tableBList
                                      }
                                      onChange={this.onTableBDropdownChange}
                                      isMulti={true}
                                    />
                                  </div>

                                  <div className="filter-box clear-all-btn pt-2">
                                    <button
                                      type="button"
                                      className="btn btn-blue-bg "
                                      onClick={this.clearAllFilter}
                                    >
                                      Reset
                                    </button>
                                  </div>
                                </div>
                              </div>
                            </fieldset>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="desktop-class">
                <div className="row">
                  <div className="col-lg-12 filter-flex-container wrap">
                    <div
                      className="filter-box filter-flex-item form-label"
                      id={"clientName1"}
                    >
                      {!isClientOrClientUser && !isShipCrew ? (
                        <div>
                          <Dropdown
                            dropdownLabel="Client Name:"
                            name="clientFilter"
                            onChange={this.onFilterSelected}
                            selected={dropdown_clientFilter}
                            style={{
                              textOverflow: "ellipsis",
                              whiteSpace: "pre",
                              overflow: "hidden",
                            }}
                            hideselect={true}
                          >
                            {clientDataList == null
                              ? null
                              : clientDataList.map((item) => (
                                <option
                                  wrap="true"
                                  key={item.value}
                                  value={item.value}
                                >
                                  {item.label}
                                </option>
                              ))}
                            ;
                          </Dropdown>
                          {dropdown_clientFilter != "" ? (
                            <UncontrolledTooltip
                              autohide={false}
                              target={"clientName1"}
                              aria-haspopup="true"
                              role="example"
                              effect="solid"
                              placement="bottom"
                              style={{ width: "350px" }}
                            >
                              {clientDataList != null &&
                                clientDataList.filter(
                                  (x) => x.value == dropdown_clientFilter
                                )[0]?.label}
                            </UncontrolledTooltip>
                          ) : null}
                        </div>
                      ) : (
                        <div className="form-group dropdown-form form-drpdown-responsive">
                          <label className="label-class">Client Name:</label>
                          <input
                            type="name"
                            value={clientName}
                            className="form-control"
                            disabled
                            readOnly
                            style={{
                              textOverflow: "ellipsis",
                              whiteSpace: "pre",
                              overflow: "hidden",
                            }}
                            title={clientName}
                          />
                        </div>
                      )}
                    </div>

                    <div
                      className="filter-box filter-flex-item form-label"
                      id={"shipName1"}
                    >
                      {!isShipCrew ? (
                        <div>
                          <Dropdown
                            dropdownLabel="Ship Name:"
                            name="shipFilter"
                            onChange={this.onFilterSelected}
                            selected={dropdown_shipFilter}
                            style={{
                              textOverflow: "ellipsis",
                              whiteSpace: "pre",
                              overflow: "hidden",
                            }}
                            hasALL={true}
                          >
                            {shipDataList == null
                              ? null
                              : shipDataList.map((item) => (
                                <option
                                  wrap="true"
                                  key={item.value}
                                  value={item.value}
                                >
                                  {item.label}
                                </option>
                              ))}
                            ;
                          </Dropdown>
                          {dropdown_shipFilter > 0 ? (
                            <UncontrolledTooltip
                              autohide={false}
                              target={"shipName1"}
                              aria-haspopup="true"
                              role="example"
                              effect="solid"
                              placement="bottom"
                              style={{ width: "350px" }}
                            >
                              {shipDataList != null &&
                                shipDataList.filter(
                                  (x) => x.value == dropdown_shipFilter
                                )[0]?.label}
                            </UncontrolledTooltip>
                          ) : null}
                        </div>
                      ) : (
                        <div className="form-group dropdown-form form-drpdown-responsive ">
                          {" "}
                          {/* admin-client-form */}
                          <label className="label-class">Ship Name:</label>
                          <input
                            type="name"
                            value={shipName}
                            className="form-control"
                            disabled
                            readOnly
                            style={{
                              textOverflow: "ellipsis",
                              whiteSpace: "pre",
                              overflow: "hidden",
                            }}
                            title={shipName}
                          />
                        </div>
                      )}
                    </div>

                    <div className="filter-box filter-flex-item" id={"year1"}>
                      <Dropdown
                        dropdownLabel="Year:"
                        name="yearFilter"
                        onChange={this.onFilterSelected}
                        selected={dropdown_yearFilter}
                        style={{
                          textOverflow: "ellipsis",
                          whiteSpace: "pre",
                          overflow: "hidden",
                        }}
                      >
                        {dropdownYearList == null
                          ? null
                          : dropdownYearList.map((item, index) => (
                            <option
                              wrap="true"
                              value={item.value}
                              key={index}
                            >
                              {item.label}
                            </option>
                          ))}
                        ;
                      </Dropdown>
                      {dropdown_yearFilter > 0 ? (
                        <UncontrolledTooltip
                          autohide={false}
                          target={"year1"}
                          aria-haspopup="true"
                          role="example"
                          effect="solid"
                          placement="bottom"
                          style={{ width: "350px" }}
                        >
                          {dropdownYearList != null &&
                            dropdownYearList.filter(
                              (x) => x.value == dropdown_yearFilter
                            )[0]?.label}
                        </UncontrolledTooltip>
                      ) : null}
                    </div>
                  </div>

                  <div className="tracking-tool-fieldset">
                    <fieldset className="p-2 admin-ship-fieldset">
                      <legend className="float-none w-auto p-2 admin-ship-legend">
                        Filter By
                      </legend>
                      <div className="row">
                        <div className="col-lg-12 filter-flex-container wrap">
                          <div className="filter-date-from">
                            <label
                              htmlFor="exampleDataList"
                              className="form-label"
                            >
                              PO Date:
                            </label>

                            <span className="from-to-box">
                              <span className="from-date">
                                <label>From:</label>
                                <DatePicker
                                  selectedDate={filter_fromDate}
                                  onDateChange={(e) =>
                                    this.onDateChanged("fromDate", e)
                                  }
                                  maxDate={previousToDate}
                                >
                                  {" "}
                                </DatePicker>
                              </span>
                              <span className="to-date">
                                <label>To:</label>
                                <DatePicker
                                  selectedDate={filter_toDate}
                                  onDateChange={(e) =>
                                    this.onDateChanged("toDate", e)
                                  }
                                  minDate={nextFromDate}
                                  maxDate={currentDate}
                                >
                                  {" "}
                                </DatePicker>
                              </span>
                            </span>
                          </div>

                          <div
                            className="filter-box filter-flex-item form-label"
                            id={"shipVal"}
                          >
                            <Dropdown
                              dropdownLabel="Supplier Name:"
                              name="supplierFilter"
                              onChange={this.onFilterSelected}
                              selected={dropdown_supplierFilter}
                              style={{
                                textOverflow: "ellipsis",
                                whiteSpace: "pre",
                                overflow: "hidden",
                              }}
                            >
                              {supplierDataList == null
                                ? null
                                : supplierDataList.map((item) => (
                                  <option
                                    wrap="true"
                                    key={item.value}
                                    value={item.value}
                                  >
                                    {item.label}
                                  </option>
                                ))}
                              ;
                            </Dropdown>
                            {dropdown_supplierFilter > 0 ? (
                              <UncontrolledTooltip
                                autohide={false}
                                target={"shipVal"}
                                aria-haspopup="true"
                                role="example"
                                effect="solid"
                                placement="bottom"
                                style={{ width: "350px" }}
                              >
                                {supplierDataList != null &&
                                  supplierDataList.filter(
                                    (x) => x.value == dropdown_supplierFilter
                                  )[0]?.label}
                              </UncontrolledTooltip>
                            ) : null}
                          </div>

                          <div
                            className="filter-box filter-flex-item form-label"
                            id={"pono1"}
                          >
                            <Dropdown
                              dropdownLabel="PO Number:"
                              name="poNoFilter"
                              onChange={this.onFilterSelected}
                              selected={dropdown_poNoFilter}
                              style={{
                                textOverflow: "ellipsis",
                                whiteSpace: "pre",
                                overflow: "hidden",
                              }}
                            >
                              {poNumberList == null
                                ? null
                                : poNumberList.map((item) => (
                                  <option wrap="true" key={item} value={item}>
                                    {item}
                                  </option>
                                ))}
                              ;
                            </Dropdown>

                            {dropdown_poNoFilter != undefined &&
                              dropdown_poNoFilter != null &&
                              dropdown_poNoFilter != "" &&
                              dropdown_poNoFilter != 0 ? (
                              <UncontrolledTooltip
                                autohide={false}
                                target={"pono1"}
                                aria-haspopup="true"
                                role="example"
                                effect="solid"
                                placement="bottom"
                                style={{ width: "350px" }}
                              >
                                {poNumberList != null && dropdown_poNoFilter}
                              </UncontrolledTooltip>
                            ) : null}
                          </div>

                          <div
                            className="filter-box filter-flex-item form-label"
                            id={"remarkstatus"}
                          >
                            <Dropdown
                              dropdownLabel="Remark / Status:"
                              name="remarkFilter"
                              onChange={this.onFilterSelected}
                              selected={dropdown_remarkFilter}
                              style={{
                                textOverflow: "ellipsis",
                                whiteSpace: "pre",
                                overflow: "hidden",
                              }}
                            >
                              {statusDataList == null
                                ? null
                                : statusDataList.map((item) => (
                                  <option
                                    wrap="true"
                                    key={item.value}
                                    value={item.value}
                                  >
                                    {item.label}
                                  </option>
                                ))}
                              ;
                            </Dropdown>
                            {dropdown_remarkFilter != "" ? (
                              <UncontrolledTooltip
                                autohide={false}
                                target={"remarkstatus"}
                                aria-haspopup="true"
                                role="example"
                                effect="solid"
                                placement="bottom"
                                style={{ width: "350px" }}
                              >
                                {statusDataList != null &&
                                  statusDataList.filter(
                                    (x) => x.value == dropdown_remarkFilter
                                  )[0]?.label}
                              </UncontrolledTooltip>
                            ) : null}
                          </div>

                          <div
                            className="filter-box filter-flex-item form-label"
                            id={"hazmat1"}
                          >
                            <Dropdown
                              dropdownLabel="Hazmat Present:"
                              name="hazmatFilter"
                              onChange={this.onFilterSelected}
                              selected={dropdown_hazmatFilter}
                              style={{
                                textOverflow: "ellipsis",
                                whiteSpace: "pre",
                                overflow: "hidden",
                              }}
                            >
                              {hazmatPresentStatus == null
                                ? null
                                : hazmatPresentStatus.map((item, key) => (
                                  <option
                                    wrap="true"
                                    key={key}
                                    value={item.value}
                                  >
                                    {item.label}
                                  </option>
                                ))}
                              ;
                            </Dropdown>
                            {dropdown_hazmatFilter != "" ? (
                              <UncontrolledTooltip
                                autohide={false}
                                target={"hazmat1"}
                                aria-haspopup="true"
                                role="example"
                                effect="solid"
                                placement="bottom"
                                style={{ width: "350px" }}
                              >
                                {hazmatPresentStatus != null &&
                                  hazmatPresentStatus.filter(
                                    (x) => x.value == dropdown_hazmatFilter
                                  )[0]?.label}
                              </UncontrolledTooltip>
                            ) : null}
                          </div>

                          <div className="filter-box filter-flex-item">
                            <label
                              htmlFor="exampleDataList"
                              className="form-label"
                            >
                              Hazmat Table A:
                            </label>
                            <MultiSelectDropdown
                              name="tableAFilter"
                              selectedOptions={
                                tableAItem == undefined ? [] : tableAItem
                              }
                              options={
                                tableAList == undefined ? [] : tableAList
                              }
                              onChange={this.onTableADropdownChange}
                              isMulti={true}
                            />
                          </div>
                          <div className="filter-box filter-flex-item">
                            <label
                              htmlFor="exampleDataList"
                              className="form-label"
                            >
                              Hazmat Table B:
                            </label>
                            <MultiSelectDropdown
                              name="tableBFilter"
                              selectedOptions={
                                tableBItem == undefined ? [] : tableBItem
                              }
                              options={
                                tableBList == undefined ? [] : tableBList
                              }
                              onChange={this.onTableBDropdownChange}
                              isMulti={true}
                            />
                          </div>
                          <div className="filter-box clear-all-btn">
                            <button
                              type="button"
                              className="btn btn-blue-bg "
                              onClick={this.clearAllFilter}
                            >
                              Reset
                            </button>
                          </div>
                        </div>
                      </div>
                    </fieldset>
                  </div>
                </div>
              </div>

              <div className="tt-grid-icon-sec">
                <div className="row">
                  <div className="col-12 col-sm-3 col-lg-2">
                    <div className="import-po-list">
                      {!isClientOrClientUser && !isShipCrew ? (
                        <button
                          type="button"
                          disabled={
                            accessList != null
                              ? accessList.trackingToolAccess == UserAction.READ
                                ? true
                                : false
                              : false
                          }
                          className="btn btn-blue-bg "
                          onClick={this.importPoButtonClick}
                        >
                          Import PO List
                        </button>
                      ) : null}
                    </div>
                  </div>
                  <div className="col-12 col-sm-6 col-lg-6">
                    <div className="TT-filter-text">
                      <p>
                        {displayText == "" ? " IHMM Maintenance" : displayText}
                        {/* Year 1 / IHMM Maintenance Period 02-08-2021 - 02-08-2022 */}
                      </p>
                    </div>
                  </div>
                  <div className="col-12 col-sm-3 col-lg-4">
                    {userTypeId === UserRole.ADMIN || userTypeId === UserRole.INTERNALUSER || userTypeId === UserRole.ACCOUNTS ? (
                      <div className="grid-icon">
                        {userTypeId === UserRole.ADMIN ? (
                          <a
                            className="dropdown-toggle download-dropdown"
                            href="#"
                            id="downloadDropdown"
                            role="button"
                            data-bs-toggle="dropdown"
                            aria-expanded="false"
                            onClick={this.onDownloadAllTrackingToolClicked}
                          >
                            <img
                              src={downloadAllIcon}
                              alt="Download"
                              style={{ cursor: "pointer" }}
                              title="Download All Tracking Tool"
                              data-bs-toggle="tooltip"
                              data-bs-placement="bottom"
                            />
                          </a>) : ("")}

                        {userTypeId === UserRole.ADMIN || accessList != null &&
                          accessList.trackingToolAccess == UserAction.READWRITE ? (<a>
                            <img
                              src={helpInfo}
                              className="info"
                              data-bs-toggle="tooltip"
                              data-bs-placement="right"
                              title="Instructions to save Tracking Tool data"
                              onClick={this.onShowHelpContent}
                              alt={"Help"}
                              style={{ cursor: "pointer" }}
                            />
                          </a>
                        ) : (
                          ""
                        )}
                        {userTypeId === UserRole.ADMIN || accessList != null &&
                          accessList.trackingToolAccess == UserAction.READWRITE ? (<a>
                            <img
                              src={correctionIcon}
                              className="info"
                              data-bs-toggle="tooltip"
                              data-bs-placement="right"
                              title="Send multiple request for Md/SDoC"
                              onClick={this.onSendMultipleReminder}
                              alt={"Correction"}
                              style={{ cursor: "pointer" }}
                            />
                          </a>
                        ) : (
                          ""
                        )}
                        {userTypeId === UserRole.ADMIN || accessList != null &&
                          accessList.trackingToolAccess == UserAction.READWRITE &&
                          (clientDataList.length > 0 ||
                            shipDataList.length > 0) ? (
                          <a>
                            <img
                              src={addRowIcon}
                              alt="add"
                              className="add-document"
                              title="Add new row"
                              data-bs-toggle="tooltip"
                              data-bs-placement="bottom"
                              onClick={this.onAddRowClicked}
                              style={{ cursor: "pointer" }}
                            />
                          </a>
                        ) : (
                          ""
                        )}
                        {userTypeId === UserRole.ADMIN || accessList != null &&
                          accessList.trackingToolAccess == UserAction.READWRITE ? (
                          <span className="download-dropdown">
                            <a
                              className="dropdown-toggle download-dropdown"
                              href="#"
                              id="uploadDropdown"
                              role="button"
                              data-bs-toggle="dropdown"
                              aria-expanded="false"
                              alt="Upload"
                            >
                              <img
                                src={fileUploadIcon}
                                title="Upload MD/SDOC or Upload PO"
                                data-bs-placement="bottom"
                              />
                            </a>
                            <ul
                              className="dropdown-menu"
                              aria-labelledby="uploadDropdown"
                              style={{ cursor: "pointer" }}
                            >
                              <li>
                                <a
                                  className="dropdown-item"
                                  data-bs-toggle="modal"
                                  data-bs-target="#uploadMDSDoCDocument"
                                  onClick={() =>
                                    this.openUploadModal(
                                      "showMDSDoCUploadModal"
                                    )
                                  }
                                >
                                  Upload MD/SDoC
                                </a>
                              </li>
                              <li>
                                <a
                                  className="dropdown-item"
                                  data-bs-toggle="modal"
                                  data-bs-target="#uploadPOlist"
                                  onClick={() =>
                                    this.openUploadModal("showUploadModal")
                                  }
                                >
                                  Upload PO List
                                </a>
                              </li>
                            </ul>
                          </span>
                        ) : (
                          ""
                        )}

                        {/* <span className="download-dropdown"> */}
                        <a
                          className="dropdown-toggle download-dropdown"
                          href="#"
                          id="downloadDropdown"
                          role="button"
                          data-bs-toggle="dropdown"
                          aria-expanded="false"
                          onClick={this.onDownloadTrackingToolClicked}
                        >
                          <img
                            src={downloadIcon}
                            className={`${isBouncing ? 'bounce_button' : ''}`}
                            alt="Download"
                            style={{ cursor: "pointer" }}
                            title="Download Tracking Tool"
                            data-bs-toggle="tooltip"
                            data-bs-placement="bottom"
                          />
                        </a>
                        <a
                          data-bs-toggle="modal"
                          data-bs-target="#zoom-track-tool-Modal"
                          onClick={this.onZoomClicked}
                          style={{ cursor: "pointer" }}
                        >
                          <img
                            src={zoomIcon}
                            alt="Zoom"
                            title="Zoom"
                            data-bs-toggle="tooltip"
                            data-bs-placement="bottom"
                          />
                        </a>
                      </div>
                    ) : (
                      <div className="grid-icon">
                        {/* <span className="download-dropdown"> */}
                        <a
                          className="dropdown-toggle download-dropdown"
                          href="#"
                          id="downloadDropdown"
                          role="button"
                          data-bs-toggle="dropdown"
                          aria-expanded="false"
                          onClick={this.onDownloadTrackingToolClicked}
                        >
                          <img
                            src={downloadIcon}
                            className={`${isBouncing ? 'bounce_button' : ''}`}
                            style={{ cursor: "pointer" }}
                            alt="Download"
                            title="Download Tracking Tool"
                            data-bs-toggle="tooltip"
                            data-bs-placement="bottom"
                          />
                        </a>
                        <a
                          data-bs-toggle="modal"
                          data-bs-target="#zoom-track-tool-Modal"
                          onClick={this.onZoomClicked}
                          style={{ cursor: "pointer" }}
                        >
                          <img
                            src={zoomIcon}
                            alt="Zoom"
                            title="Zoom"
                            data-bs-toggle="tooltip"
                            data-bs-placement="bottom"
                          />
                        </a>
                      </div>
                    )}
                  </div>
                </div>
              </div>

              <div className="tracking-tool-grid">
                <div className="row">
                  <div className="col-md-12">
                    <div className="table-responsive">
                      {isAdminOrInternalUser ? (

                        <AGGrid
                          rowData={mappedTrackingTool}
                          colDefs={this.gridColumns()}
                          rowSelection="multiple"
                          pagination="100"
                          // pagination={false} // Disable AG-Grid's pagination
                          onScroll={this.onScroll}
                          frameworkComponents={frameworkComponents}
                          onGridReady={this.onGridReady}
                          onCellContextMenu={(e) => this.onCellClickedMenu(e)}
                          onSelectionChanged={(e) => this.onSelectionChanged(e)}
                          onCellClicked={this.onCellClicked}
                          onCellKeyPress={this.onCellKeyPress.bind(this)}
                          onOverlayClick={this.overlayImportPo}
                          onSortChanged={(e) => this.onSortChanged(e)}
                          autoHeight={true}
                          overlayNoRowsTemplate={
                            '<div> <i class="fa fa-file-text-o" style="font-size:48px;color:#1C3D74"></i> </br></br> <label style="text-decoration:underline;font-size:15px;color:#3769c1"> Click here to import PO list </label></div>'
                          }
                          showOverlay={true}
                          newDataCount={100}
                          lazyLoading={true}
                          hasMoreData={hasMoreData}
                          //resetPagesCallback={resetPagesInChild}
                          resetLoadedPages={hasMoreData}
                          totalCount={totalPOCount}
                          stateCount={count}
                        />
                      ) : (
                        <AGGrid
                          onGridReady={this.onGridReady}
                          rowData={mappedTrackingTool}
                          colDefs={colDefsClient}
                          rowSelection="multiple"
                          pagination="100"
                          newDataCount={100}
                          lazyLoading={true}
                          onScroll={this.onScroll}
                          frameworkComponents={frameworkComponents}
                          autoHeight={true}
                          onSelectionChanged={(e) => this.onSelectionChanged(e)}
                          onSortChanged={(e) => this.onSortChanged(e)}
                          hasMoreData={hasMoreData}
                          //resetPagesCallback={this.resetPagesInChild}
                          resetLoadedPages={hasMoreData}
                          totalCount={totalPOCount}
                          stateCount={count}
                        />
                      )}
                    </div>

                  </div><div style={{ textAlign: 'end' }}><b>Total PO Count:{totalPOCount}</b></div>
                </div>
              </div>

              <br />
            </div>
          </section>
        </main>

        <Footer />
      </>
    );
  }
}

export default withRouter(TrackingTool);
