import React, { Component } from "react";
import AGGrid from "../../components/AGGrid";
import Dropdown from "../../components/Dropdown";
import Footer from "../../components/Footer";
import Header from "../../components/Header";
import Loader from "../../components/Loader";
import MobileDropdown from "../../components/Mobile/MobileDropdown";
import MultiSelectDropdown from "../../components/MultiSelectDropdown";
import Searchbar from "../../components/Searchbar";
import ConfirmationModal from "../../components/ConfirmationModal";
import TagRenderer from "../../components/TagRenderer";
import Toast from "../../components/Toast";
import {
  AddLocalStorageData,
  GetLocalStorageDataWithParse,
  GetLocalStorageDataWithoutParse,
  changePageTitle,
  helperText,
  withRouter,
} from "../../helpers";
import Logout from "../../helpers/Logout";
import { TrackingToolService } from "../../services/trackingTool.service";
import AddAsHazmat from "./AddAsHazmat";
import ImportPOHistory from "./ImportPOHistory";
import UploadPOFile from "./UploadPOFile";
import ViewPOFile from "./ViewPOFile";
import ZoomImportPO from "./ZoomImportPO";
import ttDownloadIcon from "../../assets/images/ship-download-document.png";
import downloadIcon from "../../assets/images/admin-download.png";
import { TitlePageNames, UserRole  } from "../../helpers/enums";
import HelpModal from "../../components/HelpModal";
import { convertDate, isStringEmpty } from "../../helpers/string-helper";
import { UncontrolledTooltip } from "reactstrap";

const defaultAllItem = { label: "All", value: "0" };

class ImportPO extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isLoading: false,
      toastVisible: false,
      loggedInUserID: 0,
      toastResponseType: "",
      colDefs: this.gridColumns,
      colDefsView: this.gridColumnsView,
      colDefsUnreadable: this.gridColumnsUnreadable,
      rowData: [],
      defaultRowData: [],
      frameworkComponents: {
        tagRenderer: TagRenderer,
      },
      showUploadModal: false,
      showViewModal: false,
      showAddAsHazmatModal: false,
      suspectedHazmatItems: [],
      POItemsImportedCount: 0,
      suspectedHazmatItemsCount: 0,
      dropdownList: [],
      dropdownList_shipList_Filtered: [],
      dropdown_client: 0,
      dropdown_year: 0,
      zoomView: false,
      importHistory: [],
      importedPOFileId: 0,
      importedPOFilename: "",
      filteredShipList: [],
      userTypeId: 0,
      gridApi: null,
      helpContentModal: false,
      showOOCLApiImport: false,
      isAPIImport: false,
      showConfirmationModalOnDownloadApi: false,
      clientUserId: 0,      
      yearList: [],
    };

    this.onGridReady = this.onGridReady.bind(this);
  }

  onSortChanged(e) {
    e.api.refreshCells();
  }

  /* <summary>
    date: 15/11/2022
    Name: AS
    description: Bind AG grid columns on uploading readable file
    <summary> */

  gridColumns = [
    {
      headerName: "",
      field: "",
      width: 80,
      cellRenderer: (params) => (
        <TagRenderer
          path={
            params.data.fileType === undefined || params.data.fileType === null
              ? ""
              : params.data.fileType
          }
        />
      ),
      sortable: false,
      filter: false,
    },
    {
      headerName: "",
      field: "",
      sortable: false,
      filter: false,
      headerCheckboxSelection: true,
      checkboxSelection: true,
      width: 50,
      unSortIcon: false,
    },
    {
      headerName: "Serial Number",
      //field: "serialNumber",
      valueGetter: "node.rowIndex + 1",
      tooltipField: "serialNumber",
      width: 90,
      cellStyle: {
        wordwrap: "break-word",
        whitespace: "nowrap",
      },
      unSortIcon: false,
      sortable: false,
      filter: false,
    },
    {
      headerName: "Ship Name",
      field: "shipName",
      tooltipField: "shipName",
      cellStyle: {
        textOverflow: "ellipsis",
        whiteSpace: "pre",
        overflow: "hidden",
      },
    },
    {
      headerName: "PO Number",
      field: "pono",
      tooltipField: "pono",
    },
    {
      headerName: "PO Date",
      field: "podate",
      tooltipField: "podate",
    },
    {
      headerName: "Supplier Branch Name",
      field: "supplierBranchName",
      tooltipField: "supplierBranchName",
      cellStyle: {
        textOverflow: "ellipsis",
        whiteSpace: "pre",
        overflow: "hidden",
      },
      width: 200,
    },

    {
      headerName: "Supplier Email",
      field: "supplierEmail",
      tooltipField: "supplierEmail",
      cellStyle: {
        textOverflow: "ellipsis",
        whiteSpace: "pre",
        overflow: "hidden",
      },
    },
    {
      headerName: "Supplier Name",
      field: "supplierName",
      tooltipField: "supplierName",
      cellStyle: {
        textOverflow: "ellipsis",
        whiteSpace: "pre",
        overflow: "hidden",
      },
    },
    {
      headerName: "Description",
      field: "description",
      tooltipField: "description",
      cellStyle: {
        textOverflow: "ellipsis",
        whiteSpace: "pre",
        overflow: "hidden",
      },
    },
    {
      headerName: "Qty",
      field: "quantity",
      tooltipField: "quantity",
      width: 100,
    },
    {
      headerName: "Unit",
      field: "unit",
      tooltipField: "unit",
      width: 120,
    },
    {
      headerName: "Supplier Address",
      field: "supplierAddress",
      tooltipField: "supplierAddress",
      cellStyle: {
        textOverflow: "ellipsis",
        whiteSpace: "pre",
        overflow: "hidden",
      },
      editable: true,
    },
    {
      headerName: "Supplier Contact Number",
      field: "supplierContactNo",
      tooltipField: "supplierContactNo",
      editable: true,
    },
    {
      headerName: "Supplier Company Name",
      field: "supplierCompanyName",
      tooltipField: "supplierCompanyName",
      cellStyle: {
        textOverflow: "ellipsis",
        whiteSpace: "pre",
        overflow: "hidden",
      },
    },
    {
      headerName: "Part Name",
      field: "partName",
      tooltipField: "partName",
      cellStyle: {
        textOverflow: "ellipsis",
        whiteSpace: "pre",
        overflow: "hidden",
      },
      editable: true,
    },
    {
      headerName: "Sub Part Name",
      field: "subPartName",
      tooltipField: "subPartName",
      cellStyle: {
        textOverflow: "ellipsis",
        whiteSpace: "pre",
        overflow: "hidden",
      },
      editable: true,
    },
    {
      headerName: "Equipment Used",
      field: "equipmentUsed",
      tooltipField: "equipmentUsed",
      cellStyle: {
        textOverflow: "ellipsis",
        whiteSpace: "pre",
        overflow: "hidden",
      },
    },
    {
      headerName: "Part Maker Name",
      field: "partMakerName",
      tooltipField: "partMakerName",
      cellStyle: {
        textOverflow: "ellipsis",
        whiteSpace: "pre",
        overflow: "hidden",
      },
    },

    {
      headerName: "Line Item Number",
      field: "lineItemNo",
      tooltipField: "lineItemNo",
      cellStyle: {
        textOverflow: "ellipsis",
        whiteSpace: "pre",
        overflow: "hidden",
      },
    },
  ];

  /* <summary>
    date: 15/11/2022
    Name: AS
    description: Bind AG grid columns for viewing uploaded readable file
    <summary> */
  gridColumnsView = [
    {
      headerName: "Serial Number",
      // field: "serialNumber",
      valueGetter: "node.rowIndex + 1",
      tooltipField: "serialNumber",
      width: 90,
      cellStyle: {
        wordwrap: "break-word",
        whitespace: "nowrap",
      },
      unSortIcon: false,
      sortable: false,
      filter: false,
    },
    {
      headerName: "Ship Name",
      field: "shipName",
      tooltipField: "shipName",
      cellStyle: {
        textOverflow: "ellipsis",
        whiteSpace: "pre",
        overflow: "hidden",
      },
    },
    {
      headerName: "PO Number",
      field: "pono",
      tooltipField: "pono",
    },
    {
      headerName: "PO Date",
      field: "podate",
      tooltipField: "podate",
    },
    {
      headerName: "Supplier Branch Name",
      field: "supplierBranchName",
      tooltipField: "supplierBranchName",
      cellStyle: {
        textOverflow: "ellipsis",
        whiteSpace: "pre",
        overflow: "hidden",
      },
      width: 200,
    },
    {
      headerName: "Supplier Email",
      field: "supplierEmail",
      tooltipField: "supplierEmail",
      cellStyle: {
        textOverflow: "ellipsis",
        whiteSpace: "pre",
        overflow: "hidden",
      },
    },
    {
      headerName: "Supplier Name",
      field: "supplierName",
      tooltipField: "supplierName",
      cellStyle: {
        textOverflow: "ellipsis",
        whiteSpace: "pre",
        overflow: "hidden",
      },
    },
    {
      headerName: "Description",
      field: "description",
      tooltipField: "description",
      cellStyle: {
        textOverflow: "ellipsis",
        whiteSpace: "pre",
        overflow: "hidden",
      },
    },
    {
      headerName: "Qty",
      field: "quantity",
      tooltipField: "quantity",
      width: 100,
    },
    {
      headerName: "Unit",
      field: "unit",
      tooltipField: "unit",
      width: 120,
    },
    {
      headerName: "Supplier Address",
      field: "supplierAddress",
      tooltipField: "supplierAddress",
      cellStyle: {
        textOverflow: "ellipsis",
        whiteSpace: "pre",
        overflow: "hidden",
      },
      editable: true,
    },
    {
      headerName: "Supplier Contact Number",
      field: "supplierContactNo",
      tooltipField: "supplierContactNo",
      editable: true,
    },
    {
      headerName: "Supplier Company Name",
      field: "supplierCompanyName",
      tooltipField: "supplierCompanyName",
      cellStyle: {
        textOverflow: "ellipsis",
        whiteSpace: "pre",
        overflow: "hidden",
      },
    },
    {
      headerName: "Part Name",
      field: "partName",
      tooltipField: "partName",
      cellStyle: {
        textOverflow: "ellipsis",
        whiteSpace: "pre",
        overflow: "hidden",
      },
      editable: true,
    },
    {
      headerName: "Sub Part Name",
      field: "subPartName",
      tooltipField: "subPartName",
      cellStyle: {
        textOverflow: "ellipsis",
        whiteSpace: "pre",
        overflow: "hidden",
      },
      editable: true,
    },
    {
      headerName: "Equipment Used",
      field: "equipmentUsed",
      tooltipField: "equipmentUsed",
      cellStyle: {
        textOverflow: "ellipsis",
        whiteSpace: "pre",
        overflow: "hidden",
      },
    },
    {
      headerName: "Part Maker Name",
      field: "partMakerName",
      tooltipField: "partMakerName",
      cellStyle: {
        textOverflow: "ellipsis",
        whiteSpace: "pre",
        overflow: "hidden",
      },
    },
    {
      headerName: "Line Item Number",
      field: "lineItemNo",
      tooltipField: "lineItemNo",
      cellStyle: {
        textOverflow: "ellipsis",
        whiteSpace: "pre",
        overflow: "hidden",
      },
    },
  ];

  /* <summary>
    date: 18/11/2022
    Name: AS
    description: Bind AG grid columns for viewing history of unreadable file
    <summary> */
  gridColumnsUnreadable = [
    {
      headerName: "Serial Number",
      // field: "serialNumber",
      valueGetter: "node.rowIndex + 1",
      tooltipField: "serialNumber",
      width: 90,
      cellStyle: {
        wordwrap: "break-word",
        whitespace: "nowrap",
      },
      unSortIcon: false,
      sortable: false,
      filter: false,
    },
    {
      headerName: "Ship Name",
      field: "shipName",
      tooltipField: "shipName",
      cellStyle: {
        textOverflow: "ellipsis",
        whiteSpace: "pre",
        overflow: "hidden",
      },
    },
    {
      headerName: "PO Number",
      field: "pono",
      tooltipField: "pono",
    },
    {
      headerName: "PO Date",
      field: "podateToDisplay",
      tooltipField: "podateToDisplay",
    },
    {
      headerName: "File Name",
      field: "poFileName",
      tooltipField: "poFileName",
      cellStyle: {
        textOverflow: "ellipsis",
        whiteSpace: "pre",
        overflow: "hidden",
      },
      width: 400,
    },
    {
      headerName: "Number Of PO Items",
      field: "totalPoitem",
      tooltipField: "totalPoitem",
    },
    {
      headerName: "Total Suspected Hazmat Items",
      field: "totalSuspectedItem",
      tooltipField: "totalSuspectedItem",
    },
    {
      headerName: "",
      pinned: "right",
      filter: false,
      sortable: false,
      unSortIcon: false,
      width: 40,
      cellRenderer: (params) => (
        <img
          className="cursor-class"
          src={downloadIcon}
          title="Download"
          width="20px"
          style={{ pointerEvents: "all", cursor: "pointer" }}
          onClick={() => this.onDownloadUnreadablePOFileClick(params.data)}
        />
      ),
      cellStyle: { textAlign: "center", paddingInline: "0" },
    },
  ];

  /* <summary>
    date: 15/11/2022
    Name: AS
    description: method invoked when grid is ready
    <summary> */
  onGridReady = (params) => {
    this.setState({
      gridApi: params.api,
    });
    this.gridApi = params.api;
    this.gridColumnApi = params.columnApi;
  };

  componentDidMount() {
    //HV - 20/02/2023 - changing browser title name
    changePageTitle(TitlePageNames.IMPORTPO);
    var userData = GetLocalStorageDataWithParse("loggedInUser");
    if (userData != null) {
      this.setState(
        { loggedInUserID: userData.id, userTypeId: userData.typeId, clientUserId: userData.typeId == UserRole.CLIENTUSER ? userData.id : 0, },
        () => {
          this.getDropdownData();
        }
      );
    }
  }

  /* componentDidUpdate() {
    if (this.state.gridApi) {
      this.state.gridApi.paginationGoToPage(0);
    }
  } */

  /* <summary>
    date: 15/11/2022
    Name: AS
    description: Get dropdown data for Import PO screen
    <summary> */
  getDropdownData = async () => {
    this.setState({
      isLoading: true,
    });


    const responseDropdownYear = await TrackingToolService.GetAllTrackingToolFilters(
      this.state.userTypeId,
      this.state.loggedInUserID,
      this.state.clientUserId
    );
    if (responseDropdownYear.data != null && responseDropdownYear.data != undefined) {
      if (responseDropdownYear.status == 401) {
        Logout(this.props.navigate);
      } else if (responseDropdownYear.status == 200) {
        this.setState(
          {
            yearList: responseDropdownYear.data.year,
          },
        )
      }
    }

    var response = await TrackingToolService.GetImportPODropdownData(
      this.state.loggedInUserID
    );

    if (response != null && response != undefined && response.status == 200) {
      let selectedClient =
        GetLocalStorageDataWithoutParse("selectedShipTTool") != null
          ? GetLocalStorageDataWithParse("selectedShipTTool")
          : null;
      this.setState(
        {
          dropdownList: response.data,
          dropdownList_shipList_Filtered: response.data.shipList,
          dropdown_client:
            selectedClient != null
              ? selectedClient.clientId
              : response.data.clientList.length > 0
                ? response.data.clientList[0].value
                : 0,
          showOOCLApiImport: response.data.clientList.filter((x) => x.label == 'OOCL')[0] != null ? true : false,
        },
        () => {
          this.getImportHistory();
          this.filterShipDropdownBasisClient();
          this.filterYearDropdownBasisClient();
        }
      );
    } else if (response.status == 401) {
      Logout(this.props.navigate);
    } else {
      this.setState({
        toastVisible: true,
        toastMessage: helperText.global.somethingWentWrong,
        toastResponseType: helperText.global.toastError,
        isLoading: false,
      });
    }
  };

  /* <summary>
        date: 15/11/2022
        Name: AS
        description: method invoked for uploading readable/unreadable PO file
        <summary> */
  onUploadPOFile = async (data) => {
    const { loggedInUserID, dropdown_client, dropdown_year, userTypeId } =
      this.state;
    this.setState({
      showUploadModal: false,
      isLoading: true,
    });
    let userData = new FormData();
    if (data.dropdown_fileProperty == "readable") {
      userData.append("LoggedInUserID", loggedInUserID);
      userData.append("ClientID", dropdown_client);
      userData.append("Year", dropdown_year);
      userData.append("FileType", data.dropdown_filetype);
      userData.append("FileProperty", data.dropdown_fileProperty);
      userData.append("POFile", data.POFile);
      userData.append("UserTypeId", userTypeId);
      var response = await TrackingToolService.ImportPO(userData);
      if (response != null && response != undefined && response.status == 200) {
        if (
          response.data.incorrectDataMessage == "" ||
          response.data.incorrectDataMessage == null
        ) {
          let poData = response.data;
          let message = helperText.TrackingTool.POFileImportedSuccess;
          let toastType = helperText.global.toastSuccess;

          if (poData.incorrectSupplierDetailsExists == true) {
            message =
              message + helperText.TrackingTool.supplierDetailsPartiallyFilled;
            toastType = helperText.global.toastHtmlContent;
          }

          this.setState(
            {
              rowData: poData.pOMasterModels,
              defaultRowData: poData.pOMasterModels,
              importedPOFileId: response.data.importedPOFileId,
              importedPOFilename: response.data.importedPOFilename,
              POItemsImportedCount: response.data.pOMasterModels.length,
              filteredShipList: poData.filterPOShipList,
              toastVisible: true,
              toastMessage: message,
              toastResponseType: toastType,
              isLoading: false,
            },
            () => {
              this.selectHazmatItems();
            }
          );
        } else {
          this.setState({
            toastVisible: true,
            toastMessage: response.data.incorrectDataMessage,
            toastResponseType: helperText.global.toastError,
            isLoading: false,
          });
        }
      } else if (response.status == 401) {
        Logout(this.props.navigate);
      } else if (response.status == 404) {
        this.setState({
          toastVisible: true,
          toastMessage: helperText.TrackingTool.incorrectExcel, //importedFileShipMissing,
          toastResponseType: helperText.global.toastError,
          isLoading: false,
        });
      } else {
        this.setState({
          toastVisible: true,
          toastMessage: helperText.global.somethingWentWrong,
          toastResponseType: helperText.global.toastError,
          isLoading: false,
        });
      }
    } else {
      this.setState({
        rowData: [],
        defaultRowData: [],
        POItemsImportedCount: 0,
      });

      var poImportedFileIDMain = 0;
      for (var i = 0; i < data.unreadablePODataList.length; i++) {
        userData = new FormData();
        userData.append("LoggedInUserID", loggedInUserID);
        userData.append("ClientID", dropdown_client);
        userData.append("Year", dropdown_year);
        userData.append("FileType", data.dropdown_filetype);
        userData.append("FileProperty", data.dropdown_fileProperty);
        userData.append("POFile", data.unreadablePODataList[i].poFile);
        userData.append("POImportedFileIDMain", poImportedFileIDMain);
        userData.append("UserTypeId", userTypeId);

        var addData = {
          ShipId: data.unreadablePODataList[i].dropdown_modalShip,
          SupplierId: data.unreadablePODataList[i].dropdown_modalSupplier,
          TotalPoItem: data.unreadablePODataList[i].totalPOItems,
          TotalSuspectedItem:
            data.unreadablePODataList[i].totalSuspectedHazmatItems,
          Pono: data.unreadablePODataList[i].poNumber,
          Podate: new Date(data.unreadablePODataList[i].poDate),
          CustomFileName: data.unreadablePODataList[i].customFileName,
          POFileName: data.unreadablePODataList[i].poFileName,
          POFilePath: data.unreadablePODataList[i].poFilePath,
        };
        console.log(JSON.stringify(addData));
        userData.append("unreadablePODataList", JSON.stringify(addData));
        userData.append("PODate", convertDate(new Date(data.unreadablePODataList[i].poDate)));

        var response = await TrackingToolService.ImportPO(userData);
        if (
          response == null ||
          response == undefined ||
          response.status != 200
        ) {
          this.setState({
            toastVisible: true,
            toastMessage: helperText.global.somethingWentWrong,
            toastResponseType: helperText.global.toastError,
            isLoading: false,
          });
          return;
        } else {
          poImportedFileIDMain = response.data;
          this.setState({
            toastVisible: true,
            toastMessage: helperText.TrackingTool.POFileSavedSuccess,
            toastResponseType: helperText.global.toastSuccess,
            isLoading: false,
          });
        }
      }
      setTimeout(() => {
        this.props.navigate("/TrackingTool");
      }, 1000);

      // () => {
      //}
      // );
    }
  };

  /* <summary>
    date: 15/11/2022
    Name: AS
    description: method invoked when hazmat items are selected
    <summary> */
  //HV - 14/03/2023 - setting the count for selected hazmat item in shipfiltered list

  onSelectionChanged = (event) => {
    var { rowData, filteredShipList } = this.state;

    var selectedRows = event.api.getSelectedRows();
    const updatedRowData = rowData.map((item) => {
      if (selectedRows.some((t) => t.pomasterId == item.pomasterId)) {
        item.isSuspectedHazmat = true;
      } else item.isSuspectedHazmat = false;
      return item;
    });

    const updatedFilteredShipList = filteredShipList.map((item) => {
      let selectedShip = selectedRows.filter((t) => t.shipId == item.shipId);
      if (selectedShip) {
        item.suspectedHazmatItemsCount = selectedShip.length;
      }
      return item;
    });

    this.setState({
      rowData: rowData,
      suspectedHazmatItems: selectedRows,
      suspectedHazmatItemsCount: selectedRows.length,
      filteredShipList: filteredShipList,
    });
    //window.alert('selection changed, ' + rowCount + ' rows selected');
  };

  selectHazmatItems = () => {
    this.gridApi.forEachNode(function (node) {
      node.setSelected(node.data.isSuspectedHazmat === true);
    });

    var selectedNodes = this.gridApi.getSelectedNodes();
    var suspectedItems = [];
    selectedNodes.map((item) => {
      suspectedItems.push(item.data);
    });
    this.setState({
      suspectedHazmatItems: suspectedItems,
      suspectedHazmatItemsCount: suspectedItems.length,
    });
  };

  /* <summary>
        date: 15/11/2022
        Name: AS
        description: method invoked for confirming hazmat items
        <summary> */
  onConfirmClick = async () => {
    const {
      loggedInUserID,
      dropdown_year,
      suspectedHazmatItems,
      POItemsImportedCount,
      suspectedHazmatItemsCount,
      isAPIImport,
    } = this.state;
    this.setState({
      showAddAsHazmatModal: false,
      isLoading: true,
    });
    if (suspectedHazmatItemsCount > 0) {
      let userData = new FormData();
      userData.append("LoggedInUserID", loggedInUserID);
      userData.append("Year", dropdown_year);
      userData.append("SuspectedHazmat", JSON.stringify(suspectedHazmatItems));
      userData.append("TotalPOItems", POItemsImportedCount);
      userData.append("TotalSuspectedHazmatItems", suspectedHazmatItemsCount);
      userData.append("IsAPIImportedData", isAPIImport);

      var response = await TrackingToolService.SaveHazmatItemsToTrackingTool(
        userData
      );
      if (response != null && response != undefined && response.status == 200) {
        let dropdownData = {
          clientId: parseInt(this.state.dropdown_client, 10),
          shipId: 0,
        };

        //If saved from API Import Delete the existing entries
        if (this.state.isAPIImport) {
          await this.deleteOOCLApiData();
        }

        //HV - 21/02/2023 - saving selected clientid inorder to display same in tracking tool
        AddLocalStorageData("selectedShipTTool", JSON.stringify(dropdownData));
        this.setState(
          {
            rowData: [],
            defaultRowData: [],
            POItemsImportedCount: 0,
            suspectedHazmatItemsCount: 0,
            suspectedHazmatItems: [],
            toastVisible: true,
            toastMessage: helperText.TrackingTool.HazmatItemsImported,
            toastResponseType: helperText.global.toastSuccess,
          },
          () => {
            this.props.navigate("/TrackingTool");
          }
        );
      } else {
        this.setState({
          toastVisible: true,
          toastMessage: helperText.global.somethingWentWrong,
          toastResponseType: helperText.global.toastError,
          isLoading: false,
        });
      }
    } else {
      this.setState({
        toastVisible: true,
        toastMessage: helperText.TrackingTool.selectToAddHazard,
        toastResponseType: helperText.global.toastError,
        isLoading: false,
      });
    }
  };


  deleteOOCLApiData = async () => {
    const { userTypeId, loggedInUserID } = this.state;
    var response = await TrackingToolService.DeleteOOCLApiData();
    if (response != null && response != undefined && response.status == 200) {
      this.setState({
        toastVisible: true,
        toastMessage: helperText.global.apiDataDeletedSuccessfully,
        toastResponseType: helperText.global.toastSuccess,
      });
    } else {
      this.setState({
        toastVisible: true,
        toastMessage: helperText.global.somethingWentWrong,
        toastResponseType: helperText.global.toastError,
        isLoading: false,
      });
    }
  }

  onBackClick = () => {
    if (this.state.defaultRowData.length > 0) {
      this.setState({
        toastVisible: true,
        toastMessage: helperText.TrackingTool.addCurrentDataAsHazmat,
        toastResponseType: helperText.global.toastError,
      });
      return;
    } else {
      this.props.navigate("/TrackingTool");
    }
  };

  onSuspectedHazmatClick = () => {
    if (this.state.defaultRowData.length > 0) {
      this.setState({
        toastVisible: true,
        toastMessage: helperText.TrackingTool.addCurrentDataAsHazmat,
        toastResponseType: helperText.global.toastError,
      });
      return;
    } else {
      this.props.navigate("/AddSuspectedHazmat");
    }
  };

  onImportOOCLData = () => {
    if (this.state.defaultRowData.length > 0) {
      this.setState({
        toastVisible: true,
        toastMessage: helperText.TrackingTool.addCurrentDataAsHazmat,
        toastResponseType: helperText.global.toastError,
      });
      return;
    } else {
      this.getAllOOCLApiData();
    }
  };

  getAllOOCLApiData = async () => {
    const { userTypeId, loggedInUserID, dropdownList } = this.state;
    this.setState({ isLoading: true });
    var response = await TrackingToolService.GetAllOOCLApiData(loggedInUserID, userTypeId);
    if (response != null && response != undefined && response.status == 200) {
      if (
        response.data.incorrectDataMessage == "" ||
        response.data.incorrectDataMessage == null
      ) {
        let poData = response.data;
        let message = '';
        let toastType = '';

        if (poData.pOAPIMasterModels.length > 0) {
          message = helperText.TrackingTool.POFileImportedSuccess;
          toastType = helperText.global.toastSuccess;
        }
        else {
          message = helperText.TrackingTool.POFileImportedNoRecords;
          toastType = helperText.global.toastSuccess;
        }

        if (poData.incorrectSupplierDetailsExists == true) {
          message =
            message + helperText.TrackingTool.supplierDetailsPartiallyFilled;
          toastType = helperText.global.toastHtmlContent;
        }
        this.setState(
          {
            rowData: poData.pOAPIMasterModels,
            defaultRowData: poData.pOAPIMasterModels,
            importedPOFileId: poData.importedPOFileId,
            importedPOFilename: poData.importedPOFilename,
            POItemsImportedCount: poData.pOAPIMasterModels.length,
            filteredShipList: poData.filterPOShipList,
            toastVisible: true,
            toastMessage: message,
            toastResponseType: toastType,
            isAPIImport: true,
            isLoading: false,
            dropdown_client: dropdownList.clientList.filter((x) => x.label == 'OOCL')[0].value,
          },
          () => {
            this.selectHazmatItems();
          }
        );
      } else {
        this.setState({
          toastVisible: true,
          toastMessage: response.data.incorrectDataMessage,
          toastResponseType: helperText.global.toastError,
          isLoading: false,
        });
      }
    } else {
      this.setState({
        toastVisible: true,
        toastMessage: helperText.global.somethingWentWrong,
        toastResponseType: helperText.global.toastError,
        isLoading: false,
      });
    }
  }


  /* <summary>
    date: 22/11/2022
    Name: AS
    description: method invoked for getting history data
    <summary> */
  getImportHistory = async () => {
    const { dropdown_client, loggedInUserID } = this.state;

    var response = await TrackingToolService.GetImportedFilesData(
      dropdown_client,
      loggedInUserID
    );
    if (response != null && response != undefined && response.status == 200) {
      this.setState({
        importHistory: response.data,
        isLoading: false,
      });
    } else {
      this.setState({
        toastVisible: true,
        toastMessage: helperText.global.somethingWentWrong,
        toastResponseType: helperText.global.toastError,
        isLoading: false,
      });
    }
  };

  onDropdownSelect = (e) => {
    var { name, value } = e.target;
    const { defaultRowData } = this.state;

    if (name == "dropdown_client" || name == "dropdown_year") {
      if (defaultRowData.length > 0) {
        this.setState({
          toastVisible: true,
          toastMessage: helperText.TrackingTool.addCurrentDataAsHazmat,
          toastResponseType: helperText.global.toastError,
        });
        return;
      }
    }
    this.setState(
      {
        [name]: value,
      },
      () => {
        if (name == "dropdown_client") {
          this.setState({ isLoading: true }, () => {
            this.getImportHistory();
            this.filterShipDropdownBasisClient();
            this.filterYearDropdownBasisClient();
          });
        }
      }
    );
  };

  filterShipDropdownBasisClient = () => {
    var { dropdown_client, dropdown_ship, dropdownList } = this.state;
    var dropdownShipList = dropdownList.shipList;

    if (dropdownShipList.length > 0) {
      dropdownShipList = dropdownShipList.filter(
        (e) => e.parentValue == dropdown_client
      );
      dropdownShipList.unshift(defaultAllItem);

      if (dropdown_client == "0")
        dropdown_ship = dropdownShipList.filter(
          (e) => e.parentValue == dropdown_client
        )[0];
      else dropdown_ship = dropdownShipList;

      this.setState({
        dropdownList_shipList_Filtered: dropdownShipList,
        dropdown_ship: dropdown_ship,
      });
    }
  };

  filterYearDropdownBasisClient = () => {
    var { dropdown_client, dropdown_year } = this.state;
    var dropdownYearList =
      this.state.dropdownList.year == undefined
        ? []
        : this.state.dropdownList.year;
    if (dropdownYearList.length > 0) {
      dropdownYearList = dropdownYearList
        .filter((e) => e.parentValue == dropdown_client)
        ?.sort((a, b) => a.year - b.year)
        ?.reverse();

      if (dropdownYearList.length > 0 && dropdown_year != undefined)
        dropdown_year = dropdownYearList.filter(
          (e) => e.parentValue == dropdown_client
        )[0].value;
      else dropdown_year = 0;
      this.setState({
        dropdownList_year_Filtered: dropdownYearList,
        dropdown_year,
        isLoading: false,
      });
    }
  };

  onShipDropdownChange = (option) => {
    var dropdown_ship = [];
    var rowData = this.state.defaultRowData;
    var filter_rowData = [];

    if (option.length > 0) {
      option
        // .filter((t) => t.value != "0")
        .forEach((item) => {
          dropdown_ship.push(item);
        });
      if (option.some((t) => t.value == "0")) {
        filter_rowData = rowData;
      } else {
        option
          .filter((t) => t.value != "0")
          .forEach((item) => {
            var rowDataList = rowData.filter((t) => t.shipId == item.value);
            rowDataList.forEach((item) => {
              filter_rowData.push(item);
            });
          });
      }
    }

    this.setState({ dropdown_ship, rowData: filter_rowData });
  };

  onSearch = (e) => {
    var { name, value } = e.target;
    var poItemsList = this.state.defaultRowData;
    if (poItemsList.length == 0 || value == null || value == "") return;
    this.setState(
      {
        [name]: value,
      },
      () => {
        value = value.toString().toLowerCase();
        poItemsList = poItemsList.filter(
          (t) =>
            t.description.toString().toLowerCase().includes(value) ||
            t.equipmentUsed.toString().toLowerCase().includes(value) ||
            t.lineItemNo.toString().includes(value) ||
            t.partMakerName.toString().toLowerCase().includes(value) ||
            t.partName.toString().toLowerCase().includes(value) ||
            t.podate.toString().includes(value) ||
            t.pono.toString().includes(value) ||
            t.quantity.toString().includes(value) ||
            t.shipName.toString().toLowerCase().includes(value) ||
            t.subPartName.toString().toLowerCase().includes(value) ||
            t.supplierAddress.toString().toLowerCase().includes(value) ||
            t.supplierBranchName.toString().toLowerCase().includes(value) ||
            t.supplierCompanyName.toString().toLowerCase().includes(value) ||
            t.supplierContactNo.toString().includes(value) ||
            t.supplierEmail.toString().toLowerCase().includes(value) ||
            t.supplierName.toString().toLowerCase().includes(value) ||
            t.unit.toString().toLowerCase().includes(value)
        );

        this.setState({
          rowData: poItemsList,
        });
      }
    );
  };

  closeToast = () => {
    this.setState({ toastVisible: false });
  };

  //HV - 14/03/2023 - handled to show toast message if client or year is not selected

  toggleModal = (name, value) => {
    const { defaultRowData, dropdown_client, dropdown_year } = this.state;
    if (name == "showUploadModal" && value == true) {
      let clientId =
        dropdown_client == null ? 0 : parseInt(dropdown_client, 10);
      let yearId = dropdown_year == null ? 0 : parseInt(dropdown_year, 10);

      if (clientId == 0 || yearId == 0) {
        this.setState({
          toastVisible: true,
          toastMessage: helperText.TrackingTool.selectClientAndYear,
          toastResponseType: helperText.global.toastError,
        });
        return;
      }

      if (defaultRowData.length > 0) {
        this.setState({
          toastVisible: true,
          toastMessage: helperText.TrackingTool.addCurrentDataAsHazmat,
          toastResponseType: helperText.global.toastError,
        });
        return;
      }
    }
    this.setState({ [name]: value });
  };

  toggleZoom = (name, value) => {
    this.setState({ zoomView: value });
  };

  downloadTrackingToolTemplate = async () => {
    try {
      this.setState({
        isLoading: true,
      });

      const response = await TrackingToolService.DownloadTrackingToolTemplate();
      if (response.status == 200) {
        response.data.forEach((element) => {
          var fname = "";
          var lastItem = element.split("/").pop();
          fname = lastItem;
          const link = document.createElement("a");
          link.href = element;
          link.setAttribute("download", element);
          document.body.appendChild(link);
          link.download = fname;
          link.click();
        });

        this.setState({
          toastVisible: true,
          toastResponseType: helperText.global.toastSuccess,
          toastMessage: helperText.global.downloadSuccess,
        });
      } else {
        this.setState({
          toastVisible: true,
          toastResponseType: helperText.global.toastError,
          toastMessage: helperText.global.somethingWentWrong,
        });
      }

      this.setState({
        isLoading: false,
      });
    } catch (error) { }
  };

  /* <summary>
    date: 28/11/2022
    Name: AS
    description: method invoked for downloading PO file
    <summary> */
  //HV - 16/02/2023 - PO file was not getting download handled it
  onDownloadPOClick = async (importedPOFileId) => {
    this.setState({
      isLoading: true,
    });
    var response = await TrackingToolService.GetPOFileDetails(importedPOFileId);
    if (response != null && response != undefined && response.status == 200) {
      if (response.data.poFileName != null) {
        var path = response.data.poFilePath;
        var fname = response.data.poFileName;
        var fileType = response.data.poFileType;

        const link = document.createElement("a");
        link.setAttribute("href", path);
        link.setAttribute("download", path);

        if (fileType == "pdf" || fileType == "PDF") {
          link.setAttribute("target", "_blank");
        } else {
          link.setAttribute("target", "");
        }
        document.body.appendChild(link);
        link.download = fname;
        link.click();

        this.setState({
          toastVisible: true,
          toastResponseType: helperText.global.toastSuccess,
          toastMessage: helperText.global.downloadSuccess,
          isLoading: false,
        });
      } else {
        this.setState({
          toastVisible: true,
          toastMessage: helperText.global.somethingWentWrong,
          toastResponseType: helperText.global.toastError,
          isLoading: false,
        });
      }
    } else if (response.status == 401) {
      Logout(this.props.navigate);
    } else {
      this.setState({
        toastVisible: true,
        toastMessage: helperText.global.somethingWentWrong,
        toastResponseType: helperText.global.toastError,
        isLoading: false,
      });
    }
  };

  onDownloadApiClicked = async () => {
    const { rowData, dropdown_client, dropdownList } =
      this.state;

    if (rowData.length > 0) {
      this.setState({
        isLoading: true,
      });
      let apiImportData = new FormData();

      let fileName = "";

      if (dropdown_client != null && dropdown_client != 0) {
        fileName = dropdownList.clientList.filter((x) => x.value == dropdown_client
        )[0]?.label
      }

      fileName = fileName + "_API_TrackingTool.xlsx";

      apiImportData.append(
        "ApiImportList",
        JSON.stringify(rowData)
      );
      apiImportData.append("ApiFileName", fileName);
      var response = await TrackingToolService.DownloadApiImport(
        apiImportData
      );

      if (response.status == 200 && response.data != null && response.data != "") {
        const url = window.URL.createObjectURL(new Blob([response.data]));

        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", fileName);
        document.body.appendChild(link);
        link.click();

        await this.deleteOOCLApiData();

        this.setState({
          rowData: [],
          defaultRowData: [],
          POItemsImportedCount: 0,
          suspectedHazmatItemsCount: 0,
          suspectedHazmatItems: [],
          showConfirmationModalOnDownloadApi: false,
          toastVisible: true,
          toastResponseType: helperText.global.toastSuccess,
          toastMessage: helperText.global.downloadSuccess,
          isLoading: false,
        });
      } else if (response.status == 401) {
        Logout(this.props.navigate);
      } else {
        this.setState({
          toastVisible: true,
          toastMessage: helperText.global.somethingWentWrong,
          toastResponseType: helperText.global.toastError,
          isLoading: false,
        });
      }
    } else {
      this.setState({
        toastVisible: true,
        toastMessage: helperText.TrackingTool.trackingToolEmpty,
        toastResponseType: helperText.global.toastError,
        isLoading: false,
      });
    }
  };


  /* <summary>
    date: 21/04/2023
    Name: HV
    description: method for downloading readable po file
    <summary> */

  onDownloadPOReadableFilesClick = async (poFileName, importHistoryList) => {
    this.setState({
      isLoading: true,
    });

    let importHistoryData = new FormData();
    importHistoryData.append(
      "POMasterModelList",
      JSON.stringify(importHistoryList)
    );
    importHistoryData.append("poFileName", poFileName);

    var response = await TrackingToolService.DownloadPOReadableFiles(
      importHistoryData
    );

    if (response.status == 200) {
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", poFileName);
      document.body.appendChild(link);
      link.click();

      this.setState({
        toastVisible: true,
        toastResponseType: helperText.global.toastSuccess,
        toastMessage: helperText.global.downloadSuccess,
        isLoading: false,
      });
    } else if (response.status == 401) {
      Logout(this.props.navigate);
    } else {
      this.setState({
        toastVisible: true,
        toastMessage: helperText.global.somethingWentWrong,
        toastResponseType: helperText.global.toastError,
        isLoading: false,
      });
    }
  };

  /* <summary>
   date: 16/02/2023
   Name: HV
   description: method for downloading unreadable PO file
   <summary> */
  onDownloadUnreadablePOFileClick = async (data) => {
    if (data.poFileName != null) {
      var path = data.poFilePath;
      var fname = data.poFileName;
      var fileType = data.fileType;

      const link = document.createElement("a");
      link.setAttribute("href", path);
      link.setAttribute("download", path);

      if (fileType == "pdf" || fileType == "PDF") {
        link.setAttribute("target", "_blank");
      } else {
        link.setAttribute("target", "");
      }
      document.body.appendChild(link);
      link.download = fname;
      link.click();

      this.setState({
        toastVisible: true,
        toastResponseType: helperText.global.toastSuccess,
        toastMessage: helperText.global.downloadSuccess,
      });
    } else {
      this.setState({
        toastVisible: true,
        toastMessage: helperText.global.somethingWentWrong,
        toastResponseType: helperText.global.toastError,
      });
    }
  };

  onModalCloseClick = () => {
    this.setState({
      helpContentModal: false,
      showConfirmationModalOnDownloadApi: false,
    });
  };

  onShowHelpContent = () => {
    this.setState({
      helpContentModal: true,
    });
  };

  /* <summary>
     date: 13/02/2023
     Name: HV
     description: clearing filter
     <summary> */

  clearAllFilter = () => {
    document.getElementById("searchQueryInput").value = "";
    this.setState(
      {
        dropdown_year: "0",
        rowData: this.state.defaultRowData,
      },
      () => {
        this.getImportHistory();
        this.filterShipDropdownBasisClient();
        this.filterYearDropdownBasisClient();
      }
    );
  };

  render() {
    const {
      isLoading,
      toastVisible,
      zoomView,
      toastResponseType,
      toastMessage,
      dropdownList,
      colDefs,
      colDefsView,
      colDefsUnreadable,
      rowData,
      showUploadModal,
      showHistoryModal,
      showViewModal,
      showAddAsHazmatModal,
      frameworkComponents,
      suspectedHazmatItemsCount,
      POItemsImportedCount,
      dropdownList_shipList_Filtered,
      dropdown_ship,
      dropdown_client,
      dropdown_year,
      dropdownList_year_Filtered,
      importHistory,
      importedPOFileId,
      importedPOFilename,
      filteredShipList,
      helpContentModal,
      showOOCLApiImport,
      isAPIImport,
      showConfirmationModalOnDownloadApi,
      yearList
    } = this.state;
    return (
      <>
        {isLoading && <Loader></Loader>}
        <Header />
        {toastVisible && (
          <Toast
            toastVisible={toastVisible}
            type={toastResponseType}
            message={toastMessage}
            closeToast={this.closeToast}
          />
        )}
        <main className="page-height">
          <section className="communications po-items-list">
            <div className="container">
              <div className="row">
                <div className="col-12 col-md-4 col-lg-8 heading-bottom-space">
                  <img
                    className="back-btn-responsive manage-client-back-button cursor-class"
                    onClick={this.onBackClick}
                    src="images/back button image.png"
                    alt="Back"
                  />
                  <span className="title-font">PO Items Data</span>
                </div>
                <div className="col-12 col-md-8 col-lg-4">
                  <Searchbar
                    placeholder="PO Number, Supplier Name, Item etc..."
                    onChange={this.onSearch}
                  />
                </div>
              </div>
              <div className="row mt-row">
                <div className="accordion-filter-dropdown">
                  <div className="accordion" id="myAccordion">
                    <div className="accordion-item">
                      <h2 className="accordion-header" id="headingTwo">
                        <button
                          type="button"
                          className="accordion-button collapsed filter-toggle-btn"
                          data-bs-toggle="collapse"
                          data-bs-target="#collapseOne"
                        >
                          Filter By
                        </button>
                      </h2>
                      <div
                        id="collapseOne"
                        className="accordion-collapse collapse"
                        data-bs-parent="#myAccordion"
                      >
                        <div className="card-body filter-dropdwn-body">
                          <div className="form-group dropdown-form form-drpdown-responsive admin-client-form">
                            <MobileDropdown
                              dropdownLabel="Client Name:"
                              name="client"
                              selected={dropdown_client}
                            >
                              {dropdownList.clientList == null
                                ? null
                                : dropdownList.clientList.map((obj, key) => (
                                  <option key={key} value={obj.value}>
                                    {obj.label}
                                  </option>
                                ))}
                              ;
                            </MobileDropdown>
                          </div>
                          <div className="form-group dropdown-form admin-client-form">
                            <MobileDropdown
                              dropdownLabel="Ship Name:"
                              name="ship"
                              selected={dropdown_ship}
                            >
                              {dropdownList.shipList == null
                                ? null
                                : dropdownList.shipList.map((obj, key) => (
                                  <option key={key} value={obj.value}>
                                    {obj.label}
                                  </option>
                                ))}
                              ;
                            </MobileDropdown>
                          </div>
                          <div className="form-group dropdown-form form-drpdown-responsive admin-client-form"></div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="desktop-class">
                <div className="row mt-row">
                  <div className="col-12 col-md-4 col-lg-3">
                    <div
                      className="form-group dropdown-form form-drpdown-responsive admin-client-form"
                      id={"clientName"}
                    >
                      <Dropdown
                        dropdownLabel="Client Name:"
                        name="client"
                        onChange={this.onDropdownSelect}
                        selected={dropdown_client}
                        style={{
                          textOverflow: "ellipsis",
                          whiteSpace: "pre",
                          overflow: "hidden",
                        }}
                      >
                        {dropdownList.clientList == null
                          ? null
                          : dropdownList.clientList.map((obj, key) => (
                            <option key={key} value={obj.value}>
                              {obj.label}
                            </option>
                          ))}
                        ;
                      </Dropdown>
                      {dropdown_client > 0 ? (
                        <UncontrolledTooltip
                          autohide={false}
                          target={"clientName"}
                          aria-haspopup="true"
                          role="example"
                          effect="solid"
                          placement="bottom"
                          style={{ width: "350px" }}
                        >
                          {dropdownList.clientList != null &&
                            dropdownList.clientList.filter(
                              (x) => x.value == dropdown_client
                            )[0]?.label}
                        </UncontrolledTooltip>
                      ) : null}
                    </div>
                  </div>
                  <div className="col-12 col-md-4 col-lg-3">
                    <div className="form-group dropdown-form form-drpdown-responsive admin-client-form">
                      <label className="label-class">Ship Name:</label>
                      <MultiSelectDropdown
                        selectedOptions={
                          dropdown_ship == undefined ? [] : dropdown_ship
                        }
                        options={
                          dropdownList_shipList_Filtered == undefined
                            ? []
                            : dropdownList_shipList_Filtered
                        }
                        onChange={this.onShipDropdownChange}
                        isMulti={true}
                      />
                    </div>
                  </div>
                  <div className="col-12 col-md-4 col-lg-3">
                    <div
                      className="form-group dropdown-form form-drpdown-responsive admin-client-form"
                      id={"year"}
                    >
                      <Dropdown
                        dropdownLabel="Year:"
                        name="year"
                        onChange={this.onDropdownSelect}
                        selected={dropdown_year}
                        style={{
                          textOverflow: "ellipsis",
                          whiteSpace: "pre",
                          overflow: "hidden",
                        }}
                      >
                        {dropdownList_year_Filtered == null
                          ? null
                          : dropdownList_year_Filtered.map((obj, key) => (
                            <option key={key} value={obj.value}>
                              {obj.label}
                            </option>
                          ))}
                        ;
                      </Dropdown>
                      {dropdown_year > 0 ? (
                        <UncontrolledTooltip
                          autohide={false}
                          target={"year"}
                          aria-haspopup="true"
                          role="example"
                          effect="solid"
                          placement="bottom"
                          style={{ width: "350px" }}
                        >
                          {dropdownList_year_Filtered != null &&
                            dropdownList_year_Filtered.filter(
                              (x) => x.value == dropdown_year
                            )[0]?.label}
                        </UncontrolledTooltip>
                      ) : null}
                    </div>
                  </div>
                  <div
                    className="col-12 col-md-4 col-lg-3 "
                    style={{ alignSelf: "center" }}
                  >
                    <button
                      type="button"
                      className="btn btn-blue-bg"
                      onClick={this.clearAllFilter}
                      style={{ marginTop: 24 }}
                    >
                      Reset
                    </button>
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-12 btn-top-space">
                  <div className="manage-client-button">
                    <button
                      type="button"
                      className="btn btn-background-color btn-padding view-polist-btn btn-responsive add-user-responsive manage-add-client"
                      onClick={() => this.toggleModal("showUploadModal", true)}
                    >
                      Upload PDF / Excel
                    </button>
                    {showOOCLApiImport && (
                      <button
                        type="button"
                        className="btn btn-background-color btn-padding btn-responsive view-polist-btn add-user-responsive manage-add-client"
                        onClick={() => this.onImportOOCLData()}
                      >
                        OOCL API Import
                      </button>
                    )}

                    <button
                      type="button"
                      className="btn btn-background-color btn-padding btn-responsive view-polist-btn add-user-responsive manage-add-client"
                    >
                      Amos API Import
                    </button>
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-md-7 btn-top-space polist-tooltip">
                  <img
                    src="images/info.png"
                    alt="info"
                    style={{
                      cursor: "pointer",
                    }}
                    className="info"
                    data-bs-toggle="tooltip"
                    data-bs-placement="right"
                    title="The items you select will be listed under tracking tool"
                    onClick={() => this.onShowHelpContent()}
                  />
                </div>
                <div className="col-12 col-md-5 btn-top-space">
                  <div className="float-btn manage-client-button">
                    <h5 className="totalpoend">
                      Total PO Items:<span>{POItemsImportedCount}</span>
                    </h5>
                    <button
                      type="button"
                      style={{
                        cursor: POItemsImportedCount == 0 ? "arrow" : "pointer",
                      }}
                      disabled={POItemsImportedCount == 0}
                      className="btn btn-background-color view-history-btn btn-padding btn-responsive add-user-responsive manage-add-client"
                      onClick={() => this.toggleModal("showViewModal", true)}
                    >
                      View PO File
                    </button>

                    <button
                      type="button"
                      style={{
                        cursor: "pointer",
                      }}
                      className="btn btn-background-color view-history-btn btn-padding btn-responsive add-user-responsive manage-add-client"
                      onClick={() => this.onSuspectedHazmatClick()}
                    >
                      Suspected Hazmats
                    </button>

                    <img
                      src={ttDownloadIcon}
                      alt="Download"
                      style={{ cursor: "pointer", width: "28px" }}
                      className="maxi"
                      title="Download Tracking Tool Template"
                      data-bs-toggle="tooltip"
                      onClick={() => this.downloadTrackingToolTemplate()}
                    />

                    <img
                      src="images/maximize.png"
                      alt="maximize"
                      title="Zoom"
                      className="maxi"
                      style={{ cursor: "pointer", width: "30px" }}
                      onClick={() => this.toggleZoom("zoomView", true)}
                    />
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-md-12 table-left-padding table-pl-responsive">
                  <div className="table-responsive">
                    <AGGrid
                      rowData={rowData}
                      colDefs={colDefs}
                      rowSelection="multiple"
                      pagination="10"
                      frameworkComponents={frameworkComponents}
                      rowMultiSelectWithClick={true}
                      onGridReady={this.onGridReady}
                      autoHeight={true}
                      onSelectionChanged={this.onSelectionChanged.bind(this)}
                      onSortChanged={(e) => this.onSortChanged(e)}
                    />
                  </div>
                </div>
                {(isAPIImport && suspectedHazmatItemsCount == 0) ?
                  (<div className="col-10 btn-top-space">
                    <div className="float-btn manage-client-button">
                      <button
                        type="button"
                        className="btn btn-background-color view-history-btn btn-padding btn-responsive add-user-responsive manage-add-client"
                        onClick={() =>
                          this.toggleModal("showConfirmationModalOnDownloadApi", true)
                        }
                        style={{
                          cursor: rowData.length == 0 ? "arrow" : "pointer",
                        }}
                      >
                        Download API Data
                      </button>
                    </div>
                  </div>) : (<div className="col-10 btn-top-space"></div>)}
                <div className="col-2 btn-top-space">
                  <div className="float-btn manage-client-button">
                    <button
                      type="button"
                      className="btn btn-background-color view-history-btn btn-padding btn-responsive add-user-responsive manage-add-client"
                      onClick={() =>
                        this.toggleModal("showAddAsHazmatModal", true)
                      }
                      style={{
                        cursor: rowData.length == 0 ? "arrow" : "pointer",
                      }}
                      disabled={rowData.length == 0}
                    >
                      Add as Hazmat
                    </button>
                  </div>
                </div>
              </div>
              <ImportPOHistory
                importHistory={importHistory}
                colDefs={colDefsView}
                colDefsUnreadable={colDefsUnreadable}
                onDownloadPOReadableFilesClick={
                  this.onDownloadPOReadableFilesClick
                }
              />
            </div>
          </section>
        </main>
        {showUploadModal && (
          <UploadPOFile
            onCloseClick={this.toggleModal}
            onUploadClick={this.onUploadPOFile}
            dropdownList={dropdownList}
            clientId={dropdown_client}
            yearFilter={dropdown_year}
            yearList={yearList}
          />
        )}

        {showConfirmationModalOnDownloadApi && (
          <ConfirmationModal
            onModalCloseClick={this.onModalCloseClick}
            deleteIHMDocData={this.onDownloadApiClicked}
            modalText={"download? By clicking Yes, the file will be downloaded to your system, and the data from the API will be deleted."}
          />
        )}

        {helpContentModal && (
          <HelpModal
            helpContentModal
            isPOInstructions={true}
            onOkClick={this.onModalCloseClick}
          />
        )}
        <ViewPOFile
          showModal={showViewModal}
          colDefs={colDefsView}
          rowData={rowData}
          importedPOFileId={importedPOFileId}
          importedPOFilename={importedPOFilename}
          onCloseClick={this.toggleModal}
          onDownloadPOClick={this.onDownloadPOClick}
        />
        {showAddAsHazmatModal && (
          <AddAsHazmat
            showModal={showAddAsHazmatModal}
            isAPIImport={isAPIImport}
            suspectedHazmatItemsCount={suspectedHazmatItemsCount}
            POItemsImportedCount={POItemsImportedCount}
            onConfirmClick={this.onConfirmClick}
            onCloseClick={this.toggleModal}
            filteredShipList={filteredShipList}
          />
        )}
        <ZoomImportPO
          rowData={rowData}
          colDefs={colDefs}
          zoomView={zoomView}
          rowSelection="multiple"
          frameworkComponents={frameworkComponents}
          rowMultiSelectWithClick={true}
          onGridReady={this.onGridReady}
          onSelectionChanged={this.onSelectionChanged}
          //selectHazmatItems={this.selectHazmatItems}
          closeModal={this.toggleZoom}
        />

        <Footer />
      </>
    );
  }
}

export default withRouter(ImportPO);
