import React from "react";
import AGGrid from "../../components/AGGrid";
import correctionIcon from "../../assets/images/correction-icon.png";
import addRowIcon from "../../assets/images/add.png";
import fileUploadIcon from "../../assets/images/tt-file-upload.png";
import downloadIcon from "../../assets/images/ship-download-document.png";
import modalCloseIcon from "../../assets/images/modal-close-blue.png";
import { UserAction } from "../../helpers/enums";
import helpInfo from "../../assets/images/helpInfo.png";
import downloadAllIcon from "../../assets/images/download-all.png";

const ZoomTrackingTool = ({
  showZoom,
  mappedTrackingTool,
  colDefs,
  accessList,
  onAddRow,
  onSendReminder,
  onOpenSubModal,
  onCellKeyPress,
  onSelectionChanged,
  onCellClickedMenu,
  onGridReady,
  onCloseZoomClicked,
  isClientOrClientUser,
  isShipCrew,
  userRolePermissionRead,
  onDownloadTrackingToolClicked,
  onDownloadAllTrackingToolClicked,
  onShowHelpContent
}) => {
  return (
    <section>
      <div className="zoom-tracking-tool tracking-tool-grid">
        <div
          className="modal-backdrop fade show"
          style={{ display: showZoom ? "block" : "none", zIndex: "998" }}
        ></div>
        <div
          className="modal"
          id="zoom-track-tool-Modal"
          tabIndex="-1"
          style={{ display: showZoom ? "block" : "none", zIndex: "999" }}
          aria-labelledby="exampleModalLabel"
          aria-hidden="true"
        >
          <div className="modal-dialog modal-xl">
            <div className="modal-content">
              <div className="modal-body">
                <div className="top-container">
                  {!isClientOrClientUser && !isShipCrew && !userRolePermissionRead ? (
                    accessList != null &&
                      accessList.trackingToolAccess == UserAction.READ ? (
                      ""
                    ) : (
                      <span>
                        <a
                          className="dropdown-toggle download-dropdown"
                          href="#"
                          id="downloadDropdown"
                          role="button"
                          data-bs-toggle="dropdown"
                          aria-expanded="false"
                          onClick={onDownloadAllTrackingToolClicked}
                        >
                          <img
                            src={downloadAllIcon}
                            alt="Download"
                            style={{ cursor: "pointer" }}
                            title="Download All Tracking Tool"
                            data-bs-toggle="tooltip"
                            data-bs-placement="bottom"
                          />
                        </a>
                        <a>
                          <img
                            src={helpInfo}
                            className="info"
                            data-bs-toggle="tooltip"
                            data-bs-placement="right"
                            title="Instructions to save Tracking Tool data"
                            onClick={onShowHelpContent}
                            alt={"Help"}
                            style={{ cursor: "pointer" }}
                          />
                        </a>
                        <a>
                          <img
                            src={correctionIcon}
                            className="info"
                            data-bs-toggle="tooltip"
                            data-bs-placement="right"
                            title="Send multiple request for Md/SDoC"
                            onClick={onSendReminder}
                            alt={"Correction"}
                            style={{ cursor: "pointer" }}
                          />
                        </a>
                        <a>
                          <img
                            src={addRowIcon}
                            alt="add-document"
                            className="add-document"
                            title="Add new row"
                            data-bs-toggle="tooltip"
                            data-bs-placement="bottom"
                            onClick={onAddRow}
                            style={{ cursor: "pointer" }}
                          />
                        </a>
                        <span className="download-dropdown">
                          <a
                            className="dropdown-toggle download-dropdown"
                            href="#"
                            id="uploadDropdown"
                            role="button"
                            data-bs-toggle="dropdown"
                            aria-expanded="false"
                            alt="Upload"
                          >
                            <img
                              src={fileUploadIcon}
                              title="Upload multiple MD/SDoC"
                              data-bs-placement="bottom"
                            />
                          </a>
                          <ul
                            className="dropdown-menu"
                            aria-labelledby="uploadDropdown"
                            style={{ cursor: "pointer" }}
                          >
                            <li>
                              <a
                                className="dropdown-item"
                                data-bs-toggle="modal"
                                data-bs-target="#uploadMDSDoCDocument"
                                onClick={() =>
                                  onOpenSubModal("showMDSDoCUploadModal")
                                }
                              >
                                Upload MD/SDoC
                              </a>
                            </li>
                            <li>
                              <a
                                className="dropdown-item"
                                data-bs-toggle="modal"
                                data-bs-target="#uploadPOlist"
                                onClick={() =>
                                  onOpenSubModal("showUploadModal")
                                }
                              >
                                Upload PO List
                              </a>
                            </li>
                          </ul>
                        </span>
                      </span>
                    )
                  ) : null}
                  {/*  */}
                  <span className="download-dropdown">
                    <a
                      className="dropdown-toggle download-dropdown"
                      href="#"
                      id="downloadDropdown"
                      role="button"
                      data-bs-toggle="dropdown"
                      aria-expanded="false"
                    >
                      <img
                        src={downloadIcon}
                        alt="Download"
                        style={{ cursor: "pointer" }}
                        title="Download Tracking Tool"
                        data-bs-toggle="tooltip"
                        data-bs-placement="bottom"
                        onClick={onDownloadTrackingToolClicked}
                      />
                    </a>
                  </span>
                  <a>
                    <img
                      src={modalCloseIcon}
                      alt="close-icon"
                      className="close-btn"
                      title="Close zoom modal"
                      data-bs-toggle="tooltip"
                      data-bs-placement="bottom"
                      data-bs-dismiss="modal"
                      onClick={onCloseZoomClicked}
                      style={{ cursor: "pointer" }}
                    />
                  </a>
                </div>

                <div className="table-responsive">
                  <AGGrid
                    rowData={mappedTrackingTool}
                    colDefs={colDefs}
                    rowSelection="multiple"
                    pagination="200"
                    onGridReady={onGridReady}
                    onCellContextMenu={(e) => onCellClickedMenu(e)}
                    onSelectionChanged={(e) => onSelectionChanged(e)}
                    onCellKeyPress={onCellKeyPress.bind(this)}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default ZoomTrackingTool;
